import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { isEmpty } from 'lodash';
import type { MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import Message from 'components/Common/Message';
import useAlertQueue from 'hooks/useAlertQueue';
import Alert from 'storybook/stories/cells/Alert';
import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import type { Money } from 'types/general';
import type { BuyerProduct } from 'types/models/buyer-product';
import type { PriceListEntry, UnitPricingTier } from 'types/models/price-list-entry';
import { getBuyerPriceListEntryForVariant } from 'utils/api/buyer/priceLists';
import { createQuoteItem } from 'utils/api/quotes';
import { humanizeMoney, toMoney } from 'utils/currencies';

import { Controller, useForm } from 'react-hook-form';
import NewQuoteContext from './NewQuoteContext';
import WholesalePricingTable from './WholesalePricingTable';

interface ProductDetailsProps {
  product: BuyerProduct;
  handleBackButtonClick: MouseEventHandler<HTMLButtonElement>;
}

type AddQuoteItemFormValues = {
  variantId: string;
  quantity: number;
};

interface ProductImageProps {
  src: string;
  alt: string;
}

const ProductImage = styled.div.attrs<ProductImageProps>(({ alt }) => ({
  role: 'img',
  alt,
}))<ProductImageProps>`
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `}

  ${({ theme }) => css`
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: ${theme.color.gray200};
  `};
`;

interface ProductImageThumbnailProps {
  isSelected: boolean;
}

const ProductImageThumbnail = styled(ProductImage)<ProductImageThumbnailProps>`
  border: 1px solid transparent;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      border-color: ${theme.color.blue500};
    `}
`;

const ProductTitle = styled.h1`
  font-size: 35px;
  margin-bottom: 0;
`;

const ProductCode = styled.h2`
  font-size: 14px;
  margin-bottom: 0;
`;

const ProductDescription = styled.p`
  margin-bottom: 0;
`;

const Label = styled.label`
  text-transform: uppercase;
  margin-bottom: 0;
`;

const Input = styled.input`
  padding: 4px;
`;

const WarningText = styled.p`
  margin: 8px;
`;

const MinimumQuanity = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.color.gray400};
    font-size: 14px;
  `}
`;

interface ProductDetailsFormProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  product: BuyerProduct;
  sortedWholesalePricingTiers: [number, UnitPricingTier][];
  hasPriceListEntry: boolean;
  isEntryPricedForWholesale: boolean;
  fetchPriceListEntryForVariant: (variantId: string) => void;
}

const ProductDetailsForm = ({
  setIsLoading,
  product,
  sortedWholesalePricingTiers,
  hasPriceListEntry,
  isEntryPricedForWholesale,
  fetchPriceListEntryForVariant,
}: ProductDetailsFormProps) => {
  const { quote, refreshQuote } = useContext(NewQuoteContext);
  const { addErrorAlert } = useAlertQueue();

  const { handleSubmit, register, control, watch } = useForm<AddQuoteItemFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      variantId: product.variants[0]?.id,
      quantity: 1,
    },
  });

  const values = watch();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    createQuoteItem(quote.id, { productId: product.id, ...data })
      .then(refreshQuote)
      .catch((error) => {
        addErrorAlert('Unable to add Quote Item', 'Please try again.');
        console.error(`Unable to add Quote Item for Quote ${quote.id}`, error.message);
      })
      .finally(() => setIsLoading(false));
  });

  const quantityInQuote =
    quote.items.find((item) => item.variantId === values.variantId)?.quantity ?? 0;

  const unitPriceForQuantity = sortedWholesalePricingTiers.reduce(
    (previousValue, [minQuantity, tier]) => {
      const currentQuantity = values.quantity + quantityInQuote;

      if (currentQuantity >= minQuantity) return tier.price;
      return previousValue;
    },
    { amount: 0, currency: '' } as Money
  );

  const totalPrice = toMoney(
    (unitPriceForQuantity.amount / 100) * values.quantity,
    unitPriceForQuantity.currency
  );

  const variantSelectOptions = useMemo(
    () =>
      product.variants.map((variant) => {
        const variantOptions = variant.options.map((option) => `${option.name}: ${option.value}`);
        const label = `[${variant.sku}] ${variantOptions.join(', ')}`;
        return { value: variant.id, label };
      }),
    [product.variants]
  );

  const minWholesaleTier = sortedWholesalePricingTiers[0] ?? [0];
  const requiredQuantity = minWholesaleTier[0];

  const remainingQuantityRequired = requiredQuantity - quantityInQuote;
  const isMinTierQuantityMet = values.quantity >= remainingQuantityRequired;

  const disableAddToQuoteButton =
    !hasPriceListEntry ||
    !isMinTierQuantityMet ||
    values.quantity <= 0 ||
    !isEntryPricedForWholesale;

  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" gap="24px">
        <Flex gap="24px">
          <Flex.Item grow={2}>
            <Flex flexDirection="column" gap="4px">
              <Label htmlFor="select-variant">Select a Variant</Label>
              <Controller
                control={control}
                name="variantId"
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Select
                    ref={ref}
                    inputId="select-variant"
                    defaultValue={variantSelectOptions[0]?.value}
                    options={variantSelectOptions.map((option) => option.value)}
                    onChange={(value) => {
                      if (!value) return;
                      onChange(value);
                      fetchPriceListEntryForVariant(value);
                    }}
                    {...rest}
                  />
                )}
              />
            </Flex>
          </Flex.Item>

          <Flex.Item>
            <Flex flexDirection="column" gap="4px">
              {remainingQuantityRequired > 1 ? (
                <Label htmlFor="quantity">
                  Quantity <MinimumQuanity>min {remainingQuantityRequired}</MinimumQuanity>
                </Label>
              ) : (
                <Label htmlFor="quantity">Quantity</Label>
              )}

              <Input
                {...register('quantity', { valueAsNumber: true, min: remainingQuantityRequired })}
                type="number"
                aria-label="Number of items to add to Quote"
                data-testid="quantity-input"
              />
            </Flex>
          </Flex.Item>
        </Flex>

        <Divider />

        <Table>
          <Table.TBody>
            <Table.TR align="right">
              <Table.TH width="75%">unit price</Table.TH>
              <Table.TD>{humanizeMoney(unitPriceForQuantity)}</Table.TD>
            </Table.TR>
            <Table.TR align="right">
              <Table.TH width="75%">total</Table.TH>
              <Table.TD>{humanizeMoney(totalPrice)}</Table.TD>
            </Table.TR>
          </Table.TBody>
        </Table>

        <Flex column alignItemsEnd gap="24px">
          <PrimaryButton
            type="submit"
            disabled={disableAddToQuoteButton}
            data-testid="add-to-quote-button"
          >
            Add To Quote
          </PrimaryButton>

          <Message
            show={!isEntryPricedForWholesale}
            kind="warning"
            onClose={undefined} // undefined hides the close button
            animate={false}
          >
            <WarningText>This variant has no wholesale pricing assigned.</WarningText>
          </Message>
        </Flex>
      </Flex>
    </form>
  );
};

interface ProductImageContainerProps {
  product: BuyerProduct;
}

const ProductImageContainer = ({ product }: ProductImageContainerProps) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (!product?.images) return null;

  return (
    <Flex column gap="8px">
      <ProductImage
        src={product.images[selectedImageIndex].source}
        alt={`Product image for ${product.title}`}
        data-testid="product-image"
      />

      {product.images.length > 1 && (
        <Grid columns="1fr 1fr 1fr" gap="8px">
          {product.images.map((image, index) => (
            <ProductImageThumbnail
              as="button"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={image.source}
              alt={`Product image for ${product.title}`}
              isSelected={selectedImageIndex === index}
              onClick={() => setSelectedImageIndex(index)}
              data-testid="product-image-thumbnail"
            />
          ))}
        </Grid>
      )}
    </Flex>
  );
};

const ProductDetails = ({ product, handleBackButtonClick }: ProductDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priceListEntry, setPriceListEntry] = useState({} as PriceListEntry);

  const { quote } = useContext(NewQuoteContext);

  const { addErrorAlert } = useAlertQueue();

  // Helpers

  const isEntryPricedForWholesale = !isEmpty(priceListEntry?.wholesaleUnitPricingTiers);

  const fetchPriceListEntryForVariant = useCallback(
    (variantId: string) => {
      if (!variantId) return;

      setIsLoading(true);

      getBuyerPriceListEntryForVariant(quote.priceListId, variantId)
        .then((response) => {
          setPriceListEntry(response.data);
        })
        .catch((error) => {
          addErrorAlert(
            'Unable to fetch Pricing for selected Product Variant',
            'Please try again.'
          );
          console.error('Unable to fetch Pricing for selected Product Variant', error.message);
        })
        .finally(() => setIsLoading(false));
    },
    [quote.priceListId, addErrorAlert]
  );

  const sortedWholesalePricingTiers = useMemo(
    () =>
      Object.entries(priceListEntry?.wholesaleUnitPricingTiers ?? {})
        // Convert minimum quantities to numbers
        .map(([minQuantity, tier]): [number, UnitPricingTier] => [parseInt(minQuantity, 10), tier])
        // Sort by minimum quantity, descending
        .sort(([aMinValue], [bMinValue]) => aMinValue - bMinValue),
    [priceListEntry?.wholesaleUnitPricingTiers]
  );

  // Side Effects

  useEffect(() => {
    fetchPriceListEntryForVariant(product.variants[0]?.id);
  }, [fetchPriceListEntryForVariant, product.variants]);

  // Render

  return (
    <Card data-testid="product-details">
      <Flex column gap="16px">
        <Flex.Item alignSelfStart>
          <TertiaryButton $iconName="arrow_back" onClick={handleBackButtonClick}>
            Back to all products
          </TertiaryButton>
        </Flex.Item>

        <Grid columns="240px auto" gap="24px">
          <Grid.Item>
            <ProductImageContainer product={product} />
          </Grid.Item>

          <Grid.Item>
            <Flex flexDirection="column" gap="24px">
              <ProductTitle>{product.title}</ProductTitle>
              <ProductCode>{product.sellerReference}</ProductCode>
              <ProductDescription>{product.description}</ProductDescription>

              {!isLoading && isEmpty(priceListEntry) && (
                <Alert
                  type="error"
                  title="No pricing found for the selected variant"
                  data-testid="no-pricing-alert"
                >
                  Please try another selection below.
                </Alert>
              )}

              {!isLoading && isEntryPricedForWholesale && (
                <WholesalePricingTable sortedWholesalePricingTiers={sortedWholesalePricingTiers} />
              )}

              <ProductDetailsForm
                setIsLoading={setIsLoading}
                product={product}
                sortedWholesalePricingTiers={sortedWholesalePricingTiers}
                hasPriceListEntry={!isEmpty(priceListEntry)}
                isEntryPricedForWholesale={isEntryPricedForWholesale}
                fetchPriceListEntryForVariant={fetchPriceListEntryForVariant}
              />
            </Flex>
          </Grid.Item>
        </Grid>
      </Flex>
    </Card>
  );
};

export default ProductDetails;
