import Flex from '@react-css/flex';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Spinner from 'components/Common/Spinner';
import {
  EditButton,
  EditDot,
  EditText,
  PartnerName,
  QuoteDate,
  QuoteHeadCard,
} from 'containers/Quotes/shared/Styles';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import Card from 'storybook/stories/cells/Card';
import type { Address } from 'types/general';
import type { Quote } from 'types/models/quote';
import { getQuote, updateQuoteBilling, updateQuoteShipping } from 'utils/api/quotes';
import { prettyDate } from 'utils/date';

import { toMoney } from 'utils/currencies';
import QuoteLayout, { QuoteLayoutAside, QuoteLayoutMain } from './QuoteLayout';
import QuoteStatusCard from './QuoteStatusCard';
import AddressSelectModal from './shared/AddressSelectModal';
import BillingCard from './shared/BillingCard';
import QuoteItemTable from './shared/QuoteItemTable';
import ShippingCard from './shared/ShippingCard';
import TotalsCard from './shared/TotalsCard';

type QuotePageParams = {
  quoteId: string;
  action?: 'edit';
};

const shouldUpdateAddress = (
  type: string,
  typeBeingEdited: string,
  oldAddress: Address,
  newAddress: Address
) => {
  if (type !== typeBeingEdited) return false;
  if (isEmpty(newAddress)) return false;

  return !isEqual(oldAddress, newAddress);
};

const QuotePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [editingAddressType, setEditingAddressType] = useState('' as 'shipping' | 'billing' | '');
  const [quote, setQuote] = useState({} as Quote);

  const me = useAppSelector((state) => state.me);

  const history = useHistory();
  const { quoteId, action } = useParams<QuotePageParams>();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  // Helpers

  const isBuyer = me?.company?.commerceType === 'buyer' ?? false;
  const isEditing = action === 'edit' && isBuyer;

  const refreshQuote = useCallback(() => {
    setIsLoading(true);

    getQuote(quoteId)
      .then((response) => setQuote(response.data))
      .catch((error) => {
        addErrorAlert(
          'Unable to refresh Quote',
          'This data may be out of date. Please try refreshing your browser.'
        );

        console.error(`Unable to refresh quote ${quoteId}`, error.message);
      })
      .finally(() => setIsLoading(false));
  }, [quoteId, addErrorAlert]);

  // Side Effects

  useEffect(refreshQuote, [refreshQuote]);

  // Event Handlers

  const handleEditButtonClick = () => {
    if (isEditing) history.push(`/quotes/show/${quoteId}`);
    else history.push(`/quotes/show/${quoteId}/edit`);
  };

  const onEstimatedTaxesChange = (newEstimatedTaxes: number) => {
    const shippingAndSubtotalAmount =
      quote.estimatedShippingPrice.amount / 100 + quote.subtotalPrice.amount / 100;

    // eslint-disable-next-line no-restricted-globals
    const newTotalPrice = isNaN(newEstimatedTaxes)
      ? shippingAndSubtotalAmount
      : newEstimatedTaxes + shippingAndSubtotalAmount;

    const updatedQuote = {
      ...quote,
      estimatedTaxPrice: toMoney(newEstimatedTaxes, quote.currency),
      totalPrice: toMoney(newTotalPrice, quote.currency),
    };

    setQuote(updatedQuote);
  };

  const onEstimatedShippingChange = (newEstimatedShipping: number) => {
    const taxesAndSubtotalAmount =
      quote.estimatedTaxPrice.amount / 100 + quote.subtotalPrice.amount / 100;

    // eslint-disable-next-line no-restricted-globals
    const newTotalPrice = isNaN(newEstimatedShipping)
      ? taxesAndSubtotalAmount
      : newEstimatedShipping + taxesAndSubtotalAmount;

    const updatedQuote = {
      ...quote,
      estimatedShippingPrice: toMoney(newEstimatedShipping, quote.currency),
      totalPrice: toMoney(newTotalPrice, quote.currency),
    };

    setQuote(updatedQuote);
  };

  // Render

  if (isLoading) return <Spinner />;

  return (
    <QuoteLayout title="Open Quote" data-testid="quote-page">
      <QuoteLayoutMain>
        <QuoteHeadCard>
          <Flex justifySpaceBetween alignItemsCenter>
            <Flex.Item>
              <PartnerName>{quote.sellerName}</PartnerName>
              <QuoteDate>{prettyDate(quote.createdAt)}</QuoteDate>
            </Flex.Item>

            {isBuyer && (
              <EditButton onClick={handleEditButtonClick} data-testid="edit-quote-button">
                <EditDot />
                <EditText>{isEditing ? 'Cancel Editing' : 'Edit Quote'}</EditText>
              </EditButton>
            )}
          </Flex>
        </QuoteHeadCard>

        <Card>
          <QuoteItemTable
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            quote={quote}
            refreshQuote={refreshQuote}
            isEditable={isEditing}
          />
        </Card>

        <Flex justifyEnd>
          <TotalsCard quote={quote} />
        </Flex>
      </QuoteLayoutMain>

      <QuoteLayoutAside>
        <QuoteStatusCard
          quote={quote}
          refreshQuote={refreshQuote}
          onEstimatedTaxesChange={onEstimatedTaxesChange}
          onEstimatedShippingChange={onEstimatedShippingChange}
        />

        <ShippingCard
          address={quote.shippingAddress}
          isEditable={isEditing}
          shipBy={quote.requestedShipBy}
          deliverBy={quote.requestedDeliverBy}
          onEditButtonClick={() => setEditingAddressType('shipping')}
        />

        <BillingCard
          address={quote.billingAddress}
          isEditable={isEditing}
          onEditButtonClick={() => setEditingAddressType('billing')}
        />

        <AddressSelectModal
          addressType={editingAddressType}
          handleClose={async (address) => {
            try {
              if (
                shouldUpdateAddress('shipping', editingAddressType, quote.shippingAddress, address)
              ) {
                await updateQuoteShipping(quote.id, {
                  shippingAddress: address,
                  requestedShipBy: quote.requestedShipBy,
                  requestedDeliverBy: quote.requestedDeliverBy,
                });

                addSuccessAlert(
                  'Your Quote has been updated',
                  'The change to your Shipping Addresses was successful.'
                );
              }

              if (
                shouldUpdateAddress('billing', editingAddressType, quote.billingAddress, address)
              ) {
                await updateQuoteBilling(quote.id, { billingAddress: address });

                addSuccessAlert(
                  'Your Quote has been updated',
                  'The change to your Billing Addresses was successful.'
                );
              }

              setEditingAddressType('');
              refreshQuote();
            } catch (error) {
              const errorMessage = error instanceof Error ? error.message : error;

              addErrorAlert('Unable to update Addresses', 'Please try again.');
              console.error('Unable to update Addresses', errorMessage);
            }
          }}
        />
      </QuoteLayoutAside>
    </QuoteLayout>
  );
};

export default QuotePage;
