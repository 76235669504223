import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import Card from 'storybook/stories/cells/Card';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import type { Address } from 'types/general';
import type { Quote } from 'types/models/quote';
import type { QuotePricingParams, QuoteShippingParams } from 'utils/api/quotes';
import {
  acceptQuote,
  deleteQuote,
  rejectQuote,
  updateQuotePricing,
  updateQuoteShipping,
} from 'utils/api/quotes';
import { toMoney } from 'utils/currencies';
import { capitalizeFirstChar } from 'utils/strings';

export interface QuoteStatusCardProps {
  quote: Quote;
  refreshQuote: () => void;
  onEstimatedTaxesChange: (newEstimatedTaxes: number) => void;
  onEstimatedShippingChange: (newEstimatedShipping: number) => void;
}

interface SellerButtonProps {
  active: boolean;
}

interface ApprovalFormProps {
  quoteId: string;
  currency: string;
  requestedShipBy: string;
  requestedDeliverBy: string;
  shippingAddress: Address;
  refreshQuote: () => void;
  onEstimatedTaxesChange: (newEstimatedTaxes: number) => void;
  onEstimatedShippingChange: (newEstimatedShipping: number) => void;
}

type ApprovalFormValues = {
  estimatedTaxPrice: number;
  estimatedShippingPrice: number;
};

interface RejectionFormProps {
  quoteId: string;
  refreshQuote: () => void;
}

type RejectionFormValues = {
  note: string;
};

interface StatusIconProps {
  status: string;
}

const Heading = styled.strong`
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-weight: normal;
  color: ${({ theme }) => theme.color.gray400};
`;

const StatusIcon = styled.span<StatusIconProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;
  border-radius: 100%;
  border: 1px solid transparent;

  &::before {
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.icon};
  }

  ${({ status }) => {
    switch (status) {
      case 'draft':
        return css`
          color: ${({ theme }) => theme.color.gray500};
          background: ${({ theme }) => theme.color.gray200};
          border-color: ${({ theme }) => theme.color.gray500};

          &::before {
            content: 'content_paste';
          }
        `;
      case 'pending':
        return css`
          color: ${({ theme }) => theme.color.gray500};
          background: ${({ theme }) => theme.color.gray200};
          border-color: ${({ theme }) => theme.color.gray500};

          &::before {
            content: 'pending_actions';
          }
        `;
      case 'rejected':
        return css`
          color: ${({ theme }) => theme.color.error500};
          background-color: ${({ theme }) => theme.color.error500};
          border-color: ${({ theme }) => theme.color.error500};

          &::before {
            color: ${({ theme }) => theme.color.white};
            content: 'cancel';
          }
        `;
      case 'accepted':
        return css`
          color: ${({ theme }) => theme.color.success500};
          background-color: ${({ theme }) => theme.color.success500};
          border-color: ${({ theme }) => theme.color.success500};

          &::before {
            color: ${({ theme }) => theme.color.white};
            content: 'check_circle';
          }
        `;
      default:
        return css``;
    }
  }}
`;

const DeleteButton = styled.button`
  display: inline-flex;
  align-items: center;

  background: none;
  border: 0;
  cursor: pointer;

  gap: 4px;
  color: ${({ theme }) => theme.color.error500};

  &::before {
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.icon};
    content: 'delete_forever';
  }
`;

const SellerButton = styled.button<SellerButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: none;
  cursor: pointer;

  gap: 8px;
  border: 1px solid ${({ theme }) => theme.color.gray200};
  padding: 8px;

  &::before {
    font-size: 21px;
    font-family: ${({ theme }) => theme.font.icon};
  }
`;

const ApproveButton = styled(SellerButton)`
  color: ${({ theme }) => theme.color.success500};
  border-radius: 4px 0 0 4px;

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.color.success500};
      border-color: ${theme.color.success500};
      color: white;
    `}

  &::before {
    content: 'check_circle';
  }
`;

const RejectButton = styled(SellerButton)`
  border-left: 0;

  color: ${({ theme }) => theme.color.error500};
  border-radius: 0 4px 4px 0;

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.color.error500};
      border-color: ${theme.color.error500};
      color: white;
    `}

  &::before {
    content: 'cancel';
  }
`;

const StatusText = styled.span`
  font-size: 20px;
`;

const Message = styled.p`
  text-align: center;
`;

const ApprovalForm = ({
  quoteId,
  shippingAddress,
  requestedShipBy,
  requestedDeliverBy,
  currency,
  refreshQuote,
  onEstimatedTaxesChange,
  onEstimatedShippingChange,
}: ApprovalFormProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const initialValues: ApprovalFormValues = {
    estimatedTaxPrice: 0,
    estimatedShippingPrice: 0,
  };

  const onSubmit = async (values: ApprovalFormValues) => {
    // TODO: Requiring a shipping address after it's already set is silly
    // https://github.com/convictional/backend/issues/4244
    const shippingParams: QuoteShippingParams = {
      shippingAddress,
      requestedShipBy,
      requestedDeliverBy,
    };

    const pricingParams: QuotePricingParams = {
      estimatedShippingPrice: toMoney(values.estimatedShippingPrice, currency),
      estimatedTaxPrice: toMoney(values.estimatedTaxPrice, currency),
    };

    try {
      // TODO: Way too many requests for a form submission
      // https://github.com/convictional/backend/issues/4244
      await updateQuoteShipping(quoteId, shippingParams);
      await updateQuotePricing(quoteId, pricingParams);
      await acceptQuote(quoteId);
      await refreshQuote();

      addSuccessAlert('Your Quote has been Approved', "We'll let your partner know right away!");
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Something went wront', errorMessage as string);
      console.error('Something went wrong', errorMessage);
    }
  };

  const { handleSubmit, register, formState, watch } = useForm<ApprovalFormValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const estimatedTaxPrice = watch('estimatedTaxPrice');
  const estimatedShippingPrice = watch('estimatedShippingPrice');

  useEffect(() => {
    onEstimatedTaxesChange(estimatedTaxPrice);
  }, [estimatedTaxPrice, onEstimatedTaxesChange]);

  useEffect(() => {
    onEstimatedShippingChange(estimatedShippingPrice);
  }, [estimatedShippingPrice, onEstimatedShippingChange]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="estimated-tax-price">
        <Form.Label>Estimated Taxes ({currency})</Form.Label>
        <Form.Control
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('estimatedTaxPrice', { required: true, valueAsNumber: true })}
          type="number"
          step="0.01"
          min="0.00"
        />
      </Form.Group>

      <Form.Group controlId="estimated-shipping-price">
        <Form.Label>Estimated Shipping Price ({currency})</Form.Label>
        <Form.Control
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('estimatedShippingPrice', { required: true, valueAsNumber: true })}
          required
          type="number"
          step="0.01"
          min="0.00"
        />
      </Form.Group>

      <Flex column alignItemsStretch>
        <PrimaryButton
          type="submit"
          disabled={!formState.isValid}
          data-testid="approval-submit-button"
        >
          Submit
        </PrimaryButton>
      </Flex>
    </Form>
  );
};

const RejectionForm = ({ quoteId, refreshQuote }: RejectionFormProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const initialValues: RejectionFormValues = {
    note: '',
  };

  const onSubmit = (values: RejectionFormValues) => {
    rejectQuote(quoteId, { note: values.note })
      .then(() => {
        addSuccessAlert('Your Quote has been Rejected', "We'll notify your partner right away!");
      })
      .then(refreshQuote)
      .catch((error) => {
        addErrorAlert('Unable to reject Quote', 'Please try again.');
        console.error(`Unable to reject Quote ${quoteId}`, error.message);
      });
  };

  const { handleSubmit, register, formState } = useForm<RejectionFormValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="note">
        <Form.Label>Reason</Form.Label>
        <Form.Control
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('note', { required: true })}
          as="textarea"
          rows={3}
        />
      </Form.Group>

      <Flex column alignItemsStretch>
        <PrimaryButton
          type="submit"
          disabled={!formState.isValid}
          data-testid="reject-submit-button"
        >
          Submit
        </PrimaryButton>
      </Flex>
    </Form>
  );
};

const QuoteStatusCard = ({
  quote,
  refreshQuote,
  onEstimatedTaxesChange,
  onEstimatedShippingChange,
}: QuoteStatusCardProps) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const me = useAppSelector((state) => state.me);

  const history = useHistory();
  const { addErrorAlert } = useAlertQueue();

  // Helpers

  const isBuyer = me?.company?.commerceType === 'buyer' ?? false;
  const isApproved = quote.status === 'accepted';
  const isRejected = quote.status === 'rejected';
  const isPending = quote.status === 'pending';
  const isDraft = quote.status === 'draft';

  // Event Handler

  const handleCancelButtonClick = () => {
    deleteQuote(quote.id)
      .then(() => {
        history.push('/quotes');
      })
      .catch((error) => {
        addErrorAlert('Unable to cancel Quote', 'Please try again.');
        console.error(`Unable to cancel Quote ${quote.id}`, error.message);
      });
  };

  const handleApproveButtonClick = () => {
    if (isApproved || isRejected) return;

    setIsApproving(true);
    setIsRejecting(false);
  };

  const handleRejectButtonClick = () => {
    if (isRejected || isApproved) return;

    setIsApproving(false);
    setIsRejecting(true);
  };

  const handleViewOrderButtonClick = () => {
    history.push(`/orders/${quote.sellerOrderId}`);
  };

  // Render

  return (
    <Card>
      <Flex column gap="16px">
        <Heading>Quote Status</Heading>

        <Flex column alignItemsCenter gap="16px">
          <Flex inline alignItemsCenter gap="8px">
            <StatusIcon status={quote.status} />
            <StatusText>{capitalizeFirstChar(quote.status)}</StatusText>
          </Flex>

          {isBuyer ? (
            <>
              {(isDraft || isPending) && (
                <DeleteButton type="button" onClick={handleCancelButtonClick}>
                  Cancel Quote
                </DeleteButton>
              )}

              {isApproved && (
                <>
                  <Message data-testid="buyer-approved-message">
                    Your quote has been accepted by <strong>{quote.sellerName}</strong> and
                    converted into an order.
                  </Message>

                  {quote?.sellerOrderId && (
                    <PrimaryButton onClick={handleViewOrderButtonClick}>View Order</PrimaryButton>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {isPending && (
                <Flex.Item alignSelfStretch>
                  <Grid columns="1fr 1fr">
                    <ApproveButton
                      type="button"
                      active={isApproving || isApproved}
                      onClick={handleApproveButtonClick}
                      data-testid="approve-button"
                    >
                      Approve
                    </ApproveButton>

                    <RejectButton
                      type="button"
                      active={isRejecting || isRejected}
                      onClick={handleRejectButtonClick}
                      data-testid="reject-button"
                    >
                      Reject
                    </RejectButton>
                  </Grid>
                </Flex.Item>
              )}

              {isApproving && !isApproved && (
                <Flex.Item alignSelfStretch>
                  <ApprovalForm
                    quoteId={quote.id}
                    requestedShipBy={quote.requestedShipBy}
                    requestedDeliverBy={quote.requestedDeliverBy}
                    currency={quote.currency}
                    shippingAddress={quote.shippingAddress}
                    refreshQuote={refreshQuote}
                    onEstimatedTaxesChange={onEstimatedTaxesChange}
                    onEstimatedShippingChange={onEstimatedShippingChange}
                  />
                </Flex.Item>
              )}

              {isApproved && (
                <>
                  <Message data-testid="seller-approved-message">
                    You have approved this quote for <strong>{quote.buyerName}</strong> and an order
                    has been created.
                  </Message>

                  {quote?.sellerOrderId && (
                    <PrimaryButton onClick={handleViewOrderButtonClick}>View Order</PrimaryButton>
                  )}
                </>
              )}

              {isRejecting && !isRejected && (
                <Flex.Item alignSelfStretch>
                  <RejectionForm quoteId={quote.id} refreshQuote={refreshQuote} />
                </Flex.Item>
              )}

              {isRejected && (
                <Message>
                  <strong>Reason:</strong> {quote.rejectedNote}
                </Message>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default QuoteStatusCard;
