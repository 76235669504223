import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import type { UnitPricingTier } from 'types/models/price-list-entry';
import { humanizeMoney } from 'utils/currencies';

interface WholesalePricingTableProps {
  sortedWholesalePricingTiers: [number, UnitPricingTier][];
}

interface QuantityRangeProps {
  fromQuantity: number;
  toQuantity: number | undefined;
}

const QuantityRange = ({ fromQuantity, toQuantity }: QuantityRangeProps) => {
  // If toQuantity is undefined, assume we're displaying the last price
  if (toQuantity === undefined) return <span>{fromQuantity}+</span>;

  return (
    <span>
      {fromQuantity} to {toQuantity - 1}
    </span>
  );
};

const getPrettyRetailDiscount = (retailDiscount: number) => `${Math.round(retailDiscount * 100)}%`;

const WholesalePricingTable = ({ sortedWholesalePricingTiers }: WholesalePricingTableProps) => {
  // Helpers

  return (
    <Card elevation="none">
      <Table>
        <Table.THead>
          <Table.TR>
            <Table.TH>Quantity</Table.TH>
            <Table.TH align="right">Retail Discount</Table.TH>
            <Table.TH align="right">Wholesale Price</Table.TH>
          </Table.TR>
        </Table.THead>

        <Table.TBody>
          {sortedWholesalePricingTiers.map(([fromQuantity, tier], index, array) => {
            // Gets the next minimum quantity in the pricing array
            // Will intentionally be undefined to indicate the end of the array
            const [toQuantity] = array[index + 1] ?? [];

            return (
              <Table.TR key={fromQuantity} data-testid="wholesale-pricing-table-row">
                <Table.TD>
                  <QuantityRange fromQuantity={fromQuantity} toQuantity={toQuantity} />
                </Table.TD>
                <Table.TD align="right">
                  {getPrettyRetailDiscount(tier?.retailDiscount ?? 0)}
                </Table.TD>
                <Table.TD align="right">{humanizeMoney(tier.price)}</Table.TD>
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
    </Card>
  );
};

export default WholesalePricingTable;
