import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import useAlertQueue from 'hooks/useAlertQueue';
import type { Money } from 'types/general';
import type { Quote } from 'types/models/quote';
import { getBuyerPriceList } from 'utils/api/buyer/priceLists';
import { getQuote } from 'utils/api/quotes';

import AddressSelection from './AddressSelection';
import type { NewQuoteContextValue } from './NewQuoteContext';
import NewQuoteContext from './NewQuoteContext';
import PartnerSelection from './PartnerSelection';
import ProductSelection from './ProductSelection';
import ReviewAndSubmit from './ReviewAndSubmit';

const NewQuotesPage = () => {
  const [quote, setQuote] = useState({} as Quote);
  const [quoteMinimumValue, setQuoteMinimumValue] = useState({
    amount: 0,
    currency: 'USD',
  } as Money);

  const { addErrorAlert } = useAlertQueue();

  // Helpers

  const refreshQuote = useCallback(
    () =>
      getQuote(quote.id)
        .then((response) => setQuote(response.data))
        .catch((error) => {
          addErrorAlert(
            'Unable to refresh Quote',
            'This data may be out of date. Please try refreshing your browser.'
          );

          console.error(`Unable to refresh quote ${quote.id}`, error.message);
        }),
    [quote.id, setQuote, addErrorAlert]
  );

  const quoteContextValue: NewQuoteContextValue = useMemo(
    () => ({ quote, setQuote, refreshQuote, quoteMinimumValue, setQuoteMinimumValue }),
    [quote, setQuote, refreshQuote, quoteMinimumValue, setQuoteMinimumValue]
  );

  // Side Effects

  useEffect(() => {
    if (quote.priceListId) {
      getBuyerPriceList(quote.priceListId)
        .then((response) => {
          const minimumValue = response.data?.quoteMinimumValue;
          setQuoteMinimumValue(minimumValue);
        })
        .catch((error) => {
          addErrorAlert('Unable to fetch Pricing', 'Please reload your browser.');
          console.error('Unable to fetch Pricing', error.message);
        });
    }
  }, [quote.priceListId, addErrorAlert]);

  // Render

  return (
    <NewQuoteContext.Provider value={quoteContextValue}>
      <Switch>
        <Route exact path="/quotes/new/partner">
          <PartnerSelection />
        </Route>

        <Route exact path="/quotes/new/:id/products">
          <ProductSelection />
        </Route>

        <Route exact path="/quotes/new/:id/addresses">
          <AddressSelection />
        </Route>

        <Route exact path="/quotes/new/:id/review">
          <ReviewAndSubmit />
        </Route>
      </Switch>
    </NewQuoteContext.Provider>
  );
};

export default NewQuotesPage;
