import Flex from '@react-css/flex';
import { isFinite } from 'lodash';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { getCurrencySymbol } from 'utils/currencies';
import type { CommonFooterProps } from '../Shared.types';
import type { CreatePriceListContextValues } from '../context';
import { CreatePriceListContext } from '../context';

import Card from 'storybook/stories/cells/Card';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import Label from 'storybook/stories/molecules/Label';
import ProgressBar from 'storybook/stories/organisms/ProgressBar';

const Footer = ({ isNextButtonDisabled }: CommonFooterProps) => {
  const { updateState } = useContext(CreatePriceListContext);

  const onClickBack = () => updateState({ currentStep: 'CurrencyForm' });

  return (
    <Card>
      <Flex justifySpaceBetween>
        <TertiaryButton size="small" $iconName="navigate_before" onClick={onClickBack}>
          Back
        </TertiaryButton>

        <PrimaryButton type="submit" size="small" $iconName="check" disabled={isNextButtonDisabled}>
          Next
        </PrimaryButton>
      </Flex>
    </Card>
  );
};

const ConversionRateForm = () => {
  const { yourCurrency, partnerCurrency, conversionRate, updateState } =
    useContext(CreatePriceListContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      conversionRate,
    },
  });

  const conversionRateFormValue = watch('conversionRate');

  const onSubmit = (data: Partial<CreatePriceListContextValues>) => {
    updateState({
      conversionRate: data.conversionRate,
      currentStep: 'RoundingRulesForm',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex column gap="16px">
        <Card>
          <Flex column gap="28px">
            <ProgressBar totalSteps={5} completedSteps={4} />

            <Flex column gap="32px">
              <Flex column gap="8px">
                <Heading variant="Headings/H3">
                  What currency conversion rate do you want to use?
                </Heading>
                <Body variant="Body/Regular" color="bodyTextSecondary">
                  To provide your partner with the suggested retail price, we multiply your retail
                  price by the conversion rate.
                </Body>
              </Flex>

              <Flex justifySpaceBetween>
                <Flex column gap="8px">
                  <Label htmlFor="conversionRate" isRequired>
                    Conversion Rate
                  </Label>

                  <Input
                    type="number"
                    min="0"
                    step="0.01"
                    id="conversionRate"
                    {...register('conversionRate', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder="e.g. 0.80"
                  />

                  {isFinite(conversionRateFormValue) && (
                    <Body variant="Body/Regular" color="gray600">
                      <Flex alignItemsCenter gap="8px">
                        <div>{getCurrencySymbol(yourCurrency)}1.00</div>
                        <Icon name="arrow_forward" />
                        <div>
                          {getCurrencySymbol(partnerCurrency)}
                          {conversionRateFormValue}
                        </div>
                      </Flex>
                    </Body>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Footer isNextButtonDisabled={!isValid} />
      </Flex>
    </form>
  );
};

export default ConversionRateForm;
