import type { Response } from 'types/api';
import type { PriceList } from 'types/models/price-list';
import type { PriceListEntry } from 'types/models/price-list-entry';
import { getRequest } from 'utils/request';

export type BuyerPriceListResponse = Response<PriceList>;
export type BuyerPriceListEntryResponse = Response<PriceListEntry>;

export const getBuyerPriceList = async (listId: string): Promise<BuyerPriceListResponse> =>
  getRequest(`/buyer/price-lists/${listId}`);

export const getBuyerPriceListEntryForVariant = async (
  listId: string,
  variantId: string
): Promise<BuyerPriceListEntryResponse> =>
  getRequest(`/buyer/price-lists/${listId}/variants/${variantId}`);
