import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import useHubspot from 'hooks/useHubspot';
import useOnboardingAttributeParams from 'hooks/useOnboardingAttributeParams';
import useShopifyAppInstallAlert from 'hooks/useShopifyAppInstallAlert';
import useShopifyAppInstallOnboardingAttributes from 'hooks/useShopifyAppInstallOnboardingAttributes';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Heading from 'storybook/stories/molecules/Heading';
import Icon from 'storybook/stories/molecules/Icon';
import Input from 'storybook/stories/molecules/Input';
import TextAreaInput from 'storybook/stories/molecules/Input/TextAreaInput';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import Form from 'storybook/stories/organisms/Form';
import SignUpPageLayout from 'storybook/stories/species/Layout/SignUpPageLayout';
import type ApiError from 'utils/ApiError';
import { signup, type SignupParams } from 'utils/api/signup';

const DefaultContent = () => {
  return (
    <Flex column gap="64px">
      <Flex column gap="24px">
        <SignUpPageLayout.Logo />

        <SignUpPageLayout.SuperHeading>
          Helping retailers and their vendors trade more profitably
        </SignUpPageLayout.SuperHeading>

        <Heading as="span" variant="Headings/H3" color="white">
          Modern Dropship&apos;s platform offers unique use cases for retailers and brands to help
          them transact with each other. Find your use case below!
        </Heading>
      </Flex>

      <Grid columns="1fr 1fr" gap="48px">
        <Flex column gap="8px">
          <Icon name="domain" color="white" size="24px">
            <Heading as="strong" variant="Headings/H3" color="white">
              For Retailers
            </Heading>
          </Icon>

          <Body color="white">
            Use Modern Dropship to onboard and integrate your dropship vendors.
          </Body>
        </Flex>

        <Flex column gap="8px">
          <Icon name="store" color="white" size="24px">
            <Heading as="strong" variant="Headings/H3" color="white">
              For Vendors
            </Heading>
          </Icon>

          <Body color="white">
            Use Modern Dropship to connect with and sell through your retail partners.
          </Body>
        </Flex>
      </Grid>
    </Flex>
  );
};

const SellerSignupContent = () => {
  return (
    <Flex column gap="64px">
      <Flex column gap="24px">
        <SignUpPageLayout.Logo />

        <SignUpPageLayout.SuperHeading>
          Start selling your products with seamless integrations
        </SignUpPageLayout.SuperHeading>

        <Heading as="span" variant="Headings/H3" color="white">
          We are a Supplier Enablement Platform to help you trade and grow your business with ease.
        </Heading>
      </Flex>

      <Flex column gap="24px">
        <Flex gap="16px">
          <Icon name="check_circle" fill={1} color="white" size="24px" />
          <Heading variant="Headings/H4" color="white" as="span">
            Self Serve: Create account and start selling through any online store (B2C and B2B)
          </Heading>
        </Flex>

        <Flex gap="16px">
          <Icon name="check_circle" fill={1} color="white" size="24px" />
          <Heading variant="Headings/H4" color="white" as="span">
            Manage orders and inventory from your existing systems
          </Heading>
        </Flex>

        <Flex gap="16px">
          <Icon name="check_circle" fill={1} color="white" size="24px" />
          <Heading variant="Headings/H4" color="white" as="span">
            One-click integration with your e-commerce platform
          </Heading>
        </Flex>

        <Flex gap="16px">
          <Icon name="check_circle" fill={1} color="white" size="24px" />
          <Heading variant="Headings/H4" color="white" as="span">
            Secured by SOC2 Type II compliant best practices
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SignUpPage = () => {
  const history = useHistory();
  const location = useLocation();

  const user = useAppSelector(selectUser);
  const alertQueue = useAlertQueue();
  const onboardingAttributes = useOnboardingAttributeParams();

  useShopifyAppInstallAlert();
  useShopifyAppInstallOnboardingAttributes();

  // Hubspot pixel
  useHubspot();

  /**
   * Helpers
   */

  const { email, redirect, invite, commerceType } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const isSellerSigningUp = commerceType === 'seller';

  /**
   * Mutations
   */

  const signingUp = useMutation({
    mutationFn: (params: SignupParams) => signup(params),
    onSuccess: (data, variables) => {
      history.push('/signin/success', { email: variables.email, backPath: '/signup' });
    },
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Something went wrong', error.message);
      Sentry.captureException(error);
    },
  });

  /**
   * Form
   */

  interface FormFields {
    email: string;
    howDidYouHear?: string;
    hopeToAccomplish: string;
  }

  const { register, handleSubmit, formState } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      email: (email || '') as string,
      howDidYouHear: '',
      hopeToAccomplish: '',
    },
  });

  /**
   * Handlers
   */

  const onSubmit = handleSubmit((data: FormFields) => {
    const params: SignupParams = {
      email: data.email.toLowerCase().trim(),
      redirect: (redirect || '') as string,
      onboardingAttributes,
    };

    signingUp.mutate(params);
  });

  /**
   * Render
   */

  if (user) return <Redirect to="/" />;

  return (
    <SignUpPageLayout.Root>
      <SignUpPageLayout.Content>
        {isSellerSigningUp ? <SellerSignupContent /> : <DefaultContent />}
      </SignUpPageLayout.Content>

      <SignUpPageLayout.Aside>
        <Flex column gap="32px">
          <Heading variant="Headings/H2">
            Create your {isSellerSigningUp ? 'free' : 'Modern Dropship'} account
          </Heading>

          <Form onSubmit={onSubmit}>
            <Form.FieldGroup>
              <Form.Field
                labelText="Email"
                id="email"
                errorText={formState.errors.email?.message}
                isRequired
              >
                <Input
                  type="email"
                  {...register('email', { required: 'Email is required' })}
                  isFullWidth
                  placeholder="email@example.com"
                />
              </Form.Field>

              <Form.Field
                labelText="How did you hear about us?"
                id="howDidYouHear"
                errorText={formState.errors.howDidYouHear?.message}
              >
                <Input
                  {...register('howDidYouHear')}
                  isFullWidth
                  placeholder="A partner told me, G2, Shopify, Blog Post, etc"
                />
              </Form.Field>

              <Form.Field
                labelText="What do you hope to accomplish with Modern Dropship?"
                id="hopeToAccomplish"
                errorText={formState.errors.hopeToAccomplish?.message}
                isRequired
              >
                <TextAreaInput
                  {...register('hopeToAccomplish', { required: 'This field is required' })}
                  isFullWidth
                  placeholder="E.g. I want to automate my order process"
                />
              </Form.Field>
            </Form.FieldGroup>

            <Form.Footer column gap="16px">
              <PrimaryButton type="submit" disabled={signingUp.isLoading}>
                Sign Up
              </PrimaryButton>

              <Body>
                <SupportLink article="urynzwlk6a">Signup Guide</SupportLink> • Already have an
                account? <Link to="/signin">Sign in</Link>
              </Body>
            </Form.Footer>

            <Form.HiddenField name="invite" value={invite as string} />
            <Form.HiddenField name="source" value={onboardingAttributes.source} />
            <Form.HiddenField name="commerceType" value={onboardingAttributes.commerceType} />
            <Form.HiddenField name="reCompanyId" value={onboardingAttributes.referralCompanyId} />
          </Form>

          <Body variant="Body/Body Small">
            By signing up for Modern Dropship, you agree to our{' '}
            <SupportLink article="xftun5gcbw">Terms of Service</SupportLink>,{' '}
            <SupportLink article="dye4z9k87t">Privacy Policy</SupportLink> and{' '}
            <SupportLink article="qgnye41fku">Security Policy</SupportLink>.
          </Body>
        </Flex>
      </SignUpPageLayout.Aside>
    </SignUpPageLayout.Root>
  );
};

export default SignUpPage;
