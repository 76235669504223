import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import Card from 'storybook/stories/cells/Card';

export const HEIGHT = '115px';

type StepStatusProp = {
  /* The status of the step */
  status?: 'default' | 'active' | 'completed';
};

type CircleProps = StepStatusProp;
type StepTextProps = StepStatusProp;
type WrapperProps = StepStatusProp;

interface StepProps extends StepStatusProp {
  /* The number of the step */
  number: string | number;
  /* The text to display below the circled number */
  children: string;
}

const CIRCLE_SIZE = 34;
const COMPLETED_CIRCLE_SIZE = 44;
const CIRCLE_SIZE_DIFFERENCE = COMPLETED_CIRCLE_SIZE - CIRCLE_SIZE;

const Circle = styled.span<CircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-radius: 100%;

  font-size: ${CIRCLE_SIZE / 2}px;

  ${({ theme }) => css`
    color: ${theme.color.gray400};
    background: ${theme.color.white};
    border: 2px solid ${theme.color.gray400};
    margin-bottom: 16px;
  `}

  ${({ theme, status }) =>
    status === 'completed' &&
    css`
      width: ${COMPLETED_CIRCLE_SIZE}px;
      height: ${COMPLETED_CIRCLE_SIZE}px;

      border-color: ${theme.color.blue500};
      margin-bottom: ${`calc(16px - ${CIRCLE_SIZE_DIFFERENCE / 2}px)`};
    `}

  ${({ theme, status }) =>
    status === 'active' &&
    css`
      color: ${theme.color.white};
      background-color: ${theme.color.blue500};
      border-color: ${theme.color.blue500};
    `}

  &::before,
  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 2px;
    width: calc(50% - ${CIRCLE_SIZE / 2}px);
    background-color: ${({ theme }) => theme.color.gray400};
    z-index: 1;

    ${({ status }) =>
      status === 'completed' &&
      css`
        width: calc(50% - ${COMPLETED_CIRCLE_SIZE / 2}px);
      `}

    ${({ theme, status }) =>
      status === 'completed' &&
      css`
        background-color: ${theme.color.blue500};
      `}
  }

  &::before {
    left: 0;

    ${({ theme, status }) =>
      status === 'active' &&
      css`
        background-color: ${theme.color.blue500};
      `}
  }

  &::after {
    right: 0;
  }
`;

const CompletedCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-radius: 100%;

  ${({ theme }) => css`
    color: ${theme.color.white};
    background: ${theme.color.blue500};
  `};

  ${materialSymbol({ name: 'check' })}
`;

const StepText = styled.span<StepTextProps>`
  ${({ status }) =>
    status === 'active' &&
    css`
      font-weight: bold;
    `}
`;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  ${({ status }) =>
    status !== 'completed' &&
    css`
      padding-top: ${CIRCLE_SIZE_DIFFERENCE / 2}px;
    `}
`;

/**
 * A step UI. Intended to be used within the `Stepper` component.
 */
const Step = ({ number, status = 'default', children }: StepProps) => {
  return (
    <Wrapper status={status}>
      <Circle status={status}>{status === 'completed' ? <CompletedCircle /> : number}</Circle>
      <StepText status={status}>{children}</StepText>
    </Wrapper>
  );
};

/**
 * A `Card` wrapper for `Step` components, displaying them properly in a row.
 *
 * Exports `Step` for convenience.
 */
const Stepper = styled(Card)`
  display: grid;
  grid-auto-flow: column;
  height: ${HEIGHT};
  width: 100%;
  padding: 24px 0 16px;
`;

export { Step };
export default Stepper;
