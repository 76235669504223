import { useDispatch } from 'react-redux';

import Flex from '@react-css/flex';
import useAlertQueue from 'hooks/useAlertQueue';
import { updateOnboardingStatus } from 'store/slices/company';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { enableSSO } from 'utils/api/login';

const EnableSSOCard = ({ skipped, done }) => {
  const alertQueue = useAlertQueue();
  const dispatch = useDispatch();

  const onSkip = () => {
    dispatch(updateOnboardingStatus({ enableSSOSkipped: true }));
  };

  const onSSOEnable = () => {
    enableSSO()
      .then((data) => {
        window.location.href = data.link;
      })
      .catch((err) => {
        alertQueue.addErrorAlert('Something went wrong', err.message);
      });
  };

  return (
    <GetStartedCard
      isComplete={done}
      title="Enable SSO"
      completedUi={
        <Body as="p">
          Manage your SSO settings from your{' '}
          <Link to="/settings/company/profile">Company settings</Link>.
        </Body>
      }
    >
      {skipped ? (
        <Body as="p">
          If you change your mind you can always manage your SSO settings from your{' '}
          <Link to="/settings/company/profile">Company settings</Link>.
        </Body>
      ) : (
        <Flex column gap="16px">
          <Body as="p">
            Modern Dropship allows you to use Single Sign-On for authentication. You will need to
            set it up before your team can use it.{' '}
            <SupportLink article="vp8621wrrt">More Info</SupportLink>
          </Body>

          <Body>
            Skipping the SSO setup means you will continue to use the current passwordless login
            system.
          </Body>

          <Flex.Item alignSelfEnd>
            <Flex gap="8px">
              <SecondaryButton size="small" onClick={onSkip}>
                Skip
              </SecondaryButton>
              <PrimaryButton size="small" onClick={onSSOEnable}>
                Enable SSO
              </PrimaryButton>
            </Flex>
          </Flex.Item>
        </Flex>
      )}
    </GetStartedCard>
  );
};

export default EnableSSOCard;
