/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Message from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import { useFetchSessions } from 'store/slices/security';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Toggle from 'storybook/stories/molecules/Toggle';
import { enableSSO } from 'utils/api/login';
import { prettyTimeBetween } from 'utils/date';

import { SettingsMain, SettingsPageHeader } from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { updateUserCompany } from 'store/slices/settings/company';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

const SecuritySettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const { sessions } = useSelector((state) => state.security);
  const [sessionsLoading, sessionsError] = useFetchSessions(sessions);
  const [ssoConnecting, setSSOConnecting] = useState(false);
  const onSSOEnable = () => {
    setSSOConnecting(true);
    enableSSO()
      .then((data) => {
        window.location.href = data.link;
      })
      .catch((err) => {
        alertQueue.addErrorAlert('Something went wrong', err.message);
      })
      .finally(() => setSSOConnecting(false));
  };

  const updatePrivacySettings = (event) => {
    const privacySettings = {
      disableSudoAccess: !event.target.checked,
    };
    dispatch(updateUserCompany({ privacy: privacySettings })).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      alertQueue.addSuccessAlert('Success', 'Saved privacy preferences');
    });
  };

  const disabledAccess = company.privacy.disableSudoAccess;

  return (
    <SettingsMain>
      <SettingsPageHeader title="Security" />
      <h4>Single Sign-On</h4>
      <p className="text-muted small">
        Modern Dropship offers Single Sign-On as an alternative to Passwordless Login.{' '}
        <SupportLink article="vp8621wrrt">Learn more about SSO.</SupportLink>
      </p>
      <SecondaryButton size="small" onClick={onSSOEnable}>
        {ssoConnecting ? <Spinner color="primary" className="mx-4" small /> : 'Manage SSO'}
      </SecondaryButton>
      <hr className="my-4" />
      <h4>Privacy</h4>
      <Toggle name="allowedSudoAccess" checked={!disabledAccess} onChange={updatePrivacySettings}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="test">Let support access my account</label>
        <p className="text-muted small">
          Disabling this setting will prolong support time. Unless your organization&apos;s security
          policy forbids it, please grant us access.
        </p>
      </Toggle>
      <hr className="my-4" />
      <h4>Active Sessions</h4>
      <p className="text-muted small">
        Here&apos;s a list of everywhere you&apos;re signed in right now. If you don&apos;t
        recognize any of these sessions, please contact support.
      </p>
      {sessionsLoading || !sessions ? (
        <Spinner small color="primary" />
      ) : sessionsError ? (
        <Message show animate={false} kind="error">
          <p className="small m-0 p-0">
            We&apos;re unable to load your sessions at this time. If this problem persists, please
            contact support.
          </p>
        </Message>
      ) : (
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">IP</th>
              <th scope="col">LAST CAPTURED</th>
            </tr>
          </thead>
          <tbody>
            {sessions
              .map(({ ip, created }, idx) => {
                const activeFor = prettyTimeBetween(created, new Date().toString());
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={idx + created}>
                    <td>{ip}</td>
                    <td>
                      {activeFor === 'ongoing' ? 'less than a second ago' : `${activeFor} ago`}
                    </td>
                  </tr>
                );
              })
              .reverse()}
          </tbody>
        </table>
      )}
    </SettingsMain>
  );
};

export default SecuritySettings;
