import { useQuery } from '@tanstack/react-query';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useAppSelector } from 'store';
import {
  selectIsDropshipSetup,
  selectIsGetStartedComplete,
  selectIsSeller,
} from 'store/selectors/me/company';
import Menu from 'storybook/stories/cells/Menu';
import Badge from 'storybook/stories/molecules/Badge';
import { getPartners } from 'utils/api/partners';

const FETCHING_PENDING_PARTNERS_QUERY_KEY = ['getPartners', { pending: true }] as const;

interface MainMenuProps {
  isActive: (path: string) => boolean;
}

const MainMenu = ({ isActive }: MainMenuProps) => {
  const isSeller = useAppSelector(selectIsSeller);
  const isGetStartedComplete = useAppSelector(selectIsGetStartedComplete);
  const isDropshipSetup = useAppSelector(selectIsDropshipSetup);

  const isWholesaleEnabled = useFeatureFlagEnabled('wholesale');

  /**
   * Queries
   */

  const fetchingPendingPartners = useQuery({
    queryKey: FETCHING_PENDING_PARTNERS_QUERY_KEY,
    queryFn: ({ queryKey }) => getPartners(queryKey[1]),
    enabled: isDropshipSetup,
  });

  /**
   * Helpers
   */

  const partnerCount = fetchingPendingPartners.data?.data?.length;

  /**
   * Render Guards
   */

  if (!isDropshipSetup) {
    return (
      <Menu>
        <Menu.Link
          to="/onboarding"
          $iconName="select_check_box"
          $isActive={isActive('/onboarding')}
        >
          Set Up Dropship
        </Menu.Link>
      </Menu>
    );
  }

  /**
   * Render
   */

  return (
    <Menu>
      {!isGetStartedComplete && (
        <Menu.Link
          to="/get-started"
          $iconName="select_check_box"
          $isActive={isActive('/get-started')}
        >
          Get Started
        </Menu.Link>
      )}

      {isWholesaleEnabled && (
        <Menu.Link $iconName="description" to="/quotes" $isActive={isActive('/quotes')}>
          Quotes
        </Menu.Link>
      )}

      <Menu.Link $iconName="download" to="/orders" $isActive={isActive('/orders')}>
        Orders
      </Menu.Link>

      <Menu.Link $iconName="receipt_long" to="/invoices" $isActive={isActive('/invoices')}>
        Invoices
      </Menu.Link>

      <Menu.Link $iconName="widgets" to="/products" $isActive={isActive('/products')}>
        Products
      </Menu.Link>

      {isSeller && (
        <Menu.Link $iconName="request_quote" to="/prices" $isActive={isActive('/prices')}>
          Price Lists
        </Menu.Link>
      )}

      <Menu.Link to="/partners" $iconName="group" $isActive={isActive('/partners')}>
        Partners
        <Badge variant="info" count={partnerCount} data-testid="partner-count-badge" />
      </Menu.Link>
    </Menu>
  );
};

export default MainMenu;
