import styled, { css } from 'styled-components';

import materialSymbol from 'storybook/mixins/materialSymbol';
import resetButtonStyles from 'storybook/mixins/resetButtonStyles';
import Card from 'storybook/stories/cells/Card';

interface QuoteDateProps {
  iconName?: string;
}

export const PartnerName = styled.h1`
  font-size: 34px;
`;

export const QuoteDate = styled.span<QuoteDateProps>`
  display: flex;
  font-size: 18px;
  align-items: center;

  ${({ theme }) => css`
    gap: 8px;
    color: ${theme.color.gray500};
  `}

  ${({ iconName }) => materialSymbol({ name: iconName || 'calendar_month' })}
`;

export const ShippingDateLabel = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray400};
`;

export const AddressHeading = styled.strong`
  display: block;
  font-size: 13px;
  letter-spacing: 0.3em;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.color.gray400};
`;

export const Address = styled.address`
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;

  strong {
    font-size: 18px;
  }
`;

export const AddressLine = styled.span`
  display: block;
`;

export const EditButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
`;

export const EditDot = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 100%;

  ${({ theme }) => css`
    color: ${theme.color.gray500};
    background-color: ${theme.color.gray200};
    ${materialSymbol({ name: 'edit_note' })}
  `};
`;

export const EditText = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.color.gray300};
`;

export const TableDataCellImage = styled.img`
  display: block;
  max-width: 80px;

  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray300};
    border-radius: 4px;
  `};
`;

export const DeleteItemButton = styled.button`
  ${resetButtonStyles}
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.color.error500};

    &[disabled] {
      cursor: not-allowed;
      color: ${theme.color.gray400};
    }
  `};

  ${materialSymbol({ name: 'delete_forever' })};
`;

export const MinAmountLeftText = styled.span`
  text-align: end;
  margin-bottom: 12px;
  font-size: 12px;
`;

export const QuoteHeadCard = styled(Card)`
  border-left: 6px solid ${({ theme }) => theme.color.blue500};
  padding: 32px;
`;
