import { createRef, useEffect, useState } from 'react';
import type {
  ControlProps,
  DropdownIndicatorProps,
  MultiValue,
  MultiValueRemoveProps,
  SelectInstance,
} from 'react-select';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';

import elevate from 'storybook/mixins/elevate';
import Icon from 'storybook/stories/molecules/Icon';

export type SelectOption = {
  value: string;
  label: string;
};

interface SellerNetworkCategoriesSelectProps {
  options: SelectOption[];
  defaultValues: SelectOption[];
  onChange: (option: MultiValue<SelectOption>) => void;
  id?: string;
}

interface SelectWrapperProps {
  controlRadius: string;
}

const MIN_CONTROL_HEIGHT = 44;

const DropDownIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.color.gray500};
  `}
`;

const SelectWrapper = styled.div<SelectWrapperProps>`
  .react-select__control {
    max-width: 480px;
    gap: 10px;
    border: 1px;
    min-height: ${MIN_CONTROL_HEIGHT}px;
    padding: 0px 4px;

    ${({ theme, controlRadius }) => css`
      background: ${theme.color.gray100};
      border-color: ${theme.color.gray100};
      border-radius: ${controlRadius};
    `}
  }

  .react-select__control--is-focused {
    ${({ theme }) => css`
      box-shadow: 0 0 0 1px ${theme.color.gray500};
    `}
  }

  .react-select__value-container {
    padding: 4px 0;
  }

  .react-select__placeholder {
    padding: 4px;
    font-size: 14px;

    ${({ theme }) => css`
      color: ${theme.color.gray600};
    `}
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__multi-value {
    border-radius: 100px;
    ${({ theme }) => css`
      background-color: ${theme.color.gray500};
      color: ${theme.color.white};
      margin: 4px;
    `}
  }

  .react-select__multi-value__label {
    font-weight: 500;
    padding: 2px;
    padding-left: 8px;
    font-size: 14px;

    ${({ theme }) => css`
      color: ${theme.color.white};
    `}
  }

  .react-select__multi-value__remove {
    border-radius: 0 100px 100px 0;
    padding-right: 8px;
    padding-left: 4px;

    &:hover {
      ${({ theme }) => css`
        background-color: ${theme.color.error100};
        color: ${theme.color.error500};
      `}
    }
  }

  .react-select__menu {
    ${elevate('2')};
    max-width: 480px;
    margin-top: 1px;
    border-radius: 16px;
    padding: 16px;
  }

  .react-select__option {
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
      ${({ theme }) => css`
        background-color: ${theme.color.gray100};
      `}
    }
  }

  .react-select__option--is-selected,
  .react-select__option--is-focused {
    ${({ theme }) => css`
      background-color: ${theme.color.gray100};
    `}
  }

  .react-select__input-container {
    font-size: 14px;
    padding: 4px;

    ${({ theme }) => css`
      font-family: ${theme.font.secondary};
      color: ${theme.color.gray700};
    `}
  }
`;

const ControlContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const Control = ({ children, ...props }: ControlProps<SelectOption, true>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Control {...props}>
    <ControlContentWrapper ref={props.innerRef}>{children}</ControlContentWrapper>
  </components.Control>
);

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, true>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <DropDownIcon name="arrow_drop_down" />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<SelectOption, true>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValueRemove {...props}>
      <Icon name="close" size="20px" />
    </components.MultiValueRemove>
  );
};

const GetControlHeight = (ref: React.RefObject<SelectInstance<SelectOption, true>>) => {
  const [height, setHeight] = useState(MIN_CONTROL_HEIGHT);
  useEffect(() => {
    if (ref?.current?.controlRef?.clientHeight) {
      setHeight(ref.current.controlRef.clientHeight);
    }
  }, [ref]);
  return height;
};

const SellerNetworkCategoriesSelect = ({
  options,
  defaultValues,
  onChange,
  id,
}: SellerNetworkCategoriesSelectProps) => {
  const ref = createRef<SelectInstance<SelectOption, true>>();
  const controlHeight = GetControlHeight(ref);
  const controlRadius = controlHeight > MIN_CONTROL_HEIGHT ? '16px' : '100px';

  const onMenuOpen = () => {
    if (ref?.current) {
      setTimeout(() => {
        ref?.current?.focusedOptionRef?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1);
    }
  };

  return (
    <SelectWrapper controlRadius={controlRadius}>
      <CreatableSelect
        isMulti
        isClearable={false}
        components={{ Control, DropdownIndicator, MultiValueRemove }}
        options={options}
        placeholder="Categories"
        maxMenuHeight={150}
        defaultValue={defaultValues}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        classNamePrefix="react-select"
        ref={ref}
        id={id}
      />
    </SelectWrapper>
  );
};

export default SellerNetworkCategoriesSelect;
