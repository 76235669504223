import { ChangelogEntryType, type ChangelogEntry } from 'CHANGELOG';
import Card from 'storybook/stories/cells/Card';
import Timeline from 'storybook/stories/organisms/Timeline';

interface WhatsNewEventMetadata {
  titlePrefix: string;
  iconName: string;
}

const EVENT_METADATA_BY_TYPE: Record<ChangelogEntryType, WhatsNewEventMetadata> = {
  [ChangelogEntryType.NewFeature]: {
    titlePrefix: 'New Feature',
    iconName: 'new_releases',
  },
  [ChangelogEntryType.Improvement]: {
    titlePrefix: 'Improvement',
    iconName: 'trending_up',
  },
  [ChangelogEntryType.Fix]: {
    titlePrefix: 'Fix',
    iconName: 'bug_report',
  },
  [ChangelogEntryType.Update]: {
    titlePrefix: 'Update',
    iconName: 'update',
  },
};

interface EntryTimelineProps {
  entries: ChangelogEntry[];
}

const TimelineCard = ({ entries }: EntryTimelineProps) => {
  return (
    <Card>
      <Timeline bgColor="white">
        {entries.map((event, index) => {
          const { iconName, titlePrefix } = EVENT_METADATA_BY_TYPE[event.type];

          return (
            <Timeline.Event
              iconName={iconName}
              title={`${titlePrefix}: ${event.title}`}
              timestamp={event.timestamp}
              shouldRenderTime={false}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {event.children}
            </Timeline.Event>
          );
        })}
      </Timeline>
    </Card>
  );
};

export default TimelineCard;
