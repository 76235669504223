import type { ReactNode } from 'react';
import styled from 'styled-components';

import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import type { Money } from 'types/general';
import type { Quote } from 'types/models/quote';
import { getCurrencySymbol, humanizeMoney } from 'utils/currencies';

interface TotalsCardProps {
  quote: Quote;
  children?: ReactNode;
}

interface EstimatedTotalProps {
  amount: Money;
  currency: string;
  status: string;
}

export const TableWrapper = styled.div`
  ${Table.TH} {
    font-size: 12px;
    color: ${({ theme }) => theme.color.gray400};
  }

  ${Table.TD} {
    font-size: 24px;
  }

  ${Table.TR}:last-child ${Table.TH},
  ${Table.TR}:last-child ${Table.TD} {
    border-top: 1px solid ${({ theme }) => theme.color.gray200};
  }
`;

const EstimatedTotal = ({ amount, currency, status }: EstimatedTotalProps) => {
  if (status === 'rejected') return <span>{getCurrencySymbol(currency)}--</span>;
  if (amount.amount === 0) return <span>TBD</span>;
  if (['accepted', 'pending', 'draft'].includes(status))
    return <span>{humanizeMoney(amount)}</span>;

  return null;
};

const TotalsCard = ({ quote, children }: TotalsCardProps) => {
  const subtotalPrice = quote?.subtotalPrice ?? { amount: 0, currency: 'USD' };
  const totalPrice = quote?.totalPrice ?? { amount: 0, currency: 'USD' };

  return (
    <Card>
      <TableWrapper>
        <Table>
          <Table.TBody>
            <Table.TR align="right">
              <Table.TH>Items Total</Table.TH>
              <Table.TD>{humanizeMoney(subtotalPrice)}</Table.TD>
            </Table.TR>
            <Table.TR align="right">
              <Table.TH>Taxes</Table.TH>
              <Table.TD data-testid="taxes-total">
                <EstimatedTotal
                  amount={quote.estimatedTaxPrice}
                  currency={quote.currency}
                  status={quote.status}
                />
              </Table.TD>
            </Table.TR>
            <Table.TR align="right">
              <Table.TH>Shipping</Table.TH>
              <Table.TD data-testid="shipping-total">
                <EstimatedTotal
                  amount={quote.estimatedShippingPrice}
                  currency={quote.currency}
                  status={quote.status}
                />
              </Table.TD>
            </Table.TR>
            <Table.TR align="right" bold>
              <Table.TH>Total</Table.TH>
              <Table.TD>{humanizeMoney(totalPrice)}</Table.TD>
            </Table.TR>
          </Table.TBody>
        </Table>
      </TableWrapper>

      {children}
    </Card>
  );
};

export default TotalsCard;
