import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import type { HTMLAttributes, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import DashboardPage from 'components/Dashboard/DashboardPage';
import Page from 'storybook/stories/cells/Page';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';

interface QuoteLayoutProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  children: ReactNode;
}

interface QuoteLayoutMainProps {
  children: ReactNode;
}

interface QuoteLayoutAsideProps {
  children: ReactNode;
}

const QuoteLayout = ({ children, title, ...gridProps }: QuoteLayoutProps) => {
  return (
    <DashboardPage>
      <Page>
        <Page.Head title={title}>
          <PrimaryButton size="small" as={Link} to="/quotes">
            All Quotes
          </PrimaryButton>
        </Page.Head>

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Grid columns="auto 360px" gap="24px" {...gridProps}>
          {children}
        </Grid>
      </Page>
    </DashboardPage>
  );
};

export const QuoteLayoutMain = ({ children }: QuoteLayoutMainProps) => {
  return (
    <Flex flexDirection="column" gap="24px">
      {children}
    </Flex>
  );
};

export const QuoteLayoutAside = ({ children }: QuoteLayoutAsideProps) => {
  return (
    <Flex flexDirection="column" gap="24px">
      {children}
    </Flex>
  );
};

export default QuoteLayout;
