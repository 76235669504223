import type { User } from 'types/models/user';
import config from 'utils/config';
import { postJSONOptions } from 'utils/options';
import { postRequest, request } from 'utils/request';

export interface SudoTokenParams {
  companyObjectId: string;
}

export const sudoToken = (params: SudoTokenParams) => {
  return postRequest('/login/sudo', params);
};

export interface SwitchCompanyParams {
  companyObjectId: string;
}

export const switchCompany = (params: SwitchCompanyParams) => {
  return postRequest('/login/switch-company', params);
};

export interface GenerateOtpParams {
  email: string;
  redirect?: string;
  rootDir?: string;
}

interface GenerateOtpResponse {
  sso?: boolean;
  link?: string;
}

export const generateOTP = (params: GenerateOtpParams): Promise<GenerateOtpResponse> => {
  const url = `${config.apiURL}/login/v2/generate`;
  const options = postJSONOptions(params);
  return request(url, options);
};

export interface VerifyOtpParams {
  token: string;
}

interface VerifyOtpResponse {
  redirect: string;
  user: User;
}

// verifyOTP verifies a one-time password token. It does not return a Response-wrapped object
export const verifyOTP = (params: VerifyOtpParams): Promise<VerifyOtpResponse> => {
  const url = `${config.apiURL}/login/verify`;
  const options = postJSONOptions(params);
  return request(url, options);
};

export const enableSSO = () => postRequest('/login/sso/enable');
