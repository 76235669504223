import { debounce } from 'lodash';
import { useState, type ChangeEvent } from 'react';

import FormField from 'components/Common/FormField';
import { DeleteItemButton, TableDataCellImage } from 'containers/Quotes/shared/Styles';
import useAlertQueue from 'hooks/useAlertQueue';
import Table from 'storybook/stories/cells/Table';
import type { Quote, QuoteItem } from 'types/models/quote';
import { deleteQuoteItem, updateQuoteItem } from 'utils/api/quotes';
import { humanizeMoney } from 'utils/currencies';

interface QuoteItemTableProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  quote: Quote;
  refreshQuote: () => void;
  isEditable: boolean;
}

interface QuantityFormProps {
  quoteId: string;
  quoteItem: QuoteItem;
  refreshQuote: () => void;
}

const QuantityForm = ({ quoteId, quoteItem, refreshQuote }: QuantityFormProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  const handleQuantityChange = debounce((event: ChangeEvent<HTMLInputElement>, item: QuoteItem) => {
    const { valueAsNumber: quantity } = event.target;

    updateQuoteItem(quoteId, item.id, {
      productId: item.productId,
      variantId: item.variantId,
      quantity,
    })
      .then(() => {
        addSuccessAlert(
          'Your Quote has been updated',
          'The Quote Item quantity was changed successfully'
        );
      })
      .then(refreshQuote)
      .catch((error) => {
        addErrorAlert('Unable to update Quote Item', 'Please try again.');

        console.error(`Unable to update item ${item.id} for quote ${quoteId}`, error.message);
      });
  }, 500);

  const [quantity, setQuantity] = useState(quoteItem.quantity);

  return (
    <FormField
      type="number"
      min="1"
      name="quantity"
      data-testid="quantity-input"
      value={quantity}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setQuantity(event.target.valueAsNumber);
        handleQuantityChange(event, quoteItem);
      }}
    />
  );
};

const QuoteItemTable = ({
  isLoading,
  setIsLoading,
  quote,
  refreshQuote,
  isEditable,
}: QuoteItemTableProps) => {
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();

  // Event Handlers

  const handleDeleteButtonClick = (quoteItemId: string) => {
    setIsLoading(true);

    deleteQuoteItem(quote.id, quoteItemId)
      .then(() => {
        addSuccessAlert('Your Quote has been updated', 'The Quote Item was removed successfully');
      })
      .then(refreshQuote)
      .catch((error) => {
        addErrorAlert('Unable to delete item from Quote', 'Please try again.');
        console.error(`Unable to delete item ${quoteItemId} from quote ${quote.id}`, error.message);
      })
      .finally(() => setIsLoading(false));
  };

  // Render

  return (
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH aria-label="Product Image" width="80px" />
          <Table.TH>SKU</Table.TH>
          <Table.TH>Quantity</Table.TH>
          <Table.TH>Unit Price</Table.TH>
          <Table.TH>Total</Table.TH>
          {isEditable && <Table.TH aria-label="Delete Item" />}
        </Table.TR>
      </Table.THead>

      <Table.TBody>
        {quote.items.map((item) => {
          const { sku } =
            item.product.variants?.find((variant) => variant.id === item.variantId) ?? {};

          return (
            <Table.TR key={item.id}>
              <Table.TD>
                {item.product.images && (
                  <TableDataCellImage
                    src={item.product.images[0].source}
                    alt={`Product image for ${item.product.title}`}
                  />
                )}
              </Table.TD>
              <Table.TD>{sku}</Table.TD>
              <Table.TD>
                {isEditable ? (
                  <QuantityForm quoteId={quote.id} quoteItem={item} refreshQuote={refreshQuote} />
                ) : (
                  item.quantity
                )}
              </Table.TD>
              <Table.TD>{humanizeMoney(item.unitPrice)}</Table.TD>
              <Table.TD>{humanizeMoney(item.extendedPrice)}</Table.TD>

              {isEditable && (
                <Table.TD align="right">
                  <DeleteItemButton
                    type="button"
                    onClick={() => handleDeleteButtonClick(item.id)}
                    disabled={isLoading}
                    data-testid="delete-quote-item-button"
                  >
                    Delete
                  </DeleteItemButton>
                </Table.TD>
              )}
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};

export default QuoteItemTable;
