import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useAlertQueue from 'hooks/useAlertQueue';
import type { Quote } from 'types/models/quote';
import { deleteQuote } from 'utils/api/quotes';

const useAbandonedQuotePrompt = (quote: Quote) => {
  const history = useHistory();
  const { addErrorAlert } = useAlertQueue();

  useEffect(() => {
    if (_isEmpty(quote)) {
      history.push('/quotes');
    }
  }, [history, quote]);

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      // Current browsers
      e.preventDefault();

      // Legacy browsers
      // This is required by Chrome to trigger the browser's warning
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      // eslint-disable-next-line no-param-reassign
      e.returnValue = '';
    };

    const removeQuote = () => {
      deleteQuote(quote.id)
        .then(() => {})
        .catch((error) => {
          addErrorAlert('Unable to cancel Quote', 'Please try again.');
          console.error(`Unable to cancel Quote ${quote.id}`, error.message);
        });
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    window.addEventListener('unload', removeQuote);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener('unload', removeQuote);
    };
  }, [addErrorAlert, quote.id]);
};

export default useAbandonedQuotePrompt;
