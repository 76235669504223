import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { useAppDispatch } from 'store';
import { updateOnboardingStatus } from 'store/slices/company';
import { fetchGettingStarted } from 'store/slices/getStarted';
import { getCurrentUserAndCompany } from 'store/slices/me';
import Body from 'storybook/stories/molecules/Body';
import { installEasyPost, type InstallEasyPostParams } from 'utils/api/easypost';

import CardFormField from 'components/Common/CardFormField';
import useAlertQueue from 'hooks/useAlertQueue';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';

interface ConnectEasyPostCardProps {
  done: boolean;
}

type FormValues = {
  apiKey: string;
};

const EasyPostSettingsForm = () => {
  const dispatch = useAppDispatch();
  const alertQueue = useAlertQueue();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const install = useMutation({
    mutationFn: (params: InstallEasyPostParams) => installEasyPost(params),
    onSuccess: () => {
      dispatch(fetchGettingStarted());
      dispatch(getCurrentUserAndCompany());
      alertQueue.addSuccessAlert('Success', 'EasyPost API Key updated successfully');
    },
    onError: (error: any) => {
      console.error('Could not install EasyPost', error);
      alertQueue.addErrorAlert('Something went wrong', error.message);
    },
  });

  const onSkip = () => {
    dispatch(updateOnboardingStatus({ connectEasyPostSkipped: true }));
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        install.mutate(data);
      })}
    >
      <Flex column gap="16px">
        <CardFormField
          autoComplete="off"
          type="text"
          id="easypost-apikey-input"
          label="EasyPost API Key"
          placeholder="EZAK..."
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('apiKey', {
            required: 'API Key is required',
          })}
          error={errors.apiKey}
        />

        <Flex.Item alignSelfEnd>
          <Flex gap="8px">
            <SecondaryButton size="small" onClick={onSkip}>
              Mark as Completed
            </SecondaryButton>

            <PrimaryButton size="small" type="submit" disabled={install.isLoading}>
              Update API Key
            </PrimaryButton>
          </Flex>
        </Flex.Item>
      </Flex>
    </form>
  );
};

const ConnectEasyPostCard = ({ done }: ConnectEasyPostCardProps) => {
  return (
    <GetStartedCard
      isComplete={done}
      title="Connect EasyPost"
      completedUi={
        <Body as="p">
          You can update your shipping responsibility and share your EasyPost account in your{' '}
          <Link to="/settings/dropshipping/shipping">Shipping settings</Link> or manage your
          EasyPost Connection from your{' '}
          <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
        </Body>
      }
    >
      <Flex column gap="16px">
        <Body as="p">
          We support EasyPost integration for streamlined shipping label creation using your
          approved carriers. Or you can set up required shipping services manually through the{' '}
          <Link to="/settings/dropshipping/shipping">shipping settings</Link>.
        </Body>

        <Body as="p">
          Create an <a href="https://www.easypost.com/">EasyPost account</a> and enter your API key
          below. <SupportLink article="anymr8mmb3">More Info</SupportLink>
        </Body>

        <EasyPostSettingsForm />
      </Flex>
    </GetStartedCard>
  );
};

export default ConnectEasyPostCard;
