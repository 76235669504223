// csvMappings holds data related ton csv mappings

// Modern Dropship Fields that can be mapped to a seller's product file headers
// Ref: https://support.moderndropship.com/resources/importing-products#elements
export const convictionalFields = [
  {
    name: 'productCode',
    convictionalField: 'Product Code',
    required: true,
    description: 'Your unique code for this product.',
  },
  {
    name: 'productTitle',
    convictionalField: 'Product Title',
    required: true,
    description: 'A short description of your product',
  },
  {
    name: 'price',
    convictionalField: 'Price',
    required: true,
    description: 'The retail price for the variant (the MSRP)',
  },
  {
    name: 'inventory',
    convictionalField: 'Inventory',
    required: true,
    description: 'The current stock level',
  },
  {
    name: 'vendor',
    convictionalField: 'Vendor',
    required: false,
    description: 'Your brand or the product brand',
  },
  {
    name: 'tags',
    convictionalField: 'Tags',
    required: false,
    description: 'Tags for the product',
  },
  {
    name: 'googleProductCategoryCode',
    convictionalField: 'Google Product Category Code',
    required: false,
    description: 'The Google Product Category code this product belongs to',
  },
  {
    name: 'googleProductCategoryName',
    convictionalField: 'Google Product Category Name',
    required: false,
    description: 'The Google Product Category name this product belongs to',
  },
  {
    name: 'sku',
    convictionalField: 'SKU',
    required: true,
    description:
      'Unique SKU for the variant. If left blank it will be generated by combining the option values entered below.',
  },
  {
    name: 'variantCode',
    convictionalField: 'Variant Code',
    required: true,
    description: 'Your internal identifier for this variant. Could be the same as SKU.',
  },
  {
    name: 'variantTitle',
    convictionalField: 'Variant Title',
    required: false,
    description:
      'Description of the the variant. If left blank it will be generated by combinging the product title and the option values below.',
  },
  {
    name: 'image',
    convictionalField: 'Image URL',
    required: false,
    description: "The URL for this variant's image",
  },
  {
    name: 'bodyHTML',
    convictionalField: 'HTML Body',
    required: false,
    description:
      'The HTML body for the website display. The content of this field will be sanitized for security reasons, but the structure will remain the same as it is provided.',
  },
  {
    name: 'option1Name',
    convictionalField: 'Option1 Name',
    required: true,
    description:
      'The name of the first option. Options store data to help differentiate variants. Examples of common options are "size" and "colour"',
  },
  {
    name: 'option1',
    convictionalField: 'Option1 Value',
    required: true,
    description: 'The first option value for the variant.',
  },
  {
    name: 'option2Name',
    convictionalField: 'Option2 Name',
    required: false,
    description:
      'The name of the second option. Options store data to help differentiate variants. Examples of common options are "size" and "colour"',
  },
  {
    name: 'option2',
    convictionalField: 'Option2 Value',
    required: false,
    description: 'The second option value for this variant',
  },
  {
    name: 'option3Name',
    convictionalField: 'Option3 Name',
    required: false,
    description:
      'The name of the third option. Options store data to help differentiate variants. Examples of common options are "size" and "colour"',
  },
  {
    name: 'option3',
    convictionalField: 'Option3 Value',
    required: false,
    description: 'The third option value for this variant',
  },
  {
    name: 'dimensionsUnits',
    convictionalField: 'Dimension Units',
    required: false,
    description: 'Units for variant dimensions',
  },
  {
    name: 'length',
    convictionalField: 'Length',
    required: false,
    description: 'Length of this variant',
  },
  {
    name: 'width',
    convictionalField: 'Width',
    required: false,
    description: 'Width of this variant',
  },
  {
    name: 'height',
    convictionalField: 'Height',
    required: false,
    description: 'Height of this variant',
  },
  {
    name: 'weightUnits',
    convictionalField: 'Weight Units',
    required: false,
    description: 'Units for variant weight',
  },
  {
    name: 'weight',
    convictionalField: 'Weight',
    required: false,
    description: 'Weight of this variant',
  },
  {
    name: 'barcode',
    convictionalField: 'Barcode',
    required: false,
    description: 'Barcode associated with this variant',
  },
  {
    name: 'barcodeType',
    convictionalField: 'Barcode Type',
    required: false,
    description: 'The barcode type. Currently we support UPC and GTIN barcodes.',
  },
];

// supportedFileDelimiters represents the delimiters that we currently accept on product files
export const supportedFileDelimiters = [
  {
    type: 'comma',
    text: 'Comma ( , )',
    value: ',',
  },
  {
    type: 'tabs',
    text: 'Tabs ( \\t )',
    value: '\t',
  },
  {
    type: 'bar',
    text: 'Bar ( | )',
    value: '|',
  },
  {
    type: 'semicolon',
    text: 'Semicolon ( ; )',
    value: ';',
  },
];

// variants will be variantFieldMappings
// NOT DONE YET
// allow multiple headers to be added to tags
export const productFieldMappings = [
  'productCode',
  'bodyHTML',
  'productTitle',
  'vendor',
  'variants',
  'option1Name',
  'option2Name',
  'option3Name',
  'tags',
  'googleProductCategoryCode',
  'googleProductCategoryName',
];

export const variantFieldMappings = [
  'variantCode',
  'inventory',
  'option1',
  'option2',
  'option3',
  'variantTitle',
  'sku',
  'price',
  'image',
  'length',
  'width',
  'height',
  'dimensionsUnits',
  'weightUnits',
  'weight',
  'barcode',
  'barcodeType',
];

export const inventoryFieldMappings = ['sku', 'inventory'];
