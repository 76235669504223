/* eslint-disable no-nested-ternary, jsx-a11y/label-has-associated-control */
import Flex from '@react-css/flex';
import Button from 'components/Common/Button';
import FormField from 'components/Common/FormField';
import Message from 'components/Common/Message';
import Spinner from 'components/Common/Spinner';
import useCommerceChangeDisabled from 'hooks/useCommerceChangeDisabled';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { updateUserCompany, uploadCompanyLogo } from 'store/slices/settings/company';
import Body from 'storybook/stories/molecules/Body';
import Icon from 'storybook/stories/molecules/Icon';
import styled from 'styled-components';
import EMAIL_REGEX from 'utils/email';
import { URL_FIELD_REGEX, getDomainFromUrl, toProperCase } from 'utils/strings';

import { useQuery } from '@tanstack/react-query';
import UrlFormField from 'components/Common/UrlFormField';
import useAlertQueue from 'hooks/useAlertQueue';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCompany, selectIsDropshipSetup } from 'store/selectors/me/company';
import CommerceTypeAvatar from 'storybook/stories/cells/Avatar/CommerceTypeAvatar';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { getDocument } from 'utils/api/documents';
import { SettingsFooter, SettingsMain, SettingsPageHeader } from '../SettingsLayout';

const PACKING_SLIP_ASPECT_RATIO = 2.5;

const LogoFileUpload = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    color: var(--secondary);
    display: inline-block;
    cursor: pointer;
    padding: 0.125rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.75;
    border-radius: 4px;
    font-weight: 400;
    border: 1px solid #d2ddec;
    text-align: center;
    vertical-align: middle;
    margin-top: 0.5rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  &:focus + label,
  & + label:hover {
    color: white;
    background-color: var(--secondary);
    border-color: var(--secondary);
  }

  &:focus + label {
    box-shadow: 0 0 0 0.15rem rgb(110 132 163 / 50%);
  }

  & + label * {
    pointer-events: none;
  }
`;

const LogoContainer = styled.div`
  background-color: white;
  height: 7.5rem;
  width: 18.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--light);
`;

const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const CompanyProfileSettings = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const formId = 'company-settings-form';
  const { name, legalName, url, commerceType, crmEmail, logoId, contactEmail } = company;
  const [imgError, setImgError] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoAspectRatio, setLogoAspectRatio] = useState(null);
  const { isUpdatingCompany, isUploadingLogo } = useSelector(({ settings }) => settings.company);
  const isDropshipSetup = useSelector(selectIsDropshipSetup);
  const isCommerceTypeChangeDisabled = useCommerceChangeDisabled();
  const sendSentryError = useScopedSentryMessage('error');

  const [logoBytes, setLogoBytes] = useState(null);

  useEffect(() => {
    if (logoBytes) {
      const logo = window.document.createElement('img');
      logo.onload = () => {
        setLogoLoading(false);
        setLogoAspectRatio(logo.width / logo.height);
      };
      logo.onerror = () => setImgError(true);
      logo.src = `data:image/png;base64,${logoBytes}`;
    }
  }, [logoBytes]);

  const { handleSubmit, register, formState, reset, control } = useForm({
    defaultValues: {
      name: name || '',
      commerceType: {
        label: toProperCase(commerceType),
        value: commerceType,
      },
      legalName: legalName || '',
      url: url.replace(/https?:\/\//, '') || '',
      crmEmail: crmEmail || '',
      contactEmail: contactEmail || '',
    },
  });

  const { isDirty } = formState;

  const onFormSubmit = (data) => {
    const companyData = {
      ...data,
      url: getDomainFromUrl(data.url).toLowerCase(),
    };

    // Don't include commerceType in form submission if it hasn't changed
    if (companyData.commerceType.value === company.commerceType) {
      delete companyData.commerceType;
    } else {
      companyData.commerceType = companyData.commerceType.value;
    }

    dispatch(updateUserCompany(companyData)).then((action) => {
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      alertQueue.addSuccessAlert('Success', 'Company details were updated');
    });

    reset({ ...data }, { keepDefaultValues: false, keepValues: false, keepDirty: false });
  };

  const fetchingLogo = useQuery({
    enabled: !logoBytes,
    queryKey: ['fetchingLogo', logoId],
    queryFn: () => getDocument(logoId),
    onSuccess: async (response) => {
      try {
        const responseBytes = await response.arrayBuffer();
        const bytes = new Uint8Array(responseBytes);
        const base64String = btoa(String.fromCharCode.apply(null, bytes));

        setLogoBytes(base64String);
      } catch (error) {
        sendSentryError('Unable to download logo', { extra: { error } });
      }
    },
  });

  const onLogoUpload = (evt) => {
    const files = Array.from(evt.target.files);
    if (files.length > 0) {
      const file = files[0];
      const fileType = file.type;
      const fileName = file.name;
      const reader = new FileReader();
      reader.onload = () => {
        const bytes = Array.from(new Uint8Array(reader.result));
        dispatch(uploadCompanyLogo({ bytes, fileType, fileName })).then((action) => {
          if (action.error) {
            alertQueue.addErrorAlert('Something went wrong', action.error.message);
            return;
          }
          alertQueue.addSuccessAlert('Success', 'Company logo has been updated');
        });
      };
      reader.onerror = (error) => {
        console.error(error);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Company Profile">
          <CommerceTypeAvatar commerceType={company.commerceType} />
        </SettingsPageHeader>

        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <FormField
            type="text"
            id="name"
            label="Marketing Name"
            placeholder="Company Name"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('name')}
            size="sm"
            subLabel="This is the name you would use to refer to your Company"
          />

          {isDropshipSetup && (
            <Flex column gap="4px" style={{ marginBottom: '12px' }}>
              <Body variant="Body/Body Small">Commerce Type</Body>

              <Controller
                id="commerceType"
                name="commerceType"
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    isDisabled={isCommerceTypeChangeDisabled.isDisabled}
                    options={[
                      {
                        label: 'Buyer',
                        value: 'buyer',
                      },
                      {
                        label: 'Seller',
                        value: 'seller',
                      },
                    ]}
                    {...field}
                  />
                )}
              />

              {isCommerceTypeChangeDisabled.isDisabled ? (
                <Body variant="Body/Body Small" color="error500">
                  {isCommerceTypeChangeDisabled.reason}
                </Body>
              ) : (
                <Body variant="Body/Body Small" color="gray500">
                  This is the how you interact with Modern Dropship. You can set up a Buyer or
                  Seller account. ⚠️
                  <strong>
                    Changing this will require you to reconnect your platform and Stripe connections
                    if present.
                  </strong>
                </Body>
              )}
            </Flex>
          )}

          <FormField
            type="text"
            id="legal-name"
            label="Legal Name"
            placeholder="Company Name, Inc."
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('legalName')}
            size="sm"
            subLabel="This is the name that shows up on government forms"
          />
          <UrlFormField
            type="text"
            id="url"
            label="Company Website"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('url', {
              required: 'Please enter a company website',
              pattern: {
                value: URL_FIELD_REGEX,
                message: 'Please enter a valid url',
              },
            })}
            error={formState.errors.url}
            placeholder="examplecompany.com"
            size="sm"
            autoComplete="off"
          />
          <FormField
            type="email"
            id="contact-email"
            label="Contact Email"
            subLabel="This email is used for miscellaneous contact, and is shown to partners."
            placeholder="support@example.com"
            error={formState.errors.contactEmail}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('contactEmail', {
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email address',
              },
            })}
            size="sm"
          />
          <FormField
            type="email"
            id="crm-email"
            label="Support BCC Email"
            subLabel="This email will be BCC'd on emails relating to your partners"
            placeholder="crm-email@example.com"
            error={formState.errors.crmEmail}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('crmEmail', {
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email address',
              },
            })}
            size="sm"
          />
        </form>
        <hr className="my-4" />
        <h4 className="mb-3">Company Logo</h4>
        {commerceType === 'buyer' ? (
          <>
            <p className="text-muted small">
              Your logo will appear on packing slips. To keep it from stretching, please upload
              logos with dimensions of 300 x 120 or an aspect ratio of 2.5. We support JPG, JPEG,
              and PNG images.
              <SupportLink article="80s3ig0toc">Learn more about packing slips.</SupportLink>
            </p>
            {logoAspectRatio && logoAspectRatio !== PACKING_SLIP_ASPECT_RATIO && (
              <Message show animate={false} kind="warning">
                <p className="small m-0 p-0">
                  This logo will stretch when printed onto a packing slip. To avoid stretching,
                  please upload a logo with an aspect ratio of 2.5.
                </p>
              </Message>
            )}
          </>
        ) : (
          <p className="text-muted small">We support JPG, JPEG, and PNG images.</p>
        )}
        <LogoContainer>
          {fetchingLogo.isLoading || isUploadingLogo || logoLoading ? (
            <Spinner color="secondary" />
          ) : fetchingLogo.isError || imgError ? (
            <div className="text-center p-3">
              <Icon name="report" className="text-danger">
                <span className="text-muted small">
                  Error downloading image {imgError && '(check file format)'}
                </span>
              </Icon>
            </div>
          ) : !logoId || !logoBytes ? (
            <div className="text-center p-3">
              <Icon name="report" className="text-danger">
                <span className="text-muted small">Add Company Logo</span>
              </Icon>
            </div>
          ) : (
            <LogoImage
              alt="Company Logo"
              onError={() => setImgError(true)}
              src={`data:image/png;base64,${logoBytes}`}
            />
          )}
        </LogoContainer>
        <LogoFileUpload type="file" id="logo-upload" onChange={onLogoUpload} />
        <label htmlFor="logo-upload">
          <Icon name="file_upload">Update Logo</Icon>
        </label>
      </SettingsMain>
      <SettingsFooter>
        <Button
          type="submit"
          form={formId}
          disabled={!isDirty || isUpdatingCompany}
          size="sm"
          color="primary"
          className="mr-2"
        >
          {isUpdatingCompany ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </Button>
      </SettingsFooter>
    </>
  );
};

export default CompanyProfileSettings;
