import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';

import useAlertQueue from 'hooks/useAlertQueue';
import materialSymbol from 'storybook/mixins/materialSymbol';
import Card from 'storybook/stories/cells/Card';
import Table from 'storybook/stories/cells/Table';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import type { Money } from 'types/general';
import type { PriceList } from 'types/models/price-list';
import { getBuyerPriceList } from 'utils/api/buyer/priceLists';
import { deleteQuoteItem } from 'utils/api/quotes';
import { humanizeMoney } from 'utils/currencies';
import { prettyDate } from 'utils/date';

import NewQuoteContext from './NewQuotePage/NewQuoteContext';
import { DeleteItemButton, MinAmountLeftText } from './shared/Styles';

const QuoteSummaryHeading = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray400};
`;

const QuoteDate = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.gray500};
`;

const ProductImage = styled.img`
  display: block;
  max-width: 40px;

  border: 1px solid ${({ theme }) => theme.color.gray300};
  border-radius: 4px;
`;

const PartnerName = styled.strong`
  font-size: 16px;
`;

const MinOrderValueText = styled(MinAmountLeftText)`
  font-style: italic;
  color: ${({ theme }) => theme.color.gray400};
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px;
  gap: 8px;

  ${({ theme }) => css`
    background: ${theme.color.gray100};
    color: ${theme.color.gray400};
  `}

  ${materialSymbol({
    name: 'landscape',
    color: 'gray400',
    size: '26px',
    additionalStyling: css`
      background-color: ${({ theme }) => theme.color.gray200};
      border-radius: 4px;
      padding: 8px;
    `,
  })}
`;

const QuoteSummaryItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { quote, refreshQuote } = useContext(NewQuoteContext);

  // Event Handlers

  const handleDeleteButtonClick = (quoteItemId: string) => {
    setIsLoading(true);

    deleteQuoteItem(quote.id, quoteItemId)
      .then(refreshQuote)
      .catch((error) => console.error(`Unable to delete quote ${quote.id}`, error.message))
      .finally(() => setIsLoading(false));
  };

  // Render

  if (isEmpty(quote))
    return <NoResults>Select a partner to start adding products to your quote</NoResults>;

  if (isEmpty(quote.items)) return <NoResults>Select products</NoResults>;

  return (
    <Table>
      <Table.TBody>
        {quote.items.map((item) => {
          const { sku } =
            item.product.variants?.find((variant) => variant.id === item.variantId) ?? {};

          return (
            <Table.TR key={item.id}>
              <Table.TD>
                {item.product.images && (
                  <ProductImage
                    src={item.product.images[0].source}
                    alt={`Product image for ${item.product.title}`}
                  />
                )}
              </Table.TD>
              <Table.TD>{sku}</Table.TD>
              <Table.TD>{item.quantity}x</Table.TD>
              <Table.TD>{humanizeMoney(item.extendedPrice)}</Table.TD>
              <Table.TD>
                <DeleteItemButton
                  type="button"
                  onClick={() => handleDeleteButtonClick(item.id)}
                  disabled={isLoading}
                />
              </Table.TD>
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};

const QuoteSummary = () => {
  const [priceList, setPriceList] = useState({} as PriceList);
  const [amountLeftToMin, setAmountLeftToMin] = useState({
    amount: Infinity,
    currency: 'USD',
  } as Money);

  const { quote, quoteMinimumValue } = useContext(NewQuoteContext);

  const { addErrorAlert } = useAlertQueue();
  const history = useHistory();

  // Helpers

  const quoteAmount = quote.totalPrice?.amount ?? 0;
  const isQuoteMinimumValueMet = quoteMinimumValue.amount - quoteAmount <= 0;

  const shouldShowQuoteMinimumValue = !useRouteMatch({
    path: ['/quotes/new/partner'],
  });

  const shouldShowAddMoreProductsButton = !useRouteMatch({
    path: ['/quotes/new/partner', `/quotes/new/${quote.id}/products`],
  });

  // Side Effects

  useEffect(() => {
    if (quote.priceListId) {
      getBuyerPriceList(quote.priceListId)
        .then((response) => setPriceList(response.data))
        .catch((error) => {
          addErrorAlert('Unable to fetch Pricing', 'Please reload your browser.');
          console.error('Unable to fetch Pricing', error.message);
        });
    }
  }, [quote.priceListId, addErrorAlert]);

  useEffect(() => {
    const amount = quoteMinimumValue.amount - quoteAmount;

    setAmountLeftToMin({ amount, currency: quote.currency });
  }, [quote.currency, quoteMinimumValue, quoteAmount]);

  // Event Handlers

  const handleAddMoreProductsButtonClick = () => {
    history.push(`/quotes/new/${quote.id}/products`);
  };

  // Render

  return (
    <Card data-testid="quote-summary">
      <Flex column gap="16px">
        <Flex alignItemsCenter justifySpaceBetween>
          <QuoteSummaryHeading>Quote Summary</QuoteSummaryHeading>
          {quote.createdAt && <QuoteDate>{prettyDate(quote.createdAt)}</QuoteDate>}
        </Flex>

        <Flex column gap="16px">
          {quote.sellerName && <PartnerName>{quote.sellerName}</PartnerName>}

          <Grid rows="auto" gap="16px">
            <QuoteSummaryItems />

            <Divider />

            <Table>
              <Table.TBody>
                <Table.TR align="right">
                  <Table.TH>subtotal</Table.TH>
                  <Table.TD>{humanizeMoney(quote.subtotalPrice)}</Table.TD>
                </Table.TR>
                <Table.TR align="right">
                  <Table.TH>taxes</Table.TH>
                  <Table.TD>TBD</Table.TD>
                </Table.TR>
                <Table.TR align="right">
                  <Table.TH>shipping</Table.TH>
                  <Table.TD>TBD</Table.TD>
                </Table.TR>
                <Table.TR align="right" bold>
                  <Table.TH>total</Table.TH>
                  <Table.TD>{humanizeMoney(quote.totalPrice)}</Table.TD>
                </Table.TR>
              </Table.TBody>
            </Table>

            {shouldShowQuoteMinimumValue &&
              (isQuoteMinimumValueMet ? (
                <MinOrderValueText>
                  Min. Order Value {humanizeMoney(priceList.quoteMinimumValue)}
                </MinOrderValueText>
              ) : (
                <MinAmountLeftText>
                  {humanizeMoney(amountLeftToMin)} left to meet the Min. Order Value
                </MinAmountLeftText>
              ))}
          </Grid>

          {shouldShowAddMoreProductsButton && (
            <>
              <Flex.Item>
                <Divider />
              </Flex.Item>

              <Flex justifyCenter>
                <TertiaryButton
                  size="small"
                  $iconName="add"
                  onClick={handleAddMoreProductsButtonClick}
                >
                  Add more products
                </TertiaryButton>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default QuoteSummary;
