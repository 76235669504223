import Flex from '@react-css/flex';

import Card from 'storybook/stories/cells/Card';
import type { Address as AddressType } from 'types/general';

import { Address, AddressHeading, AddressLine, EditButton, EditDot, EditText } from './Styles';

interface BillingCardProps {
  address: AddressType;
  isEditable: boolean;
  onEditButtonClick: () => void;
}

const BillingCard = ({ address, isEditable, onEditButtonClick }: BillingCardProps) => {
  return (
    <Card>
      <Flex column gap="16px">
        <Flex justifyCenter>
          <AddressHeading>Billing</AddressHeading>
        </Flex>

        <Flex alignItemsCenter justifySpaceBetween gap="32px">
          <Address>
            <AddressLine>
              <strong>{address.name}</strong>
            </AddressLine>
            <AddressLine>{address.addressOne}</AddressLine>
            {address?.addressTwo && <AddressLine>{address.addressTwo}</AddressLine>}
            <AddressLine>
              {address.city}, {address.state}
            </AddressLine>
            <AddressLine>{address.zip}</AddressLine>
          </Address>

          {isEditable && (
            <EditButton onClick={onEditButtonClick} data-testid="edit-billing-button">
              <EditDot />
              <EditText>Edit</EditText>
            </EditButton>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default BillingCard;
