import Grid from '@react-css/grid';
import ProtectedRoute from 'containers/App/ProtectedRoute';
import BillingSettings from 'containers/SettingsPage/DropshippingTab/BillingSettings';
import IntegrationsSettings from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings';
import MerchandisingSettings from 'containers/SettingsPage/DropshippingTab/MerchandisingSettings';
import NetworkSettings from 'containers/SettingsPage/DropshippingTab/NetworkSettings';
import NotificationsSettings from 'containers/SettingsPage/DropshippingTab/NotificationsSettings';
import ReturnsSettings from 'containers/SettingsPage/DropshippingTab/ReturnsSettings';
import ServiceLevelsSettings from 'containers/SettingsPage/DropshippingTab/ServiceLevelsSettings';
import ShippingSettings from 'containers/SettingsPage/DropshippingTab/ShippingSettings';
import WebhooksSettings from 'containers/SettingsPage/DropshippingTab/WebhooksSettings';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { useAppSelector } from 'store';
import {
  selectIsBuyer,
  selectIsDropshipSetup,
  selectIsOrderPlatformShopify,
  selectIsSeller,
} from 'store/selectors/me/company';
import Card from 'storybook/stories/cells/Card';
import Menu from 'storybook/stories/cells/Menu';
import styled from 'styled-components';

const MenuWrapper = styled(Card)`
  padding: 24px;
  position: sticky;
  top: 24px;
`;

enum SectionSegments {
  Billing = 'billing',
  Shipping = 'shipping',
  Integrations = 'integrations',
  Webhooks = 'webhooks',
  Notifications = 'notifications',
  Network = 'network',
  Merchandising = 'merchandising',
  ServiceLevels = 'service-levels',
  Returns = 'returns',
}

const Dropshipping = () => {
  const { section } = useParams<{ section?: SectionSegments }>();

  const isBuyer = useAppSelector(selectIsBuyer);
  const isSeller = useAppSelector(selectIsSeller);
  const isOrderPlatformShopify = useAppSelector(selectIsOrderPlatformShopify);
  const isAmCompanySettingsEnabled = useFeatureFlagEnabled('am-company-settings');
  const isDropshipSetup = useAppSelector(selectIsDropshipSetup);

  const selectedSection =
    section && Object.values(SectionSegments).includes(section) ? section : SectionSegments.Billing;

  return (
    <Grid columns="240px 1fr" gap="24px">
      <Grid.Item>
        <MenuWrapper>
          <Menu>
            <Menu.Link
              $iconName="monetization_on"
              $isActive={selectedSection === SectionSegments.Billing}
              to="/settings/dropshipping/billing"
            >
              Billing
            </Menu.Link>

            <Menu.Link
              $iconName="local_shipping"
              $isActive={selectedSection === SectionSegments.Shipping}
              to="/settings/dropshipping/shipping"
            >
              Shipping
            </Menu.Link>

            <Menu.Link
              $iconName="sync_alt"
              $isActive={selectedSection === SectionSegments.Integrations}
              to="/settings/dropshipping/integrations"
            >
              Integrations
            </Menu.Link>

            <Menu.Link
              $iconName="webhook"
              $isActive={selectedSection === SectionSegments.Webhooks}
              to="/settings/dropshipping/webhooks"
            >
              Webhooks
            </Menu.Link>

            <Menu.Link
              $iconName="notifications"
              $isActive={selectedSection === SectionSegments.Notifications}
              to="/settings/dropshipping/notifications"
            >
              Notifications
            </Menu.Link>

            {isSeller && (
              <Menu.Link
                $iconName="language"
                $isActive={selectedSection === SectionSegments.Network}
                to="/settings/dropshipping/network"
              >
                Network
              </Menu.Link>
            )}

            {isAmCompanySettingsEnabled && (
              <Menu.Link
                $iconName="point_of_sale"
                $isActive={selectedSection === SectionSegments.Merchandising}
                to="/settings/dropshipping/merchandising"
              >
                Merchandising
              </Menu.Link>
            )}

            {isBuyer && (
              <Menu.Link
                $iconName="receipt_long"
                $isActive={selectedSection === SectionSegments.ServiceLevels}
                to="/settings/dropshipping/service-levels"
              >
                Service Levels
              </Menu.Link>
            )}

            {isOrderPlatformShopify && (
              <Menu.Link
                $iconName="assignment_return"
                $isActive={selectedSection === SectionSegments.Returns}
                to="/settings/dropshipping/returns"
              >
                Returns
              </Menu.Link>
            )}
          </Menu>
        </MenuWrapper>
      </Grid.Item>

      <Card>
        <Switch>
          <ProtectedRoute
            isAllowed={isDropshipSetup}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/billing"
            component={BillingSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/shipping"
            component={ShippingSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/integrations"
            component={IntegrationsSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/webhooks"
            component={WebhooksSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/notifications"
            component={NotificationsSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup && isSeller}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/network"
            component={NetworkSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup && !!isAmCompanySettingsEnabled}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/merchandising"
            component={MerchandisingSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup && isBuyer}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/service-levels"
            component={ServiceLevelsSettings}
          />

          <ProtectedRoute
            isAllowed={isDropshipSetup && isOrderPlatformShopify}
            redirectTo="/settings/preferences/account"
            path="/settings/dropshipping/returns"
            component={ReturnsSettings}
          />

          <Route path="*">
            <Redirect to="/settings/dropshipping/billing" />
          </Route>
        </Switch>
      </Card>
    </Grid>
  );
};

export default Dropshipping;
