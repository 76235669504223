import type { ChangelogEntry } from 'CHANGELOG';
import CHANGELOG from 'CHANGELOG';
import { differenceInSeconds, parseISO } from 'date-fns';
import useChangelogEntries from 'hooks/useChangelogEntries';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const WHATS_NEW_VIEWED_AT_STORAGE_KEY = 'whatsNewViewedAt';

interface UseWhatsNewEntriesOptions {
  changelog?: ChangelogEntry[];
  initialViewedAtDate?: string;
  /**
   * Use this option to shuffled "new" entries into the "rest" entries array
   * after the caller Component has finished unmounting.
   */
  shouldSetViewedAtOnUnmount?: boolean;
}

/**
 * This categorizes the changelog entries into two groups: new and old.
 */
const useWhatsNewEntries = ({
  changelog = CHANGELOG,
  shouldSetViewedAtOnUnmount,
  initialViewedAtDate = new Date().toISOString(),
}: UseWhatsNewEntriesOptions = {}) => {
  type ChangelogEntriesTuple = [new: ChangelogEntry[], old: ChangelogEntry[]];

  const changelogEntries = useChangelogEntries({ changelog });

  const [viewedAt, setViewedAt] = useLocalStorage<string>(
    WHATS_NEW_VIEWED_AT_STORAGE_KEY,
    initialViewedAtDate
  );

  useEffect(() => {
    return () => {
      if (shouldSetViewedAtOnUnmount) setViewedAt(new Date().toISOString());
    };
  }, [shouldSetViewedAtOnUnmount, setViewedAt]);

  const categorizedEntries: ChangelogEntriesTuple = useMemo(
    () =>
      changelogEntries.reduce(
        (accumulator: ChangelogEntriesTuple, entry) => {
          const [newEvents, restEvents] = accumulator;
          const distance = differenceInSeconds(parseISO(entry.timestamp), parseISO(viewedAt));

          if (distance > 0) {
            return [[...newEvents, entry], restEvents];
          }

          return [newEvents, [...restEvents, entry]];
        },
        [[], []] as ChangelogEntriesTuple
      ),
    [viewedAt, changelogEntries]
  );

  return categorizedEntries;
};

export default useWhatsNewEntries;
