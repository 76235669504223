import { Link } from 'react-router-dom';
import type { EventProps } from 'storybook/stories/organisms/Timeline';

export enum ChangelogAudienceType {
  AnyDropship,
  DropshipSeller,
  DropshipBuyer,
}

export enum ChangelogEntryType {
  NewFeature,
  Improvement,
  Fix,
  Update,
}

export interface ChangelogEntry extends Omit<EventProps, 'iconName'> {
  /**
   * The type of changelog entry
   */
  type: ChangelogEntryType;
  /**
   * The title of the changelog entry
   */
  title: string;
  /**
   * Limit the rendering based on user type. Omit to show to all users.
   */
  limitForAudience?: ChangelogAudienceType;
}

/**
 * Add new changelog entries here.
 */
const CHANGELOG: ChangelogEntry[] = [
  {
    type: ChangelogEntryType.Improvement,
    title: 'Clearer iconography for Order return status',
    timestamp: '2024-01-04',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        This was confusing some users, so we made the return status icons more clear. This
        improvement can be found on the <Link to="/orders">Orders</Link> page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Graphing support for Reporting & Insights',
    timestamp: '2024-01-05',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        Now, when creating a new Report to track, you can ask for a graph to be generated. Try it
        out on the <Link to="/reporting">Reporting</Link> page. This change also applied to Insights
        mode in our Chat feature.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Add Partner locations',
    timestamp: '2024-01-10',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        Now you can navigate to your <Link to="/partners">Partners</Link> page and click on a
        partner to see their locations.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Update,
    title: 'Partner Actions have moved',
    timestamp: '2024-01-22',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        We moved the Partner Actions to its own tab of the <Link to="/partners">Partners</Link>{' '}
        page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Partner Onboarding',
    timestamp: '2024-01-23',
    limitForAudience: ChangelogAudienceType.DropshipBuyer,
    children: (
      <>
        We standardized the look and feel of our Get Started page to help make it easier for your
        sellers to get up and running quickly.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Order and Product search speed',
    timestamp: '2024-01-24',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        The next time you try searching or filtering for an <Link to="/orders">Order</Link> or{' '}
        <Link to="/products">Product</Link>, you should notice a significant improvement in response
        times.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Import Price Lists as CSV',
    timestamp: '2024-01-25',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        You can now import your Price Lists as CSV files. Try it out on the{' '}
        <Link to="/prices">Price Lists</Link> page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Export Price Lists as CSV',
    timestamp: '2024-02-08',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        You now have more control over what products are included in your Price List CSV exports.
        Try it out on the <Link to="/prices">Price Lists</Link> page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Manually Post Invoices to Stripe',
    timestamp: '2024-02-14',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        Invoices created before activating Stripe for a partner can now be manually added to Stripe
        for payment.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Manually Mark Invoices as Paid',
    timestamp: '2024-02-15',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: <>Invoices not posted to Stripe can now be marked paid in-app.</>,
  },

  {
    type: ChangelogEntryType.Fix,
    title: 'Fixed a bug when searching for partners',
    timestamp: '2024-02-16',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        Some users were reporting that the <Link to="/partners">Partners</Link> page search bar was
        not working as expected. We fixed it.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Sellers integrated via EDI can now cancel orders in-app',
    timestamp: '2024-02-22',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        Sellers integrated via EDI were previously required to handle order cancelation via the 855
        PO Acknowledgement transaction set. Cancelations can now be performed directly on the order
        page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Resend pending Partner invitations',
    timestamp: '2024-02-23',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        You may now send Partnership invitation reminders from the the{' '}
        <Link to="/partners">Partners</Link> page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'Improved Reporting page design',
    timestamp: '2024-02-28',
    children: (
      <>
        The <Link to="/reporting">Reporting</Link> page has undergone a design overhaul.
      </>
    ),
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'More currency options available',
    timestamp: '2024-03-01',
    children: <>We now support 116 different currencies 🌏</>,
  },

  {
    type: ChangelogEntryType.Improvement,
    title: 'WooCommerce Dimensions and Weight Syncing',
    timestamp: '2024-04-10',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        We now support syncing product dimensions and weight from WooCommerce into Modern Dropship.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Filter Orders by Cancelled status',
    timestamp: '2024-06-04',
    limitForAudience: ChangelogAudienceType.AnyDropship,
    children: (
      <>
        You may now filters orders by Cancelled status from the the <Link to="/orders">Orders</Link>{' '}
        page.
      </>
    ),
  },

  {
    type: ChangelogEntryType.NewFeature,
    title: 'Partner Network Opt-In',
    timestamp: '2024-06-26',
    limitForAudience: ChangelogAudienceType.DropshipSeller,
    children: (
      <>
        Interested in finding new partners to work with? You can opt-in via the company{' '}
        <Link to="/settings/dropshipping/network">network settings</Link>.
      </>
    ),
  },
];

export default CHANGELOG;
