import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { isEmpty, noop } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Spinner from 'components/Common/Spinner';
import StepNavigationCard from 'containers/Quotes/shared/StepNavigationCard';
import useAlertQueue from 'hooks/useAlertQueue';
import Card from 'storybook/stories/cells/Card';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import Table from 'storybook/stories/cells/Table';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import Stepper, { Step } from 'storybook/stories/organisms/Stepper';
import type { Response } from 'types/api';
import type { Partner } from 'types/models/partner';
import { getPartners } from 'utils/api/partners';
import { createQuote } from 'utils/api/quotes';

import { useForm } from 'react-hook-form';
import QuoteLayout, { QuoteLayoutAside, QuoteLayoutMain } from '../QuoteLayout';
import QuoteSummary from '../QuoteSummary';
import NewQuoteContext from './NewQuoteContext';

type PartnerSearchFormValues = {
  sellerName: string;
};

const PartnerSelectionHeading = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.color.gray700};
`;

const RadioButtonWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const PartnerSelection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [partners, setPartners] = useState([] as Partner[]);
  const [selectedPartner, setSelectedPartner] = useState({} as Partner);
  const [searchParams, setSearchParams] = useState({} as PartnerSearchFormValues);

  const history = useHistory();
  const { setQuote } = useContext(NewQuoteContext);
  const { addErrorAlert } = useAlertQueue();

  const { handleSubmit, register } = useForm<PartnerSearchFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      sellerName: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    setCurrentPage(0);
    setSearchParams(data);
  });

  // Side Effects

  useEffect(() => {
    setIsLoading(true);

    getPartners({ page: currentPage, limit: 20, ...searchParams })
      .then((response: Response<Partner[]>) => {
        setPartners(response.data);
      })
      .catch((error) => {
        addErrorAlert('Unable to fetch Partners', 'Please reload your browser.');
        console.error('Unable to fetch Partners', error.message);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, searchParams, addErrorAlert]);

  // Event Handlers

  const handleBackButtonClick = () => noop;

  const handleNextButtonClick = () => {
    setIsLoading(true);

    createQuote({ partnerId: selectedPartner._id })
      .then((response) => {
        const quote = response.data;
        setQuote(response.data);
        history.push(`/quotes/new/${quote.id}/products`);
      })
      .catch((error) => {
        addErrorAlert('Unable to create Quote', 'Please try again.');
        console.error('Unable to create Quote', error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleTableRowClick = (partner: Partner) => {
    setSelectedPartner(partner);
  };

  // Render

  return (
    <QuoteLayout title="Request Quote" data-testid="partner-selection">
      <QuoteLayoutMain>
        <Stepper>
          <Step number={1} status="active">
            Select Partner
          </Step>

          <Step number={2}>Select Products</Step>

          <Step number={3}>Shipping &amp; Billing</Step>

          <Step number={4}>Review &amp; Submit</Step>
        </Stepper>

        <Card>
          <Grid rows="auto" gap="32px">
            <Flex column gap="16px">
              <Flex justifySpaceBetween alignItemsCenter>
                <Flex.Item grow={1}>
                  <PartnerSelectionHeading>Select a Partner</PartnerSelectionHeading>
                </Flex.Item>

                <Flex.Item grow={2}>
                  <SearchForm onSubmit={onSubmit}>
                    <SearchFormInput
                      {...register('sellerName')}
                      placeholder="Search for a Partner"
                      aria-label="Search for a Partner"
                    />

                    <SearchFormButton disabled={isLoading} />
                  </SearchForm>
                </Flex.Item>
              </Flex>

              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <Table variant="data" data-testid="partner-selection-table">
                    <Table.THead>
                      <Table.TR>
                        <Table.TH aria-label="Selected Indicator" width="25px" />
                        <Table.TH>Partner</Table.TH>
                        <Table.TH>Syncing</Table.TH>
                        <Table.TH>Billing</Table.TH>
                        <Table.TH>Price List</Table.TH>
                      </Table.TR>
                    </Table.THead>

                    <Table.TBody>
                      {partners.map((partner) => {
                        const isPartnerSelected = partner._id === selectedPartner._id;
                        const disabled = partner.priceListName === '';

                        const partnerName = partner.sellerName || partner.sellerCompanyId;

                        return (
                          <Table.TR
                            key={partner._id}
                            onClick={() => (disabled ? noop : handleTableRowClick(partner))}
                            disabled={disabled}
                          >
                            <Table.TD>
                              <RadioButtonWrapper>
                                <RadioButton checked={isPartnerSelected} readOnly />
                              </RadioButtonWrapper>
                            </Table.TD>
                            <Table.TD>{partnerName}</Table.TD>
                            <Table.TD>{partner.active ? 'Yes' : 'No'}</Table.TD>
                            <Table.TD>{partner.billing ? 'Yes' : 'No'}</Table.TD>
                            <Table.TD data-testid="price-list-name">
                              {disabled ? (
                                <>
                                  No price list assigned.{' '}
                                  <a href={`mailto:${partner.email}`}>Contact your partner</a> to
                                  set up wholesale pricing.
                                </>
                              ) : (
                                partner.priceListName
                              )}
                            </Table.TD>
                          </Table.TR>
                        );
                      })}
                    </Table.TBody>
                  </Table>

                  <Flex justifyEnd alignItemsCenter gap="16px">
                    <PageList currentPage={currentPage + 1} />

                    <PageNavigation
                      hasPrevious={currentPage !== 0}
                      hasNext={partners.length === 0}
                      onPreviousClick={() => setCurrentPage((page) => page - 1)}
                      onNextClick={() => setCurrentPage((page) => page + 1)}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Grid>
        </Card>
      </QuoteLayoutMain>

      <QuoteLayoutAside>
        <StepNavigationCard
          disableBackButton
          disableNextButton={isEmpty(selectedPartner)}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
        />

        <QuoteSummary />
      </QuoteLayoutAside>
    </QuoteLayout>
  );
};

export default PartnerSelection;
