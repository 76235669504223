import type { MouseEventHandler } from 'react';
import styled from 'styled-components';

import Card from 'storybook/stories/cells/Card';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import { HEIGHT } from 'storybook/stories/organisms/Stepper';

interface StepNavigationCardProps {
  onBackButtonClick: MouseEventHandler<HTMLButtonElement>;
  onNextButtonClick: MouseEventHandler<HTMLButtonElement>;
  disableBackButton: boolean;
  disableNextButton: boolean;
  backButtonText?: string;
  nextButtonText?: string;
}

const Wrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEIGHT};
  gap: 8px;
`;

/**
 * Compliments `StepNavigation` in style and height
 */
const StepNavigationCard = ({
  disableBackButton,
  disableNextButton,
  onBackButtonClick,
  onNextButtonClick,
  backButtonText = 'Back',
  nextButtonText = 'Next',
}: StepNavigationCardProps) => {
  return (
    <Wrapper>
      <SecondaryButton
        disabled={disableBackButton}
        onClick={onBackButtonClick}
        data-testid="back-button"
        $iconName="navigate_before"
      >
        {backButtonText}
      </SecondaryButton>

      <PrimaryButton
        disabled={disableNextButton}
        onClick={onNextButtonClick}
        data-testid="next-button"
        $iconName="navigate_next"
        $iconAfter
      >
        {nextButtonText}
      </PrimaryButton>
    </Wrapper>
  );
};

export default StepNavigationCard;
