/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Spinner from 'components/Common/Spinner';
import { updateUserCompany } from 'store/slices/settings/company';

import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import Divider from 'storybook/stories/molecules/Divider';
import Label from 'storybook/stories/molecules/Label';
import { getSellerNetworkOptions } from 'utils/api/companies';

import {
  SettingsFooter,
  SettingsMain,
  SettingsPageHeader,
} from 'containers/SettingsPage/SettingsLayout';
import useAlertQueue from 'hooks/useAlertQueue';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import type { SelectOption } from './SellerNetworkCategoriesSelect';
import SellerNetworkCategoriesSelect from './SellerNetworkCategoriesSelect';

type NetworkOptionsFormData = {
  isOptedIn: boolean;
  supplyModels?: string[];
  categories?: SelectOption[];
};

const DescriptionText = styled.p`
  font-size: 14px;
  margin-top: 4px;

  ${({ theme }) => css`
    color: ${theme.color.gray600};
  `};
`;

const FormSeparator = styled(Divider)`
  margin: 32px 0px;

  ${({ theme }) => css`
    color: ${theme.color.gray600};
  `};
`;

const NetworkSettings = () => {
  const defaultSupplyModel = 'Dropship';
  const dispatch = useAppDispatch();
  const company = useSelector(selectCompany);
  const alertQueue = useAlertQueue();
  const [isOptedIn, setIsOptedIn] = useState(company?.sellerNetworkData?.isOptedIn ?? false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formId = 'network-settings-form';

  const { data: networkOptionsResponse } = useQuery({
    queryKey: ['network-options'],
    queryFn: () => getSellerNetworkOptions(),
  });

  const defaultCategories = networkOptionsResponse?.data?.productCategories;
  const defaultSupplyModels = networkOptionsResponse?.data?.supplyModels;
  const showNetworkOptionsForm = isOptedIn && defaultSupplyModels && defaultCategories;

  const toggleIsOptedIn = () => {
    setIsOptedIn((prev) => !prev);
  };

  const apiToSelectOption = (categories: string[]) => {
    return categories.map((category) => {
      return { value: category, label: category };
    });
  };

  const { handleSubmit, register, formState, reset, control } = useForm({
    defaultValues: {
      isOptedIn: company?.sellerNetworkData.isOptedIn ?? false,
      supplyModels: company?.sellerNetworkData.supplyModels ?? [defaultSupplyModel],
      categories: apiToSelectOption(company?.sellerNetworkData.categories ?? []),
    },
  });

  const { isDirty } = formState;

  const onFormSubmit = (data: NetworkOptionsFormData) => {
    setIsSubmitting(true);
    const sellerNetworkUpdate = data.isOptedIn
      ? {
          sellerNetworkData: {
            isOptedIn: data.isOptedIn,
            supplyModels: [defaultSupplyModel],
            categories: data.categories?.map((category) => {
              return category.value;
            }),
          },
        }
      : {
          sellerNetworkData: {
            isOptedIn,
          },
        };

    dispatch(updateUserCompany(sellerNetworkUpdate)).then((action: { error: any }) => {
      setIsSubmitting(false);
      if (action.error) {
        alertQueue.addErrorAlert('Something went wrong', action.error.message);
        return;
      }
      if (data.isOptedIn && !company?.sellerNetworkData.isOptedIn) {
        alertQueue.addSuccessAlert(
          'Success',
          'Welcome to the Modern Dropship network, we will contact you shortly.'
        );
      } else {
        alertQueue.addSuccessAlert('Success', 'Network preferences updated successfully.');
      }
    });
    reset(
      { ...data },
      {
        keepDefaultValues: false,
        keepValues: false,
        keepDirty: false,
      }
    );
  };

  return (
    <>
      <SettingsMain>
        <SettingsPageHeader title="Network" />
        <h4>Modern Dropship Trade Network</h4>
        <DescriptionText>
          By opting in, Modern Dropship will propose your brand to retailers if they express
          interest in your product category. Additionally, we will notify you once a retailer has
          confirmed their interest in your brand. Your brand will not be visible to retailers
          directly.
        </DescriptionText>
        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <div className="custom-control custom-switch">
            <input
              className="custom-control-input"
              type="checkbox"
              id="isOptedIn"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('isOptedIn')}
              data-testid="isOptedIn"
              onChange={toggleIsOptedIn}
            />
            <label className="custom-control-label" htmlFor="isOptedIn">
              Contact me for new opportunities
            </label>
          </div>
          {showNetworkOptionsForm && (
            <>
              <FormSeparator />
              <Label htmlFor="categories">SELECT CATEGORIES YOU ARE INTERESTED IN</Label>
              <Controller
                name="categories"
                data-testid="categories"
                control={control}
                render={({ field }) => (
                  <SellerNetworkCategoriesSelect
                    id="categories"
                    options={apiToSelectOption(defaultCategories)}
                    defaultValues={apiToSelectOption(company?.sellerNetworkData.categories ?? [])}
                    onChange={field.onChange}
                  />
                )}
              />
            </>
          )}
        </form>
      </SettingsMain>

      <SettingsFooter className="justify-content-end">
        <PrimaryButton type="submit" disabled={!isDirty || isSubmitting} form={formId}>
          {isSubmitting ? <Spinner color="white" className="mx-2" small /> : 'Save'}
        </PrimaryButton>
      </SettingsFooter>
    </>
  );
};

export default NetworkSettings;
