import { isEmpty } from 'lodash';
import { useState } from 'react';
import Modal from 'storybook/stories/cells/Modal';
import styled, { css } from 'styled-components';

import { useAppSelector } from 'store';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import RadioButton from 'storybook/stories/molecules/RadioButton';
import type { Address as AddressType } from 'types/general';
import { capitalizeFirstChar } from 'utils/strings';

interface AddressSelectModalProps {
  addressType: 'shipping' | 'billing' | '';
  handleClose: (address: AddressType) => void;
}

interface SelectListItemProps {
  active?: boolean;
}

const ModalHeader = styled(Modal.Header)`
  align-items: center;

  // Close button
  button {
    font-size: 32px;
  }
`;

const ModalTitle = styled(Modal.Title)`
  margin-bottom: 0;
  font-size: 22px;
`;

const SelectList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SelectListItem = styled.button<SelectListItemProps>`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  gap: 24px;
  padding: 8px 24px;
  border-radius: 8px;
  border: 1px solid transparent;

  &:hover {
    background: ${({ theme }) => theme.color.gray100};
  }

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.color.gray100};
      border-color: ${({ theme }) => theme.color.blue500};
    `};
`;

const Address = styled.address`
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;

  strong {
    font-size: 18px;
  }
`;

const AddressLine = styled.span`
  display: block;
`;

const AddressSelectModal = ({ addressType, handleClose }: AddressSelectModalProps) => {
  const [selectedAddress, setSelectedAddress] = useState({} as AddressType);

  const me = useAppSelector((state) => state.me);

  // Helpers

  const isModalOpen = ['shipping', 'billing'].includes(addressType);
  const addresses = me.company?.locations ?? [];

  // Event Handlers

  const handleUpdateButtonClick = () => {
    handleClose(selectedAddress);
  };

  // Render

  return (
    <Modal.Root
      show={isModalOpen}
      onHide={() => handleClose(selectedAddress)}
      data-testid="address-select-modal"
    >
      <ModalHeader closeButton>
        <ModalTitle>Edit your {capitalizeFirstChar(addressType)} Address</ModalTitle>
      </ModalHeader>

      <Modal.Body>
        <SelectList>
          {addresses.map((address) => (
            <SelectListItem
              key={address._id}
              onClick={() => setSelectedAddress(address)}
              active={address._id === selectedAddress._id}
              data-testid="address-select-button"
            >
              <RadioButton checked={address._id === selectedAddress._id} readOnly />

              <Address>
                <AddressLine>
                  <strong>{address.name}</strong>
                </AddressLine>
                <AddressLine>{address.addressOne}</AddressLine>
                {!isEmpty(address.addressTwo) && <AddressLine>{address.addressTwo}</AddressLine>}
                <AddressLine>
                  {address.city}, {address.state}
                </AddressLine>
                <AddressLine>{address.zip}</AddressLine>
              </Address>
            </SelectListItem>
          ))}
        </SelectList>
      </Modal.Body>

      <Modal.Footer>
        <SecondaryButton onClick={() => handleClose({} as AddressType)}>Cancel</SecondaryButton>

        <PrimaryButton
          onClick={handleUpdateButtonClick}
          data-testid="update-address-button"
          disabled={isEmpty(selectedAddress)}
        >
          Update Address
        </PrimaryButton>
      </Modal.Footer>
    </Modal.Root>
  );
};

export default AddressSelectModal;
