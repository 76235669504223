import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { Money } from 'types/general';
import type { Quote } from 'types/models/quote';

export type NewQuoteContextValue = {
  quote: Quote;
  setQuote: Dispatch<SetStateAction<Quote>>;
  refreshQuote: () => Promise<void>;
  quoteMinimumValue: Money;
  setQuoteMinimumValue: Dispatch<SetStateAction<Money>>;
};

const NewQuoteContext = createContext({} as NewQuoteContextValue);

export default NewQuoteContext;
