import { forwardRef } from 'react';
import typography from 'storybook/mixins/typography';
import Input from 'storybook/stories/molecules/Input';
import styled from 'styled-components';

const StyledInput = styled(Input)``;

type WrapperProps = Pick<UnitInputProps, 'disabled' | 'isFullWidth'>;

const Wrapper = styled.div<WrapperProps>`
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  position: relative;
  overflow: hidden;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  ${StyledInput} {
    flex-grow: 1;
  }
`;

const Unit = styled.span`
  ${typography('Body/Caption')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
  padding-right: 16px;
  position: absolute;
  inset: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface UnitInputProps extends React.ComponentProps<typeof Input> {
  unit: string;
}

/**
 * Extends an `Input` component's styling and wraps it to provide iconography. Props
 * passed to `UnitInput` will be curried to the underlying `Input` component.
 */
const UnitInput = forwardRef<HTMLInputElement, UnitInputProps>(
  ({ disabled, isFullWidth, unit, ...inputProps }: UnitInputProps, ref) => (
    <Wrapper disabled={disabled} isFullWidth={isFullWidth}>
      <StyledInput ref={ref} disabled={disabled} isFullWidth={isFullWidth} {...inputProps} />
      <Unit>{unit}</Unit>
    </Wrapper>
  )
);

export default UnitInput;
