import PostHog from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { selectUser } from 'store/selectors/me/user';
import type { Company } from 'types/models/company';
import type { User } from 'types/models/user';
import config from 'utils/config';

if (config.postHog.key && config.postHog.host)
  PostHog.init(config.postHog.key, { api_host: config.postHog.host });

export const getUserProperties = (user: User, company: Company | null) => {
  const [primaryEmail] = user.emails || [];

  const properties: Record<string, string | boolean> = {
    email: primaryEmail?.address ?? '',
    firstName: user.firstName,
    lastName: user.lastName,
    companyId: user.companyId,
    companyObjectId: user.companyObjectId,
    superUser: user.superUser,
  };

  if (company) {
    properties.companyCommerceType = company.commerceType;
    properties.companyProductPlatform = company.productPlatform;
  }

  return properties;
};

const useInitPostHog = () => {
  const posthog = usePostHog();

  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);

  useEffect(() => {
    // We're not ready to run if
    // - our keys aren't set
    // - the app is still loading
    // - we don't have a posthog instance
    if (!config.postHog.key || !config.postHog.host) return;
    if (!user) return;
    if (!posthog) return;

    // We only want to identify we have a user & they're not anonymous
    if (user.flags?.stayAnonymous !== true) {
      posthog.identify(user._id, getUserProperties(user, company));
    }

    // If the user is tied to a company, group by company
    if (company) {
      posthog.group('company', company._id, {
        legacyCompanyId: company.legacyCompanyId,
        commerceType: company.commerceType,
        productPlatform: company.productPlatform,
        orderPlatform: company.orderPlatform,
      });
    }
  }, [company, posthog, user]);
};

export default useInitPostHog;
