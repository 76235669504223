import { map } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Modal from 'storybook/stories/cells/Modal';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import PreviewCreateActionEmail from 'containers/PartnerPage/Actions/Common/PreviewCreateActionEmail';
import SubmitFormButton from 'containers/PartnerPage/Actions/Common/SubmitFormButton';
import { TERMS_AND_CONDITIONS } from 'containers/PartnerPage/Actions/Kinds';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import styled from 'styled-components';
import type { PartnerAction, TermsTemplateMapping } from 'types/models/partner-action';
import { CURRENCIES } from 'utils/currencies';

export const HiddenInput = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 1rem;
`;

export const OptionSelector = styled.label<{ isDisabled?: boolean; isSelected?: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.6rem;
  font-size: 0.9375rem;
  border: 1px solid #d2ddec;
  border-radius: 4px;
  margin-bottom: 0;
  color: ${({ isDisabled }) => (isDisabled ? '#59759B' : '#12263f')};
  background-color: ${({ isSelected }) => (isSelected ? '#E8F0FC' : 'white')};
  opacity: ${({ isDisabled }) => (isDisabled ? '.8' : '1')};
  transition: background-color 0.15s ease-in-out;

  :hover {
    background-color: ${({ isDisabled }) => (isDisabled ? 'white' : '#e8f0fc')};
  }

  & label {
    cursor: pointer;
  }
`;

interface CreateTermsAndConditionsValueModalProps {
  showModal: boolean;
  onHide: () => void;
  onBackClick: () => void;
  onNextClick: (data: any, updatedFiledBytes: number[]) => void;
  isActionTemplate: boolean;
  mappingData: TermsTemplateMapping;
  bytes: number[];
  action: Partial<PartnerAction>;
  loading: boolean;
  success: boolean;
}

const EnterValuesModal = ({
  showModal,
  onHide,
  onBackClick,
  onNextClick,
  isActionTemplate,
  mappingData,
  bytes,
  action,
  loading,
  success,
}: CreateTermsAndConditionsValueModalProps) => {
  const isWholesaleEnabled = useFeatureFlagEnabled('wholesale');

  const [vendorName, setVendorName] = useState('');
  const [dropshipMargin, setDropshipMargin] = useState(0);
  const [wholesaleMargin, setWholesaleMargin] = useState<number | null>(null);
  const [baseCurrency, setBaseCurrency] = useState('CAD');

  // Update the PDF with values entered by the user
  const updatePDFFields = (doc: PDFDocument) => {
    const form = doc.getForm();

    // Set Vendor Name on PDF
    if (mappingData?.sellerCompanyName != null) {
      form.getTextField(mappingData.sellerCompanyName).setText(vendorName.toString());
    }

    // Set Dropship Margin on PDF
    form.getTextField(mappingData?.dropshipMargin).setText(dropshipMargin.toString());

    // Set Wholesale Margin on PDF
    if (mappingData?.wholesaleMargin != null) {
      form.getTextField(mappingData.wholesaleMargin).setText(wholesaleMargin?.toString());
    }

    // Set Base Currency (Purchase Currency) on PDF
    if (mappingData?.baseCurrency != null) {
      form.getTextField(mappingData.baseCurrency).setText(baseCurrency);
    }
  };

  const onSubmit = async () => {
    const uint8Bytes = Uint8Array.from(bytes);
    const parsedPDF = await PDFDocument.load(uint8Bytes, {
      ignoreEncryption: true,
    });

    updatePDFFields(parsedPDF);

    const savedBytes = Array.from(await parsedPDF.save());

    // Convert whole numbers to percent values
    const formattedDropshipMargin = parseFloat((dropshipMargin / 100).toFixed(2));
    const formattedWholesaleMargin = wholesaleMargin
      ? parseFloat((wholesaleMargin / 100).toFixed(2))
      : null;

    const formattedData = {
      sellerCompanyName: vendorName,
      dropshipMargin: formattedDropshipMargin,
      wholesaleMargin: formattedWholesaleMargin,
      baseCurrency,
      retailCurrency: baseCurrency, // Default RetailCurrency to BaseCurrency
    };

    onNextClick(formattedData, savedBytes);
  };

  useEffect(() => {
    if (showModal && bytes?.length > 0) {
      const loadValuesFromPDF = async (pdfBytes: number[]) => {
        const uint8Bytes = Uint8Array.from(pdfBytes);
        const parsedPDF = await PDFDocument.load(uint8Bytes, {
          ignoreEncryption: true,
        });
        const form = parsedPDF.getForm();

        // Get Vendor Name
        if (mappingData?.sellerCompanyName != null) {
          const vendorNameFromPDF =
            form.getTextField(mappingData?.sellerCompanyName).getText() ?? '';
          setVendorName(vendorNameFromPDF);
        }

        // Get Dropship Margin
        if (mappingData?.dropshipMargin != null) {
          const dropshipMarginFromPDF =
            form.getTextField(mappingData?.dropshipMargin).getText() ?? '';
          setDropshipMargin(parseFloat(dropshipMarginFromPDF));
        }

        // Get Wholesale Margin
        if (mappingData?.wholesaleMargin != null) {
          const wholesaleMarginFromPDF =
            form.getTextField(mappingData?.wholesaleMargin).getText() ?? '';
          setWholesaleMargin(parseFloat(wholesaleMarginFromPDF));
        }

        // Get Base Currency
        if (mappingData?.baseCurrency != null) {
          const baseCurrencyFromPDF = form.getTextField(mappingData.baseCurrency).getText();
          setBaseCurrency(baseCurrencyFromPDF ?? 'CAD');
        }
      };

      loadValuesFromPDF(bytes);
    }
  }, [bytes, showModal, mappingData]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vendorName: '',
      dropshipMargin: null,
      wholesaleMargin: null,
      purchaseCurrency: 'CAD',
    },
  });

  return (
    <Modal.Root
      show={showModal}
      onHide={onHide}
      animation={false}
      aria-labelledby="action-terms-and-conditions-general-modal"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate
            ? 'Create Terms and Conditions Action Template'
            : 'Create Terms and Conditions Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <h4 className="mb-4">Enter Values</h4>
            <p className="text-muted">
              Enter the values for each field that will apply to the vendor&apos;s price list
            </p>

            <Form.Group controlId="vendorName">
              <Form.Label>Vendor Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="vendorName"
                placeholder=""
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
              />
              {errors.vendorName && (
                <Form.Control.Feedback type="invalid">
                  {errors.vendorName.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="dropshipMargin">
              <Form.Label>Dropship Margin</Form.Label>
              <div className="input-group">
                <Form.Control
                  required
                  type="number"
                  name="dropshipMargin"
                  placeholder="--"
                  value={dropshipMargin}
                  onChange={(e) => setDropshipMargin(parseFloat(e.target.value))}
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
                {errors.dropshipMargin && (
                  <Form.Control.Feedback type="invalid">
                    {errors.dropshipMargin.message}
                  </Form.Control.Feedback>
                )}
              </div>
            </Form.Group>

            {isWholesaleEnabled && (
              <Form.Group controlId="wholesaleMargin">
                <Form.Label>Wholesale Margin</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="number"
                    name="wholesaleMargin"
                    placeholder="--"
                    value={wholesaleMargin ?? undefined}
                    onChange={(e) => setWholesaleMargin(parseFloat(e.target.value))}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                  {errors.wholesaleMargin && (
                    <Form.Control.Feedback type="invalid">
                      {errors.wholesaleMargin.message}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
            )}

            <Form.Group controlId="purchaseCurrency">
              <Form.Label>Purchase Currency</Form.Label>

              <OptionsContainer>
                {map(CURRENCIES, (currency) => {
                  return (
                    <OptionSelector key={currency.code} isSelected={baseCurrency === currency.code}>
                      <HiddenInput
                        id={`setPurchaseCurrencyTo${currency.code}Button`}
                        data-testid="base-currency"
                        type="radio"
                        name="selectPurchaseCurrency"
                        checked={currency.code === baseCurrency}
                        onChange={() => setBaseCurrency(currency.code)}
                      />
                      <label
                        htmlFor={`setPurchaseCurrencyTo${currency.code}Button`}
                        className="mb-0"
                      >
                        {CURRENCIES[currency.code].symbol} - {currency.code}
                      </label>
                    </OptionSelector>
                  );
                })}
              </OptionsContainer>

              <p>The currency you want to pay the partner with.</p>
            </Form.Group>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={action.cardTitle}
              text={action.cardText}
              kind={TERMS_AND_CONDITIONS}
              required={action.required}
              optionalDoc={false}
            />
            <PreviewCreateActionEmail
              kind={TERMS_AND_CONDITIONS}
              emailSubject={action.emailSubject}
              emailMessage={action.emailMessage}
              signers=""
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <SubmitFormButton
                id="create-action-form-btn"
                label={isActionTemplate ? 'Save Action Template' : 'Create Action'}
                successfulLabel="Created"
                loading={loading}
                success={success}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default EnterValuesModal;
