import Flex from '@react-css/flex';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDocumentTitle } from 'usehooks-ts';

import DashboardPage from 'components/Dashboard/DashboardPage';
import ActivateProductsCard from 'containers/GetStarted/ActivateProductsCard';
import AddPaymentMethodCard from 'containers/GetStarted/AddPaymentMethodCard';
import AddShippingMethodsCard from 'containers/GetStarted/AddShippingMethodsCard';
import AssignedActions from 'containers/GetStarted/AssignedActions';
import ConfigureEDISettingsCard from 'containers/GetStarted/ConfigureEDISettingsCard';
import ConnectBigCommerceCard from 'containers/GetStarted/ConnectBigCommerceCard';
import ConnectEasyPostCard from 'containers/GetStarted/ConnectEasyPostCard';
import ConnectExternalSFTPCard from 'containers/GetStarted/ConnectExternalSFTPCard';
import ConnectInternalSFTPCard from 'containers/GetStarted/ConnectInternalSFTPCard';
import ConnectMagento2Card from 'containers/GetStarted/ConnectMagento2Card';
import ConnectShopifyCard from 'containers/GetStarted/ConnectShopifyCard';
import ConnectStripeCard from 'containers/GetStarted/ConnectStripeCard';
import ConnectWooCommerceCard from 'containers/GetStarted/ConnectWooCommerceCard';
import CreatePriceListCard from 'containers/GetStarted/CreatePriceListCard';
import EnableSSOCard from 'containers/GetStarted/EnableSSOCard';
import GenerateCSVMappingsCard from 'containers/GetStarted/GenerateCSVMappingsCard';
import InvitePartnersCard from 'containers/GetStarted/InvitePartnersCard';
import InviteTeamCard from 'containers/GetStarted/InviteTeamCard';
import LoadingCard from 'containers/GetStarted/LoadingCard';
import AddAddressCard from 'containers/GetStarted/Locations/AddAddressCard';
import AddBillingAddressCard from 'containers/GetStarted/Locations/AddBillingAddressCard';
import TestOrderCard from 'containers/GetStarted/TestOrderCard';
import UploadSFTPProductFileCard from 'containers/GetStarted/UploadSFTPProductFileCard';
import ViewEDISpecsCard from 'containers/GetStarted/ViewEDISpecsCard';
import useFloatingChatPrompt from 'hooks/useFloatingChatPrompt';
import { useEffect } from 'react';
import type { RootState } from 'store';
import { selectMe } from 'store/selectors/me';
import { isDone, SKIPPED, useFetchGetStarted } from 'store/slices/getStarted';
import { ACCOUNT_TYPE_SELLER } from 'utils/constants';
import { getPlatformUrl, getShopifyStore } from 'utils/platform';

const GetStartedContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 4.5rem 0;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem 0;
  & hr {
    margin: 0;
    flex-grow: 1;
  }
  & h4 {
    margin: 0 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: #59759b;
  }
`;

export const GetStarted = () => {
  useDocumentTitle('Get Started');

  const { tryPrompting } = useFloatingChatPrompt();

  useEffect(() => {
    tryPrompting();
  }, [tryPrompting]);

  const { company, user } = useSelector(selectMe);
  const { commerceType, syncing } = company || {};

  const {
    connectShopify,
    connectWooCommerce,
    connectBigCommerce,
    connectMagento2,
    connectStripe,
    connectEasyPost,
    addPaymentMethod,
    addBillingAddress,
    addShippingAddress,
    addReturnsAddress,
    shippingMethods,
    activeProducts,
    createdPriceList,
    createSFTPCreds,
    createExternalSFTPCredentials,
    uploadSFTPFile,
    createCSVMappings,
    invitePartners,
    inviteTeam,
    enableSSO,
    testOrder,
    actionsAssigned,
    viewEDISpecifications,
    configureEDISettings,
    requiredCount,
    doneCount,
  } = useSelector((state: RootState) => state.getStarted);

  const { loading } = useFetchGetStarted();

  // flags for which cards to show
  const isSeller = commerceType === ACCOUNT_TYPE_SELLER;

  const connectPlatformRequired =
    connectShopify.required || connectWooCommerce.required || connectBigCommerce.required;
  const connectPlatformDone =
    isDone(connectShopify.status) ||
    isDone(connectWooCommerce.status) ||
    isDone(connectBigCommerce.status) ||
    isDone(connectMagento2.status);
  const billingDetailsRequired = addPaymentMethod.required && addBillingAddress.required;
  const billingDetailsDone = isDone(addPaymentMethod.status) && isDone(addBillingAddress.status);

  return (
    <GetStartedContainer>
      <Flex column alignItemsCenter justifyCenter>
        <strong className="mb-4 text-uppercase text-muted">
          {doneCount} of {requiredCount} Steps Completed
        </strong>
        <h1 className="mb-3" id="lets-get-started-title">
          Let&apos;s get you started
        </h1>
        <p className="mb-5 text-muted">
          Complete the following set up tasks to start doing business through Modern Dropship.
        </p>
      </Flex>
      <SectionHeader>
        <hr />
        <h4>REQUIRED</h4>
        <hr />
      </SectionHeader>
      {(!company ?? loading) && (
        <>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </>
      )}

      <Flex column gap="16px">
        {connectShopify?.required && (
          <ConnectShopifyCard
            done={isDone(connectShopify.status)}
            store={getShopifyStore(company)}
          />
        )}

        {connectWooCommerce?.required && (
          <ConnectWooCommerceCard
            storeUrl={getPlatformUrl(company, 'woocommerce')}
            done={isDone(connectWooCommerce.status)}
          />
        )}

        {connectBigCommerce?.required && (
          <ConnectBigCommerceCard
            storeUrl={getPlatformUrl(company, 'bigcommerce')}
            done={isDone(connectBigCommerce.status)}
          />
        )}

        {connectMagento2?.required && (
          <ConnectMagento2Card
            storeUrl={getPlatformUrl(company, 'magento2')}
            done={isDone(connectMagento2.status)}
          />
        )}

        {createSFTPCreds?.required && (
          <ConnectInternalSFTPCard done={isDone(createSFTPCreds.status)} />
        )}

        {createExternalSFTPCredentials?.required && (
          <ConnectExternalSFTPCard done={isDone(createExternalSFTPCredentials.status)} />
        )}

        {uploadSFTPFile?.required && (
          <UploadSFTPProductFileCard
            done={isDone(uploadSFTPFile.status)}
            disabled={!isDone(createSFTPCreds.status || createExternalSFTPCredentials.status)}
            isExternalSFTP={company?.productPlatform === 'external-sftp'}
            company={company}
          />
        )}

        {createCSVMappings?.required && (
          <GenerateCSVMappingsCard
            done={isDone(createCSVMappings.status)}
            disabled={!isDone(uploadSFTPFile.status)}
          />
        )}

        {addPaymentMethod?.required && (
          <AddPaymentMethodCard done={isDone(addPaymentMethod.status)} />
        )}

        {addBillingAddress?.required && (
          <AddBillingAddressCard
            isDone={isDone(addBillingAddress.status)}
            isConnected={connectPlatformDone}
          />
        )}

        <AddAddressCard
          isDone={isDone(addShippingAddress.status)}
          company={company}
          addressType="shipping"
        />

        {addReturnsAddress?.required && (
          <AddAddressCard
            isDone={isDone(addReturnsAddress.status)}
            company={company}
            addressType="returns"
          />
        )}

        {connectStripe?.required && (
          <ConnectStripeCard
            skipped={connectStripe.status === SKIPPED}
            done={isDone(connectStripe.status)}
          />
        )}

        {activeProducts?.required && (
          <ActivateProductsCard
            done={isDone(activeProducts.status)}
            disabled={connectPlatformRequired && !connectPlatformDone}
            isSeller={isSeller}
            syncing={
              syncing &&
              syncing?.getProductsStart > syncing?.getProductsEnd &&
              company?.productPlatform !== 'other'
            }
          />
        )}

        {viewEDISpecifications?.required && (
          <ViewEDISpecsCard done={isDone(viewEDISpecifications.status)} />
        )}

        {company && configureEDISettings?.required && (
          <ConfigureEDISettingsCard done={isDone(configureEDISettings.status)} company={company} />
        )}

        {createdPriceList?.required && (
          <CreatePriceListCard
            done={isDone(createdPriceList.status)}
            disabled={!isDone(activeProducts.status)}
          />
        )}

        {shippingMethods?.required && (
          <AddShippingMethodsCard done={isDone(shippingMethods.status)} />
        )}

        {user && actionsAssigned?.required && (
          <AssignedActions done={isDone(actionsAssigned.status)} />
        )}
      </Flex>

      <SectionHeader className="mt-5">
        <hr />
        <h4>OPTIONAL</h4>
        <hr />
      </SectionHeader>

      {loading && (
        <>
          <LoadingCard />
          <LoadingCard />
        </>
      )}

      <Flex column gap="16px">
        {enableSSO.required && (
          <EnableSSOCard skipped={enableSSO.status === SKIPPED} done={isDone(enableSSO.status)} />
        )}

        {invitePartners.required && (
          <InvitePartnersCard
            done={isDone(invitePartners.status)}
            company={company}
            disableInvite={billingDetailsRequired && !billingDetailsDone}
          />
        )}

        {testOrder?.status && <TestOrderCard done={isDone(testOrder.status)} />}

        {inviteTeam.required && (
          <InviteTeamCard done={isDone(inviteTeam.status)} company={company} user={user} />
        )}

        {connectEasyPost && connectEasyPost.required && (
          <ConnectEasyPostCard done={isDone(connectEasyPost.status)} />
        )}
      </Flex>
    </GetStartedContainer>
  );
};

const GetStartedPage = () => (
  <DashboardPage>
    <GetStarted />
  </DashboardPage>
);

export default GetStartedPage;
