import { isEmpty } from 'lodash';
import type { Metafields } from 'types/general';
import {
  InvoicePosterReason,
  type Partner,
  type PartnerGettingStartedSteps,
} from 'types/models/partner';

export const friendlyInvoicePosterReason = (reason: InvoicePosterReason) => {
  switch (reason) {
    case InvoicePosterReason.BuyerPaymentsDisabled: {
      return 'The buyer has disabled all order payments.';
    }
    case InvoicePosterReason.SellerPaymentsDisabled: {
      return 'The seller has disabled all order payments.';
    }
    case InvoicePosterReason.PartnerBillingDisabled: {
      return 'Automatic invoicing is disabled for this partnership.';
    }
    case InvoicePosterReason.OrderIsTest: {
      return 'This is a test order.';
    }
    case InvoicePosterReason.NoGateway: {
      return 'The seller has not set up Stripe.';
    }
    default: {
      return '';
    }
  }
};

export const getNameOrEmail = (
  { buyerName, sellerName, reference: email }: Partner,
  isBuyer: boolean
) => {
  if (isBuyer && isEmpty(sellerName)) return email;
  if (!isBuyer && isEmpty(buyerName)) return email;

  return isBuyer ? sellerName : buyerName;
};

type Step = {
  label: string;
  group: string;
  articleId?: string;
};

// The order of this array determines the order of groups
export const GETTING_STARTED_GROUPS = [
  { label: 'Set Up Company Profile', value: 'company' },
  { label: 'Connect Platform', value: 'platform' },
  { label: 'Create Price Lists', value: 'pricing' },
  { label: 'Configure Billing', value: 'billing' },
  { label: 'Set Up Shipping', value: 'shipping' },
  { label: 'Complete Assigned Actions', value: 'actions' },
];

// orders, products
const STEPS: Record<string, Step> = {
  connectShopify: {
    label: 'Connect Shopify',
    group: 'platform',
    articleId: 'eqxyqt0mtj',
  },
  connectBigCommerce: {
    label: 'Connect Big Commerce',
    group: 'platform',
    articleId: 'mvbcncoh60',
  },
  connectWooCommerce: {
    label: 'Connect WooCommmerce',
    group: 'platform',
    articleId: '3ad5l1ysc6',
  },
  connectMagento2: {
    label: 'Connect Magento 2',
    group: 'platform',
  },
  connectStripe: {
    label: 'Connect Stripe',
    group: 'billing',
    articleId: '8lwln18tin',
  },
  connectEasyPost: {
    label: 'Connect EasyPost',
    group: 'shipping',
  },
  addPaymentMethod: {
    label: 'Add Payment Method',
    group: 'billing',
  },
  addBillingAddress: {
    label: 'Add Billing Address',
    group: 'company',
    articleId: 'q4znpe6bo2',
  },
  addShippingAddress: {
    label: 'Add Shipping Address',
    group: 'company',
  },
  addReturnsAddress: {
    label: 'Add Returns Address',
    group: 'company',
  },
  inviteTeam: {
    label: 'Invite Team',
    group: 'company',
    articleId: 'dvzdivcsdu',
  },
  invitePartners: {
    label: 'Invite Partners',
    group: 'company',
    articleId: 'dvwv9wl2ws',
  },
  activeProducts: { label: 'Sync Products', group: 'platform' },
  createdPriceList: {
    label: 'Setup Price List',
    group: 'prices',
    articleId: 'ia2nwgi8be',
  },
  createSFTPCreds: {
    label: 'Create SFTP Credentials',
    group: 'platform',
  },
  createExternalSFTPCredentials: {
    label: 'Create External SFTP Credentials',
    group: 'platform',
  },
  uploadSFTPFile: {
    label: 'Upload SFTP File',
    group: 'platform',
  },
  createCSVMappings: {
    label: 'Create CSV Mappings',
    group: 'platform',
    articleId: 'delewtxyn5#step_3_configure_your_product_file_s_csv_mappings',
  },
  ediSchemaSetup: {
    label: 'Setup EDI Schema',
    group: 'platform',
  },
  enableSSO: {
    label: 'Enable SSO',
    group: 'company',
  },
  testOrder: {
    label: 'Test Order',
    group: 'platform',
  },
  actionsAssigned: {
    label: 'Required Actions',
    group: 'actions',
    articleId: 'gc59nwyivb',
  },
  shippingMethods: { label: 'Add Shipping Methods', group: 'shipping' },
  configureEDISettings: {
    label: 'Configure EDI Settings',
    group: 'platform',
  },
  viewEDISpecifications: {
    label: 'View EDI Specifications',
    group: 'platform',
  },
};

export const groupPendingGettingStartedSteps = (gettingStarted: PartnerGettingStartedSteps) => {
  type GettingStartedStepKey = keyof typeof gettingStarted;

  const groupedSteps: Record<string, Step[]> = {};

  // Iterate over API-supplied steps
  Object.keys(gettingStarted).forEach((stepName) => {
    const gettingStartedStep = gettingStarted[stepName as GettingStartedStepKey];
    // Toss out steps that are not required or already complete
    if (!gettingStartedStep.required || gettingStartedStep.status !== 'incomplete') return;

    // Toss invite team and invite partners - knowing whether your
    // partner has formed other partnerships or added users is not useful
    if (stepName === 'invitePartners' || stepName === 'inviteTeam') return;

    // Get friendly step data
    const step = STEPS[stepName];
    if (!step) return;

    // Add step to group
    if (!groupedSteps[step.group]) groupedSteps[step.group] = [];
    groupedSteps[step.group].push(step);
  });

  // Convert into an ordered list of groups using the GETTING_STARTED_GROUPS array
  return GETTING_STARTED_GROUPS.filter((group) => groupedSteps[group.value]).map((group) => {
    return {
      label: group.label,
      steps: groupedSteps[group.value],
    };
  });
};

export const FIELD_LABELS = ['Namespace', 'Label', 'Value'];

export interface PartnerMetafieldSchema {
  namespace: string;
  key: string;
  value?: string;
}

export interface PartnerMetafieldData {
  isNew?: boolean;
  id?: string;
  value: string;
  label: string;
  readOnly?: boolean;
}

export const indexFields = (fields: PartnerMetafieldData[][]) =>
  fields.map(([namespaceField, labelField, valueField], idx) => [
    {
      ...namespaceField,
      id: `namespace-${idx},0`,
    },
    {
      ...labelField,
      id: `namespace-${idx},1`,
    },
    {
      ...valueField,
      id: `namespace-${idx},2`,
    },
  ]);

const addTemplateToFields = (
  schema: PartnerMetafieldSchema[],
  fields: PartnerMetafieldData[][]
) => {
  const fieldKeys = fields.map(([{ value: namespace }, { value: key }]) => `${namespace}-${key}`);
  const fieldsReference = new Set(fieldKeys);

  const templateFields = schema
    .filter(({ namespace, key }) => !fieldsReference.has(`${namespace}-${key}`))
    .map(({ namespace, key, value }) => [
      {
        label: 'Namespace',
        value: namespace,
        readOnly: true,
      },
      {
        label: 'Label',
        value: key,
        readOnly: true,
      },
      {
        label: 'Value',
        value: value || '',
      },
    ]);

  // re-index combined fields
  return indexFields([...templateFields, ...fields]);
};

export const mapDataToFields = (schema: PartnerMetafieldSchema[], data: Metafields | null) => {
  if (!data) return [];

  // index schema to determine if field should be read only
  const schemaKeys = schema.map(({ namespace, key }) => `${namespace}-${key}`);
  const schemaReference = new Set(schemaKeys);

  let fieldRowIdx = 0;
  const fields = Object.entries(data)
    .reduce(
      (allFields, [namespace, fieldMap]) => [
        ...allFields,
        ...Object.entries(fieldMap).map(([key, { value }]) => {
          const fieldRow = [
            {
              id: `namespace-${fieldRowIdx},0`,
              label: 'Namespace',
              value: namespace,
              readOnly: schemaReference.has(`${namespace}-${key}`),
            },
            {
              id: `namespace-${fieldRowIdx},1`,
              label: 'Label',
              value: key,
              readOnly: schemaReference.has(`${namespace}-${key}`),
            },
            {
              id: `namespace-${fieldRowIdx},2`,
              label: 'Value',
              value,
            },
          ];
          fieldRowIdx += 1;

          return fieldRow;
        }),
      ],
      [] as PartnerMetafieldData[][]
    )
    .sort(([, { readOnly: isTemplate }]) => (isTemplate ? -1 : 1)); // float schema fields to top

  return schema.length > 0 ? addTemplateToFields(schema, fields) : fields;
};

export const mapFieldsToData = (fields: PartnerMetafieldData[][], type = 'metafields') => ({
  [type]: fields
    .filter((fieldRow) => {
      if (fieldRow.every((field) => field.value)) return true;
      if (fieldRow[0].isNew) return false;

      return !fieldRow[0].readOnly;
    })
    .reduce(
      (data, [{ value: namespace }, { value: key }, { value }]) =>
        data[namespace]
          ? {
              ...data,
              [namespace]: {
                ...data[namespace],
                [key]: { value },
              },
            }
          : {
              ...data,
              [namespace]: {
                [key]: { value },
              },
            },
      {} as Record<string, Record<string, { value: string }>>
    ),
});
