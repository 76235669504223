import _get from 'lodash/get';
import { useDispatch } from 'react-redux';

import Flex from '@react-css/flex';
import { useAppSelector } from 'store';
import { updateOnboardingStatus } from 'store/slices/company';
import { CSV_FILE_TYPE, getFiles } from 'store/slices/sftpUser';
import Alert from 'storybook/stories/cells/Alert';
import GetStartedCard from 'storybook/stories/cells/Card/GetStartedCard';
import Body from 'storybook/stories/molecules/Body';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Link from 'storybook/stories/molecules/Link';
import SupportLink from 'storybook/stories/molecules/Link/SupportLink';
import { useSafeState } from 'utils/hooks';

const FileList = ({ files, retrieveFilesClicked, isGettingFiles, company }) => {
  return (
    <>
      {files.length > 0 && (
        <>
          <Body as="p">Here are the files we found:</Body>

          <ul>
            {files.map((fileName) => (
              <li key={fileName}>
                <code>
                  {company?.sftp?.downloadDirectory || '/'}
                  {fileName}
                </code>
              </li>
            ))}
          </ul>
        </>
      )}

      {retrieveFilesClicked && !isGettingFiles && files.length === 0 && (
        <Alert type="error" elevation="1">
          No files found. Please try uploading again. If your files still cannot be found, please
          contact <a href="mailto:support@convictional.com">support@convictional.com</a>
        </Alert>
      )}
    </>
  );
};

const UploadSFTPProductFileCard = ({ done, disabled, isExternalSFTP, company }) => {
  const { files, isGettingFiles } = useAppSelector(({ sftpUser }) => sftpUser);
  const [error, setError] = useSafeState('');
  const [retrieveFilesClicked, setRetrieveFilesClicked] = useSafeState(false);
  const dispatch = useDispatch();

  const onGetUploadedFiles = () => {
    setError('');
    setRetrieveFilesClicked(true);
    const queryParams = {
      fileType: CSV_FILE_TYPE,
      key: _get(company, 'sftp.downloadDirectory', ''),
    };
    dispatch(getFiles(queryParams)).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  const onMarkComplete = () => {
    setError('');
    dispatch(updateOnboardingStatus({ sftpFileUploadCompleted: true })).then((action) => {
      if (action.error) {
        setError(action.error.message);
      }
    });
  };

  return (
    <GetStartedCard isComplete={done} title="Upload your product CSV file">
      {!disabled ? (
        <Body as="p">
          Once you finish{' '}
          {isExternalSFTP
            ? 'connecting Modern Dropship to your SFTP server'
            : 'connecting your SFTP client to our server'}
          , above, you will be able to continue with this step.{' '}
          <SupportLink article="delewtxyn5#step_2_upload_a_products_file">Learn more</SupportLink>
        </Body>
      ) : (
        <>
          <Body as="p">
            Please upload your product CSV file into the{' '}
            <code>{company?.sftp?.downloadDirectory || 'root'}</code> directory of{' '}
            {isExternalSFTP ? 'your' : 'our'} SFTP server.{' '}
            <SupportLink article="delewtxyn5#step_2_upload_a_products_file">Learn more</SupportLink>
          </Body>

          {isExternalSFTP ? (
            <Body as="p">
              Modern Dropship&apos;s IP address (<strong>35.223.28.122</strong>) must be allowed to
              access your SFTP server before you are able to complete this step.
            </Body>
          ) : (
            <>
              <Body as="p">Here is Modern Dropship&apos;s SFTP connection information:</Body>
              <ul>
                <li>
                  <strong>Host:</strong> sftp.convictional.com
                </li>
                <li>
                  <strong>Port:</strong> 2122
                </li>
              </ul>
              <Body as="p">
                If you have forgotten your username or password you may view them in your{' '}
                <Link to="/settings/dropshipping/integrations">Integration settings</Link>.
              </Body>
            </>
          )}

          <Body as="p">
            Once your file has been uploaded, click the button below to test the connection.
          </Body>

          <FileList
            files={files}
            retrieveFilesClicked={retrieveFilesClicked}
            isGettingFiles={isGettingFiles}
            company={company}
          />

          <Flex justifyEnd gap="8px">
            <SecondaryButton size="small" onClick={onGetUploadedFiles} disabled={isGettingFiles}>
              {retrieveFilesClicked && files.length > 0 ? 'Refresh Files' : 'Fetch Files'}
            </SecondaryButton>

            {retrieveFilesClicked && files.length > 0 && (
              <PrimaryButton
                size="small"
                onClick={onMarkComplete}
                disabled={files.length === 0 || isGettingFiles}
              >
                Complete Step
              </PrimaryButton>
            )}
          </Flex>
        </>
      )}

      {error && <Body color="error500">{error}</Body>}
    </GetStartedCard>
  );
};

export default UploadSFTPProductFileCard;
