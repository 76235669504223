import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { isEmpty, isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ConfirmationModal from 'components/Common/ConfirmationModal';
import Spinner from 'components/Common/Spinner';
import StepNavigationCard from 'containers/Quotes/shared/StepNavigationCard';
import useAlertQueue from 'hooks/useAlertQueue';
import Card from 'storybook/stories/cells/Card';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import SearchForm, { SearchFormButton, SearchFormInput } from 'storybook/stories/cells/SearchForm';
import Table from 'storybook/stories/cells/Table';
import Stepper, { Step } from 'storybook/stories/organisms/Stepper';
import type { BuyerProduct } from 'types/models/buyer-product';
import { getBuyerProducts } from 'utils/api/buyer/products';
import { humanizeMoney } from 'utils/currencies';

import { useForm } from 'react-hook-form';
import QuoteLayout, { QuoteLayoutAside, QuoteLayoutMain } from '../QuoteLayout';
import QuoteSummary from '../QuoteSummary';
import useAbandonedQuotePrompt from '../hooks/useAbandonedQuotePrompt';
import NewQuoteContext from './NewQuoteContext';
import ProductDetails from './ProductDetails';

type ProductSearchFormValues = {
  title: string;
};

type SearchParams = ProductSearchFormValues & {
  companyId: string;
};

interface ProductTableProps {
  products: BuyerProduct[];
  setSelectedProduct: React.Dispatch<React.SetStateAction<BuyerProduct>>;
}

const ProductSelectionHeading = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.color.gray700};
`;

const ProductImage = styled.img`
  display: block;
  max-width: 60px;

  border: 1px solid ${({ theme }) => theme.color.gray300};
  border-radius: 4px;
`;

const ProductTable = ({ products, setSelectedProduct }: ProductTableProps) => {
  const handleRowClick = (product: BuyerProduct) => {
    setSelectedProduct(product);
  };

  return (
    <Table variant="data" data-testid="product-selection-table">
      <Table.THead>
        <Table.TR>
          <Table.TH aria-label="Product Image" width="60px" />
          <Table.TH>Product Title</Table.TH>
          <Table.TH>Code</Table.TH>
          <Table.TH>Brand</Table.TH>
          <Table.TH>Wholesale Price</Table.TH>
        </Table.TR>
      </Table.THead>

      <Table.TBody>
        {products.map((product) => {
          const [primaryImage] = product.images || [];

          const hasPriceRange = !isEqual(
            product.wholesalePricing.min,
            product.wholesalePricing.max
          );

          return (
            <Table.TR key={product.id} onClick={() => handleRowClick(product)}>
              <Table.TD>
                {primaryImage && (
                  <ProductImage src={primaryImage.source} alt={`Image for ${product.title}`} />
                )}
              </Table.TD>
              <Table.TD>{product.title}</Table.TD>
              <Table.TD>{product.sellerReference}</Table.TD>
              <Table.TD>{product.brand}</Table.TD>
              <Table.TD>
                {humanizeMoney(product.wholesalePricing.min)}

                {hasPriceRange && (
                  <>
                    {' to '}
                    {humanizeMoney(product.wholesalePricing.max)}
                  </>
                )}
              </Table.TD>
            </Table.TR>
          );
        })}
      </Table.TBody>
    </Table>
  );
};

const ProductSelection = () => {
  const { quote, quoteMinimumValue } = useContext(NewQuoteContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [products, setProducts] = useState([] as BuyerProduct[]);
  const [selectedProduct, setSelectedProduct] = useState({} as BuyerProduct);
  const [hasMore, setHasMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useState({
    companyId: quote.sellerCompanyLegacyId,
  } as Partial<SearchParams>);

  const history = useHistory();
  const { addErrorAlert } = useAlertQueue();
  useAbandonedQuotePrompt(quote);

  const { handleSubmit, register } = useForm<ProductSearchFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    setCurrentPage(0);
    setSearchParams(data);
  });

  // Helpers

  const quoteAmount = quote.totalPrice?.amount ?? 0;
  const isQuoteMinimumValueMet = quoteMinimumValue.amount - quoteAmount <= 0;

  const disableNextButton = isEmpty(quote.items) || !isQuoteMinimumValueMet;

  const resetSelectedProduct = () => {
    setSelectedProduct({} as BuyerProduct);
  };

  // Side Effects

  useEffect(() => {
    setIsLoading(true);

    getBuyerProducts({ page: currentPage, limit: 20, ...searchParams })
      .then((response) => {
        setProducts(response.data);
        setHasMore(response.hasMore);
      })
      .catch((error) => {
        addErrorAlert('Unable to fetch Products', 'Please reload your browser.');
        console.error('Unable to fetch Products', error.message);
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, searchParams, addErrorAlert]);

  // Event Handlers

  const handleBackButtonClick = () => {
    history.push(`/quotes/new/partner`);
  };

  const handleNextButtonClick = () => {
    history.push(`/quotes/new/${quote.id}/addresses`);
  };

  // Render

  return (
    <>
      <ConfirmationModal
        show={showModal}
        title="Are you sure you want to select a new Partner?"
        confirmText="Select a new Partner"
        cancelText="Resume Quote"
        onConfirm={handleBackButtonClick}
        onCancel={() => setShowModal(false)}
      >
        Selecting a new partner will cancel your current progress and start a new quote.
      </ConfirmationModal>
      <QuoteLayout title="Request Quote" data-testid="product-selection">
        <QuoteLayoutMain>
          <Stepper>
            <Step number={1} status="completed">
              Select Partner
            </Step>

            <Step number={2} status="active">
              Select Products
            </Step>

            <Step number={3}>Shipping &amp; Billing</Step>

            <Step number={4}>Review &amp; Submit</Step>
          </Stepper>

          {!isEmpty(selectedProduct) ? (
            <ProductDetails
              product={selectedProduct}
              handleBackButtonClick={resetSelectedProduct}
            />
          ) : (
            <Card>
              <Flex column gap="16px">
                <Flex justifySpaceBetween alignItemsCenter>
                  <Flex.Item grow={1}>
                    <ProductSelectionHeading>Select Products</ProductSelectionHeading>
                  </Flex.Item>

                  <Flex.Item grow={2}>
                    <SearchForm onSubmit={onSubmit}>
                      <SearchFormInput
                        {...register('title')}
                        placeholder="Search for Products by Title"
                        aria-label="Search for Products by Title"
                      />

                      <SearchFormButton disabled={isLoading} />
                    </SearchForm>
                  </Flex.Item>
                </Flex>

                <Grid rows="auto" gap="32px">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <ProductTable products={products} setSelectedProduct={setSelectedProduct} />

                      <Flex justifyEnd alignItemsCenter gap="16px">
                        <PageList currentPage={currentPage + 1} />

                        <PageNavigation
                          hasPrevious={currentPage !== 0}
                          hasNext={hasMore}
                          onPreviousClick={() => setCurrentPage((page) => page - 1)}
                          onNextClick={() => setCurrentPage((page) => page + 1)}
                        />
                      </Flex>
                    </>
                  )}
                </Grid>
              </Flex>
            </Card>
          )}
        </QuoteLayoutMain>

        <QuoteLayoutAside>
          <StepNavigationCard
            disableBackButton={false}
            disableNextButton={disableNextButton}
            onBackButtonClick={() => setShowModal(true)}
            onNextButtonClick={handleNextButtonClick}
          />

          <QuoteSummary />
        </QuoteLayoutAside>
      </QuoteLayout>
    </>
  );
};

export default ProductSelection;
