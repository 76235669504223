import DashboardPage from 'components/Dashboard/DashboardPage';
import TimelineCard from 'containers/WhatsNewPage/TimelineCard';
import useWhatsNewEntries from 'hooks/useWhatsNewEntries';
import Page from 'storybook/stories/cells/Page';
import Body from 'storybook/stories/molecules/Body';
import Divider from 'storybook/stories/molecules/Divider';
import Heading from 'storybook/stories/molecules/Heading';
import { useDocumentTitle } from 'usehooks-ts';

const WhatsNewPage = () => {
  useDocumentTitle("What's New");

  const [newEntries, restEntries] = useWhatsNewEntries({
    shouldSetViewedAtOnUnmount: true,
  });

  return (
    <DashboardPage>
      <Page>
        <Page.Head title="What's New" />

        <Body as="p">
          Welcome to the What&apos;s New page! We&apos;re always working to improve your experience,
          so check back often to see what&apos;s new. If you have any feedback or suggestions on how
          we might be able to improve the platform, we would love to hear from you! Please reach out
          to us at{' '}
          <a href="mailto:support+feedback@convictional.com">support+feedback@convictional.com</a>
        </Body>

        {newEntries.length > 0 && (
          <>
            <Heading variant="Headings/H2">New Updates</Heading>
            <TimelineCard entries={newEntries} />
          </>
        )}

        {newEntries.length > 0 && restEntries.length > 0 && <Divider />}

        {restEntries.length > 0 && <TimelineCard entries={restEntries} />}
      </Page>
    </DashboardPage>
  );
};

export default WhatsNewPage;
