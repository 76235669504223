import { useScript, useUnmount } from 'usehooks-ts';
import config from 'utils/config';

const useHubspot = () => {
  // UseScript's OnUnmount will only remove the initial script tag
  useScript('//js.hs-scripts.com/4004130.js', {
    shouldPreventLoad: !config.isProduction,
    removeOnUnmount: true,
  });

  // When Hubspot's script tag is mounted, it'll create additional script tags
  // which we need to manually remove on unmount.
  useUnmount(() => {
    const elements = document.querySelectorAll('script[data-hsjs-portal]');
    elements.forEach((element) => {
      element.remove();
    });
  });
};

export default useHubspot;
