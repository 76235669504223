import CHANGELOG, { ChangelogAudienceType, type ChangelogEntry } from 'CHANGELOG';
import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectCommerceType, selectIsDropshipSetup } from 'store/selectors/me/company';
import { CommerceType } from 'types/models/company';

interface UseChangelogEntriesOptions {
  changelog?: ChangelogEntry[];
}

/**
 * This sorts and filters the changelog entries based on the user's commerce type and setup status.
 */
const useChangelogEntries = ({ changelog = CHANGELOG }: UseChangelogEntriesOptions = {}) => {
  const commerceType = useAppSelector(selectCommerceType);
  const isDropshipSetup = useAppSelector(selectIsDropshipSetup);

  const entries = useMemo(
    () =>
      changelog
        // Sort by timestamp, descending
        .sort((a, b) => parseISO(b.timestamp).getTime() - parseISO(a.timestamp).getTime())
        // Filter out events that should not be rendered
        .filter((entry) => {
          switch (entry.limitForAudience) {
            case ChangelogAudienceType.AnyDropship:
              return isDropshipSetup;
            case ChangelogAudienceType.DropshipSeller:
              return commerceType === CommerceType.Seller;
            case ChangelogAudienceType.DropshipBuyer:
              return commerceType === CommerceType.Buyer;
            default:
              return true;
          }
        }),
    [changelog, commerceType, isDropshipSetup]
  );

  return entries;
};

export default useChangelogEntries;
