import Flex from '@react-css/flex';
import { isEqual } from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import StepNavigationCard from 'containers/Quotes/shared/StepNavigationCard';
import {
  MinAmountLeftText,
  PartnerName,
  QuoteDate,
  QuoteHeadCard,
} from 'containers/Quotes/shared/Styles';
import useAlertQueue from 'hooks/useAlertQueue';
import Card from 'storybook/stories/cells/Card';
import TertiaryButton from 'storybook/stories/molecules/Button/TertiaryButton';
import Stepper, { Step } from 'storybook/stories/organisms/Stepper';
import { requestQuote, updateQuoteBilling, updateQuoteShipping } from 'utils/api/quotes';
import { humanizeMoney } from 'utils/currencies';
import { prettyDate } from 'utils/date';

import useAbandonedQuotePrompt from '../hooks/useAbandonedQuotePrompt';
import QuoteLayout, { QuoteLayoutAside, QuoteLayoutMain } from '../QuoteLayout';
import AddressSelectModal from '../shared/AddressSelectModal';
import BillingCard from '../shared/BillingCard';
import QuoteItemTable from '../shared/QuoteItemTable';
import ShippingCard from '../shared/ShippingCard';
import TotalsCard from '../shared/TotalsCard';
import NewQuoteContext from './NewQuoteContext';

const MinOrderValueWarningText = styled(MinAmountLeftText)`
  display: block;
  color: ${({ theme }) => theme.color.error500};
  margin: 0 12px;
`;

export const ReviewAndSubmitPage = () => {
  const { quote, refreshQuote, quoteMinimumValue } = useContext(NewQuoteContext);

  const [isLoading, setIsLoading] = useState(false);
  const [editingAddressType, setEditingAddressType] = useState('' as 'shipping' | 'billing' | '');

  const [shippingAddress, setShippingAddress] = useState(quote.shippingAddress);
  const [billingAddress, setBillingAddress] = useState(quote.billingAddress);

  const history = useHistory();
  const { addErrorAlert, addSuccessAlert } = useAlertQueue();
  useAbandonedQuotePrompt(quote);

  // Helpers

  const hasShippingAddressChanged = !isEqual(quote.shippingAddress, shippingAddress);
  const hasBillingAddressChanged = !isEqual(quote.billingAddress, billingAddress);

  const quoteAmount = quote.totalPrice?.amount ?? 0;
  const isQuoteMinimumValueMet = quoteMinimumValue.amount - quoteAmount <= 0;

  // Event Handlers

  const handleBackButtonClick = () => history.push(`/quotes/new/${quote.id}/addresses`);

  const handleNextButtonClick = async () => {
    try {
      setIsLoading(true);

      if (hasShippingAddressChanged)
        await updateQuoteShipping(quote.id, {
          shippingAddress,
          requestedShipBy: quote.requestedShipBy,
          requestedDeliverBy: quote.requestedDeliverBy,
        });

      if (hasBillingAddressChanged) await updateQuoteBilling(quote.id, { billingAddress });

      await requestQuote(quote.id);

      setIsLoading(false);

      addSuccessAlert('Your Quote has been Requested', "We'll let your partner know right away!");

      history.push(`/quotes/show/${quote.id}`);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Something went wrong', errorMessage as string);
      console.error('Something went wrong', errorMessage);
    }
  };

  const handleAddMoreProductsButtonClick = () => {
    history.push(`/quotes/new/${quote.id}/products`);
  };

  // Render

  return (
    <>
      <QuoteLayoutMain>
        <Stepper>
          <Step number={1} status="completed">
            Select Partner
          </Step>

          <Step number={2} status="completed">
            Select Products
          </Step>

          <Step number={3} status="completed">
            Shipping &amp; Billing
          </Step>

          <Step number={4} status="active">
            Review &amp; Submit
          </Step>
        </Stepper>

        <QuoteHeadCard>
          <PartnerName>{quote.sellerName}</PartnerName>
          <QuoteDate>{prettyDate(quote.createdAt)}</QuoteDate>
        </QuoteHeadCard>

        <Card>
          <QuoteItemTable
            isEditable
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            quote={quote}
            refreshQuote={refreshQuote}
          />
        </Card>

        <Flex justifySpaceBetween>
          <Flex.Item>
            <TertiaryButton size="small" $iconName="add" onClick={handleAddMoreProductsButtonClick}>
              Add more products
            </TertiaryButton>
          </Flex.Item>

          <Flex justifyEnd>
            <TotalsCard quote={quote}>
              {!isQuoteMinimumValueMet && (
                <MinOrderValueWarningText>
                  Min. Order Value {humanizeMoney(quoteMinimumValue)}
                </MinOrderValueWarningText>
              )}
            </TotalsCard>
          </Flex>
        </Flex>
      </QuoteLayoutMain>

      <QuoteLayoutAside>
        <StepNavigationCard
          nextButtonText="Submit"
          disableBackButton={false}
          disableNextButton={isLoading || !isQuoteMinimumValueMet}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
        />

        <ShippingCard
          isEditable
          address={shippingAddress}
          shipBy={quote.requestedShipBy}
          deliverBy={quote.requestedDeliverBy}
          onEditButtonClick={() => setEditingAddressType('shipping')}
        />

        <BillingCard
          isEditable
          address={billingAddress}
          onEditButtonClick={() => setEditingAddressType('billing')}
        />

        <AddressSelectModal
          addressType={editingAddressType}
          handleClose={(address) => {
            if (editingAddressType === 'shipping') setShippingAddress(address);
            if (editingAddressType === 'billing') setBillingAddress(address);

            setEditingAddressType('');
            refreshQuote();
          }}
        />
      </QuoteLayoutAside>
    </>
  );
};

const ReviewAndSubmit = () => (
  <QuoteLayout title="Request Quote" data-testid="review-and-submit">
    <ReviewAndSubmitPage />
  </QuoteLayout>
);

export default ReviewAndSubmit;
