/* eslint-disable jsx-a11y/label-has-associated-control */
import type { PDFField } from 'pdf-lib';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Modal from 'storybook/stories/cells/Modal';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';

import PreviewCreateActionCard from 'containers/PartnerPage/Actions/Common/PreviewCreateActionCard';
import PreviewCreateActionEmail from 'containers/PartnerPage/Actions/Common/PreviewCreateActionEmail';
import { TERMS_AND_CONDITIONS } from 'containers/PartnerPage/Actions/Kinds';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import type { PartnerAction } from 'types/models/partner-action';

type CreateTermsAndConditionsMappingData = {
  sellerCompanyName?: string;
  dropshipMargin: string;
  wholesaleMargin: string;
  baseCurrency: string;
  retailCurrency: string;
};

interface CreateTermsAndConditionsMappingModalProps {
  showModal: boolean;
  onHide: () => void;
  onBackClick: () => void;
  onNextClick: (data: CreateTermsAndConditionsMappingData) => void;
  isActionTemplate: boolean;
  defaultMappingData: CreateTermsAndConditionsMappingData;
  bytes: number[];
  action: Partial<PartnerAction>;
}

const parsePDF = async (pdfBytes: number[]) => {
  const uint8Bytes = Uint8Array.from(pdfBytes);
  const parsedPDF = await PDFDocument.load(uint8Bytes, {
    ignoreEncryption: true,
  });
  return parsedPDF.getForm();
};

const DefineMappingsModal = ({
  showModal,
  onHide,
  onBackClick,
  onNextClick,
  isActionTemplate,
  defaultMappingData,
  bytes,
  action,
}: CreateTermsAndConditionsMappingModalProps) => {
  const isWholesaleEnabled = useFeatureFlagEnabled('wholesale');

  const [pdfFields, setPdfFields] = useState([] as PDFField[]);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      sellerCompanyName: defaultMappingData?.sellerCompanyName,
      dropshipMargin: defaultMappingData?.dropshipMargin,
      wholesaleMargin: defaultMappingData?.wholesaleMargin,
      baseCurrency: defaultMappingData?.baseCurrency,
      retailCurrency: defaultMappingData?.baseCurrency,
    },
  });

  const selectOptions = pdfFields.map((pdfField: PDFField) => {
    return {
      label: pdfField.getName(),
      value: pdfField.getName(),
    };
  });

  useEffect(() => {
    if (showModal && bytes.length > 0) {
      const getForm = async () => parsePDF(bytes);

      getForm().then((f) => setPdfFields(f.getFields()));
    }
  }, [bytes, showModal]);

  const onSubmit = (data: any) => {
    const formattedData = {
      sellerCompanyName: data.sellerCompanyName?.value,
      dropshipMargin: data.dropshipMargin?.value,
      wholesaleMargin: data.wholesaleMargin?.value,
      baseCurrency: data.baseCurrency?.value,
      retailCurrency: data.baseCurrency?.value,
    };

    onNextClick(formattedData);
  };

  return (
    <Modal.Root
      show={showModal}
      onHide={onHide}
      aria-labelledby="action-terms-and-conditions-general-modal"
      animation={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isActionTemplate
            ? 'Create Terms and Conditions Action Template'
            : 'Create Terms and Conditions Action'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <h4 className="mb-4">Map Margin Fields</h4>
            <p className="text-muted">
              Map your margin fields to a corresponding Modern Dropship margin field.
            </p>
            <div className="form-group">
              <label>Vendor Name</label>
              <Controller
                render={({ field }) => (
                  <Select
                    id="sellerCompanyName"
                    // @ts-ignore
                    options={selectOptions}
                    isSearchable
                    menuPlacement="auto"
                    placeholder="--"
                    {...field}
                  />
                )}
                control={control}
                name="sellerCompanyName"
              />
            </div>
            <div className="form-group">
              <label>Dropship Margin</label>
              <Controller
                render={({ field }) => (
                  <Select
                    id="dropshipMargin"
                    // @ts-ignore
                    options={selectOptions}
                    isSearchable
                    menuPlacement="auto"
                    placeholder="--"
                    {...field}
                  />
                )}
                control={control}
                name="dropshipMargin"
              />
            </div>
            {isWholesaleEnabled && (
              <div className="form-group">
                <label>Wholesale Margin</label>
                <Controller
                  render={({ field }) => (
                    <Select
                      id="wholesaleMargin"
                      // @ts-ignore
                      options={selectOptions}
                      isSearchable
                      menuPlacement="auto"
                      placeholder="--"
                      {...field}
                    />
                  )}
                  control={control}
                  name="wholesaleMargin"
                />
              </div>
            )}
            <div className="form-group">
              <label>Currency</label>
              <Controller
                render={({ field }) => (
                  <Select
                    id="baseCurrency"
                    // @ts-ignore
                    options={selectOptions}
                    isSearchable
                    menuPlacement="auto"
                    placeholder="--"
                    {...field}
                  />
                )}
                control={control}
                name="baseCurrency"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 modal-inner-block">
            <PreviewCreateActionCard
              title={action.cardTitle}
              text={action.cardText}
              kind={TERMS_AND_CONDITIONS}
              required={action.required}
              optionalDoc={false}
            />
            <PreviewCreateActionEmail
              kind={TERMS_AND_CONDITIONS}
              emailSubject={action.emailSubject}
              emailMessage={action.emailMessage}
              signers=""
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <div className="text-right">
              <SecondaryButton className="mr-3" size="small" onClick={onBackClick}>
                Back
              </SecondaryButton>
              <PrimaryButton className="mr-3" size="small" type="submit">
                Next
              </PrimaryButton>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Root>
  );
};

export default DefineMappingsModal;
