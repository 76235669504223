import Flex from '@react-css/flex';

import Card from 'storybook/stories/cells/Card';
import Divider from 'storybook/stories/molecules/Divider';
import type { Address as AddressType } from 'types/general';
import { isZeroDate, prettyDate } from 'utils/date';

import {
  Address,
  AddressHeading,
  AddressLine,
  EditButton,
  EditDot,
  EditText,
  QuoteDate,
  ShippingDateLabel,
} from './Styles';

interface ShippingCardProps {
  address: AddressType;
  isEditable: boolean;
  shipBy: string;
  deliverBy: string;
  onEditButtonClick: () => void;
}

const ShippingCard = ({
  address,
  isEditable,
  shipBy,
  deliverBy,
  onEditButtonClick,
}: ShippingCardProps) => {
  const hasShippingDate = !isZeroDate(shipBy);
  const hasDeliveryDate = !isZeroDate(deliverBy);

  return (
    <Card>
      <Flex column gap="16px">
        <Flex justifyCenter>
          <AddressHeading>Shipping</AddressHeading>
        </Flex>

        <Flex column gap="16px">
          <Flex alignItemsCenter justifySpaceBetween gap="32px">
            <Address>
              <AddressLine>
                <strong>{address.name}</strong>
              </AddressLine>
              <AddressLine>{address.addressOne}</AddressLine>
              {address?.addressTwo && <AddressLine>{address.addressTwo}</AddressLine>}
              <AddressLine>
                {address.city}, {address.state}
              </AddressLine>
              <AddressLine>{address.zip}</AddressLine>
            </Address>

            {isEditable && (
              <EditButton onClick={onEditButtonClick} data-testid="edit-shipping-button">
                <EditDot />
                <EditText>Edit</EditText>
              </EditButton>
            )}
          </Flex>

          {(hasShippingDate || hasDeliveryDate) && (
            <Flex column gap="16px" data-testid="shipping-dates">
              <Divider />

              {hasShippingDate && (
                <Flex.Item>
                  <ShippingDateLabel>Requested Shipping Date</ShippingDateLabel>
                  <QuoteDate iconName="local_shipping">{prettyDate(shipBy)}</QuoteDate>
                </Flex.Item>
              )}

              {hasDeliveryDate && (
                <Flex.Item>
                  <ShippingDateLabel>Requested Delivery Date</ShippingDateLabel>
                  <QuoteDate iconName="markunread_mailbox">{prettyDate(deliverBy)}</QuoteDate>
                </Flex.Item>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default ShippingCard;
