import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';

import Spinner from 'components/Common/Spinner';
import DashboardPage from 'components/Dashboard/DashboardPage';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import Card from 'storybook/stories/cells/Card';
import Page from 'storybook/stories/cells/Page';
import PageList from 'storybook/stories/cells/PageList';
import PageNavigation from 'storybook/stories/cells/PageNavigation';
import Table from 'storybook/stories/cells/Table';
import PrimaryButton from 'storybook/stories/molecules/Button/PrimaryButton';
import StatusChip from 'storybook/stories/molecules/Chip/StatusChip';
import type { Quote } from 'types/models/quote';
import { getQuotes } from 'utils/api/quotes';
import { ACCOUNT_TYPE_BUYER } from 'utils/constants';
import { humanizeMoney } from 'utils/currencies';
import { prettyDate } from 'utils/date';
import { capitalizeFirstChar } from 'utils/strings';

interface QuotesPageWrapperProps {
  children: ReactNode;
}

interface QuoteStatusChipProps {
  status: string;
}

export const SELLER_REQUEST_STATUSES = ['pending', 'approved', 'rejected'];

const QuotesPageWrapper = ({ children }: QuotesPageWrapperProps) => {
  const me = useAppSelector((state) => state.me);

  const isBuyer = me.company?.commerceType === ACCOUNT_TYPE_BUYER;

  return (
    <DashboardPage>
      <Page>
        <Page.Head title="Quotes">
          {isBuyer && (
            <PrimaryButton size="small" as={Link} to="/quotes/new/partner">
              Request Quote
            </PrimaryButton>
          )}
        </Page.Head>

        <Page.Body>{children}</Page.Body>
      </Page>
    </DashboardPage>
  );
};

export const QuoteStatusChip = ({ status }: QuoteStatusChipProps) => {
  if (status === 'accepted') {
    return <StatusChip kind="success">{capitalizeFirstChar(status)}</StatusChip>;
  }

  if (status === 'rejected') {
    return <StatusChip kind="error">{capitalizeFirstChar(status)}</StatusChip>;
  }

  if (status === 'pending') {
    return <StatusChip kind="info">{capitalizeFirstChar(status)}</StatusChip>;
  }

  // Defaults to 'draft' status style
  return <StatusChip kind="pending">{capitalizeFirstChar(status)}</StatusChip>;
};

const QuotesPage = () => {
  useDocumentTitle('Quotes');

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [quotes, setQuotes] = useState([] as Quote[]);

  const me = useAppSelector((state) => state.me);

  const history = useHistory();
  const { addErrorAlert } = useAlertQueue();

  // Helpers

  const isBuyer = me.company?.commerceType === ACCOUNT_TYPE_BUYER;

  // Side Effects

  useEffect(() => {
    const baseQueryParams = { page: currentPage, limit: 20 };

    const params = isBuyer
      ? baseQueryParams
      : { ...baseQueryParams, status: SELLER_REQUEST_STATUSES };

    setIsLoading(true);

    getQuotes(params)
      .then((response) => {
        setQuotes(response.data);
        setHasMore(response.hasMore);
      })
      .catch((error) => {
        addErrorAlert('Unable to fetch Quotes', 'Please try refreshing your browser.');
        console.error('Unable to fetch Quotes', error.message);
      })
      .finally(() => setIsLoading(false));
  }, [isBuyer, currentPage, addErrorAlert]);

  // Event Handlers

  const handleTableRowClick = (quoteId: string) => {
    history.push(`/quotes/show/${quoteId}`);
  };

  // Render

  return (
    <QuotesPageWrapper>
      <Card>
        <Grid rows="auto" gap="32px">
          <Grid.Item>
            {isLoading ? (
              <Spinner />
            ) : (
              <Table variant="data" data-testid="quotes-table">
                <Table.THead>
                  <Table.TR>
                    <Table.TH>Quote Date</Table.TH>
                    <Table.TH>Partner</Table.TH>
                    <Table.TH>Total</Table.TH>
                    <Table.TH>Quote ID</Table.TH>
                    <Table.TH>Quote Status</Table.TH>
                  </Table.TR>
                </Table.THead>

                <Table.TBody>
                  {quotes.map((quote) => (
                    <Table.TR key={quote.id} onClick={() => handleTableRowClick(quote.id)}>
                      <Table.TD>{prettyDate(quote.createdAt)}</Table.TD>
                      <Table.TD>{isBuyer ? quote.sellerName : quote.buyerName}</Table.TD>
                      <Table.TD>{humanizeMoney(quote.totalPrice)}</Table.TD>
                      <Table.TD>{quote.id}</Table.TD>
                      <Table.TD>
                        <QuoteStatusChip status={quote.status} />
                      </Table.TD>
                    </Table.TR>
                  ))}
                </Table.TBody>
              </Table>
            )}
          </Grid.Item>

          <Grid.Item>
            <Flex justifyEnd alignItemsCenter gap="16px">
              <PageList currentPage={currentPage + 1} />

              <PageNavigation
                hasPrevious={currentPage !== 0}
                hasNext={hasMore}
                onPreviousClick={() => setCurrentPage((page) => page - 1)}
                onNextClick={() => setCurrentPage((page) => page + 1)}
              />
            </Flex>
          </Grid.Item>
        </Grid>
      </Card>
    </QuotesPageWrapper>
  );
};

export default QuotesPage;
