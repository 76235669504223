import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { isEmpty } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import StepNavigationCard from 'containers/Quotes/shared/StepNavigationCard';
import {
  Address,
  AddressLine,
  EditButton,
  EditDot,
  EditText,
} from 'containers/Quotes/shared/Styles';
import useAlertQueue from 'hooks/useAlertQueue';
import { useAppSelector } from 'store';
import Card from 'storybook/stories/cells/Card';
import Divider from 'storybook/stories/molecules/Divider';
import Stepper, { Step } from 'storybook/stories/organisms/Stepper';
import type { Address as AddressType } from 'types/general';
import type { QuoteShippingParams } from 'utils/api/quotes';
import { updateQuoteBilling, updateQuoteShipping } from 'utils/api/quotes';

import useAbandonedQuotePrompt from '../hooks/useAbandonedQuotePrompt';
import QuoteLayout, { QuoteLayoutAside, QuoteLayoutMain } from '../QuoteLayout';
import QuoteSummary from '../QuoteSummary';
import AddressSelectModal from '../shared/AddressSelectModal';
import NewQuoteContext from './NewQuoteContext';

const REQUIRED_ADDRESS_FIELDS = ['addressOne', 'city', 'country', 'zip'];

const H1 = styled.h1`
  font-size: 18px;
  margin-bottom: 32px;
`;

const H2 = styled.h2`
  font-size: 13px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin-bottom: 0;
  color: ${({ theme }) => theme.color.gray400};
`;

// Returns today's date in YYYY-MM-DD format.
// Used for preventing requestedShipBy to be set in the past.
const getTodaysFormattedDate = () => {
  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);

  return offsetDate.toISOString().split('T')[0];
};

export const AddressSelectionPage = () => {
  const companyLocations = useAppSelector((state) => state.me?.company?.locations ?? []);

  const [shippingAddress, setShippingAddress] = useState(
    (companyLocations.find((location) => location.type === 'shipping') || {}) as AddressType
  );

  const [billingAddress, setBillingAddress] = useState(
    (companyLocations.find((location) => location.type === 'billing') || {}) as AddressType
  );

  const [editingAddressType, setEditingAddressType] = useState('' as 'shipping' | 'billing' | '');

  const { quote, refreshQuote } = useContext(NewQuoteContext);

  const history = useHistory();
  const { addErrorAlert } = useAlertQueue();
  useAbandonedQuotePrompt(quote);

  // Forms

  const [requestedShipBy, setRequestedShipBy] = useState(quote.requestedShipBy);
  const [requestedDeliverBy, setRequestedDeliverBy] = useState(quote.requestedDeliverBy);

  // Helpers

  const isShippingAddressSelected = !isEmpty(shippingAddress);
  const isBillingAddressSelected = !isEmpty(billingAddress);
  const isShippingAddressValid = Object.entries(quote.shippingAddress).every(([key, value]) => {
    if (REQUIRED_ADDRESS_FIELDS.includes(key)) {
      if (value === '') return false;
    }

    return true;
  });

  const minRequestedShipByValue = useMemo(getTodaysFormattedDate, []);

  const currentShippingAddress = isShippingAddressValid ? quote.shippingAddress : null;

  // Event Handlers

  const updateShippingAddress = async (newShippingAddress: AddressType) => {
    try {
      const shippingParams: QuoteShippingParams = {
        shippingAddress: newShippingAddress,
        requestedShipBy: quote.requestedShipBy,
        requestedDeliverBy: quote.requestedDeliverBy,
      };

      await updateQuoteShipping(quote.id, shippingParams);
      await refreshQuote();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Unable to update Shipping Address', 'Please try again.');
      console.error('Unable to update Shipping Address', errorMessage as string);
    }
  };

  const updateRequestedShipBy = async (newRequestedShipBy: string) => {
    try {
      const shippingParams: QuoteShippingParams = {
        shippingAddress: currentShippingAddress,
        requestedShipBy: newRequestedShipBy,
        requestedDeliverBy: quote.requestedDeliverBy,
      };

      await updateQuoteShipping(quote.id, shippingParams);
      await refreshQuote();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Unable to update Requested Ship Date', 'Please try again.');
      console.error('Unable to update Requested Ship Date', errorMessage as string);
    }
  };

  const updateRequestedDeliverBy = async (newRequestedDeliverBy: string) => {
    try {
      const shippingParams: QuoteShippingParams = {
        shippingAddress: currentShippingAddress,
        requestedShipBy: quote.requestedShipBy,
        requestedDeliverBy: newRequestedDeliverBy,
      };

      await updateQuoteShipping(quote.id, shippingParams);
      await refreshQuote();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Unable to update Requested Delivery Date', 'Please try again.');
      console.error('Unable to update Requested Delivery Date', errorMessage as string);
    }
  };

  const updateBillingAddress = async (newBillingAddress: AddressType) => {
    try {
      await updateQuoteBilling(quote.id, { billingAddress: newBillingAddress });
      await refreshQuote();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;
      addErrorAlert('Unable to update Addresses', 'Please try again.');
      console.error('Unable to update Addresses', errorMessage as string);
    }
  };

  const handleBackButtonClick = () => history.push(`/quotes/new/${quote.id}/products`);

  const handleNextButtonClick = async () => {
    await updateShippingAddress(shippingAddress);
    await updateBillingAddress(billingAddress);
    history.push(`/quotes/new/${quote.id}/review`);
  };

  // Render

  return (
    <>
      <QuoteLayoutMain>
        <Stepper>
          <Step number={1} status="completed">
            Select Partner
          </Step>

          <Step number={2} status="completed">
            Select Products
          </Step>

          <Step number={3} status="active">
            Shipping &amp; Billing
          </Step>

          <Step number={4}>Review &amp; Submit</Step>
        </Stepper>

        <Card>
          <H1>Shipping &amp; Billing Information</H1>

          <Flex column gap="24px">
            <Flex gap="32px">
              <Form.Group controlId="requested-ship-by">
                <Form.Label>
                  <H2>Requested Ship Date</H2>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="requestedShipBy"
                  onChange={async (e) => {
                    setRequestedShipBy(e.target.value);
                    await updateRequestedShipBy(new Date(e.target.value).toISOString());
                  }}
                  min={minRequestedShipByValue}
                  value={requestedShipBy}
                />
              </Form.Group>

              <Form.Group controlId="requested-deliver-by">
                <Form.Label>
                  <H2>Requested Delivery Date</H2>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="requestedDeliverBy"
                  onChange={async (e) => {
                    setRequestedDeliverBy(e.target.value);
                    await updateRequestedDeliverBy(new Date(e.target.value).toISOString());
                  }}
                  min={requestedShipBy}
                  value={requestedDeliverBy}
                />
              </Form.Group>
            </Flex>

            <Divider />

            <Grid columns="1fr 1fr">
              <Flex flexDirection="column" gap="24px">
                <H2>Shipping Address</H2>

                <Flex gap="32px" alignItemsCenter>
                  {isShippingAddressSelected && (
                    <Address>
                      <AddressLine>
                        <strong>{shippingAddress.name}</strong>
                      </AddressLine>
                      <AddressLine>{shippingAddress.addressOne}</AddressLine>
                      {!isEmpty(shippingAddress.addressTwo) && (
                        <AddressLine>{shippingAddress.addressTwo}</AddressLine>
                      )}
                      <AddressLine>
                        {shippingAddress.city}, {shippingAddress.state}
                      </AddressLine>
                      <AddressLine>{shippingAddress.zip}</AddressLine>
                    </Address>
                  )}

                  <EditButton
                    onClick={() => setEditingAddressType('shipping')}
                    data-testid="edit-shipping-button"
                  >
                    <EditDot />
                    <EditText>{isShippingAddressSelected ? 'Edit' : 'Add'}</EditText>
                  </EditButton>
                </Flex>
              </Flex>

              <Flex flexDirection="column" gap="24px">
                <H2>Billing Address</H2>

                <Flex gap="32px" alignItemsCenter>
                  {isBillingAddressSelected && (
                    <Address>
                      <AddressLine>
                        <strong>{billingAddress.name}</strong>
                      </AddressLine>
                      <AddressLine>{billingAddress.addressOne}</AddressLine>
                      {!isEmpty(billingAddress.addressTwo) && (
                        <AddressLine>{billingAddress.addressTwo}</AddressLine>
                      )}
                      <AddressLine>
                        {billingAddress.city}, {billingAddress.state}
                      </AddressLine>
                      <AddressLine>{billingAddress.zip}</AddressLine>
                    </Address>
                  )}

                  <EditButton
                    onClick={() => setEditingAddressType('billing')}
                    data-testid="edit-billing-button"
                  >
                    <EditDot />
                    <EditText>{isBillingAddressSelected ? 'Edit' : 'Add'}</EditText>
                  </EditButton>
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </Card>

        <AddressSelectModal
          addressType={editingAddressType}
          handleClose={async (address) => {
            if (editingAddressType === 'shipping' && !isEmpty(address)) {
              setShippingAddress(address);
              await updateShippingAddress(address);
            }

            if (editingAddressType === 'billing' && !isEmpty(address)) {
              setBillingAddress(address);
              await updateBillingAddress(address);
            }

            setEditingAddressType('');
            await refreshQuote();
          }}
        />
      </QuoteLayoutMain>

      <QuoteLayoutAside>
        <StepNavigationCard
          disableBackButton={false}
          disableNextButton={!isShippingAddressSelected || !isBillingAddressSelected}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
        />

        <QuoteSummary />
      </QuoteLayoutAside>
    </>
  );
};

const AddressSelection = () => (
  <QuoteLayout title="Request Quote" data-testid="address-selection">
    <AddressSelectionPage />
  </QuoteLayout>
);

export default AddressSelection;
