import type { BaseQueryParams, PaginatedResponse, Response } from 'types/api';
import type { Address, Money } from 'types/general';
import type { Quote, QuoteItem } from 'types/models/quote';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/request';

type QuoteItemParams = {
  productId: string;
  variantId: string;
  quantity: number;
};

export type QuoteShippingParams = {
  shippingAddress: Address | null;
  requestedShipBy: string | null;
  requestedDeliverBy: string | null;
};

type QuoteBillingParams = {
  billingAddress: Address;
};

export type QuotePricingParams = {
  estimatedShippingPrice: Money;
  estimatedTaxPrice: Money;
};

type QuoteRejectParams = {
  note: string;
};

type QuoteParams = {
  partnerId: string;
};

export type QuotesResponse = PaginatedResponse<Quote[]>;
export type QuoteResponse = Response<Quote>;
export type QuoteItemResponse = Response<QuoteItem>;

export const getQuotes = async (params: BaseQueryParams): Promise<QuotesResponse> =>
  getRequest('/quotes', params, {}, {}, 'brackets');

export const createQuote = async (params: QuoteParams): Promise<QuoteResponse> =>
  postRequest('/quotes', params);

export const getQuote = async (quoteId: string): Promise<QuoteResponse> =>
  getRequest(`/quotes/${quoteId}`);

export const updateQuoteShipping = async (
  quoteId: string,
  params: QuoteShippingParams
): Promise<QuoteResponse> => patchRequest(`/quotes/${quoteId}/shipping`, params);

export const updateQuoteBilling = async (
  quoteId: string,
  params: QuoteBillingParams
): Promise<QuoteResponse> => patchRequest(`/quotes/${quoteId}/billing`, params);

export const updateQuotePricing = async (
  quoteId: string,
  params: QuotePricingParams
): Promise<QuoteResponse> => patchRequest(`/quotes/${quoteId}/pricing`, params);

export const requestQuote = async (quoteId: string): Promise<QuoteResponse> =>
  postRequest(`/quotes/${quoteId}/request`);

export const rejectQuote = async (
  quoteId: string,
  params: QuoteRejectParams
): Promise<QuoteResponse> => postRequest(`/quotes/${quoteId}/reject`, params);

export const acceptQuote = async (quoteId: string): Promise<QuoteResponse> =>
  postRequest(`/quotes/${quoteId}/accept`);

export const deleteQuote = async (quoteId: string): Promise<unknown> =>
  deleteRequest(`/quotes/${quoteId}`);

export const createQuoteItem = async (
  quoteId: string,
  params: QuoteItemParams
): Promise<QuoteItemResponse> => postRequest(`/quotes/${quoteId}/items`, params);

export const updateQuoteItem = async (
  quoteId: string,
  quoteItemId: string,
  params: QuoteItemParams
): Promise<QuoteItemResponse> => patchRequest(`/quotes/${quoteId}/items/${quoteItemId}`, params);

export const deleteQuoteItem = async (quoteId: string, quoteItemId: string): Promise<unknown> =>
  deleteRequest(`/quotes/${quoteId}/items/${quoteItemId}`);
